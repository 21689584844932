import API from './API'
import { FetchRequestsResponse } from '../types'

import { Request } from '../types'

interface getTimestampLimitsResponse {
    startTimestamp: Number
    endTimestamp: Number
}

interface requestFilters {
    RequestedTime?: object
    Registrant?: string
    Type?: string | null
    Details?: object
}

interface requestOptions {
    Filter: requestFilters
    QueryOptions: {
        page_no: number
        page_size: number
        sort: object[]
    }
}

function parseFetchResponse(response: any, page: number = 1, limit: number = 100): FetchRequestsResponse {
    const res: FetchRequestsResponse = {
        count: response.TotRecords || 0,
        page,
        limit,
        requests: response.Result,
    }

    return res
}

/**
 * Identifies the start and end timestamps of the local date
 *
 *
 */
function getTimestampLimits(fromDate: Date, toDate: Date) {
    const fromYear = fromDate.getFullYear()
    const fromMonth = fromDate.getMonth()
    const fromDay = fromDate.getDate()

    const toYear = toDate.getFullYear()
    const toMonth = toDate.getMonth()
    const toDay = toDate.getDate()

    return {
        // from morning 12 am
        startTimestamp: new Date(fromYear, fromMonth, fromDay).getTime(),
        // Till the end time night 11:59:59 pm
        endTimestamp: new Date(toYear, toMonth, toDay, 23, 59, 59).getTime(),
    }
}

export async function fetchRequests(
    fromDate: Date | null,
    toDate: Date | null,
    resident: string | null,
    food: string | null,
    type: string | null,
    page_no = 1,
    page_size = 100,
    sortBy: object[] = [{ RequestedTime: 'asc' }],
): Promise<FetchRequestsResponse> {
    const requestOptions: requestOptions = {
        Filter: {},
        QueryOptions: {
            page_no,
            page_size,
            sort: sortBy,
        },
    }

    if (type) {
        requestOptions.Filter.Type = type
    }

    if (fromDate && toDate) {
        const { startTimestamp, endTimestamp } = getTimestampLimits(fromDate, toDate)
        requestOptions.Filter.RequestedTime = { $gt: startTimestamp, $lt: endTimestamp }
    }

    if (resident) {
        requestOptions.Filter.Registrant = resident
    }

    if (food) {
        requestOptions.Filter.Details = { food: food }
    }

    const res = await API.lambdaPost(`/basic-requests/list`, requestOptions)
    const parsed = parseFetchResponse(res)
    return parsed
}
