import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Sidebar, Menu, Icon, Button, Divider, Dropdown } from 'semantic-ui-react'

import './style.less'
import { CurrentUser, UserProfile } from '../../types'
import {
    canManageUsers,
    canAccessFacilityForms,
    canManageRegistrants,
    canManageNotifications,
    canManageFacilities,
    canManageDailyActivities,
    canManageMenus,
    canSeeDashboard,
    canManageUniversityTrainings,
    canManageStandardUniversityTrainings,
} from '../../services/Permissions'

interface PageHeaderProps {
    showSidebar: boolean
    setShowSidebar: (to: boolean) => any
    pageName: string
    userName: string | null
    navigate: (to: string) => void
    logOut: () => void
}

const PageHeader: React.FC<PageHeaderProps> = (props) => {
    const handleSidebarToggle = () => {
        props.setShowSidebar(!props.showSidebar)
    }

    const renderHeader = () => {
        return (
            <header className="page-header">
                <div className="items">
                    <Button icon="bars" circular onClick={handleSidebarToggle} />
                    <span className="page-name">{props.pageName}</span>
                    <div className="right">
                        <span className="username">
                            <Dropdown text={props.userName || ''}>
                                <Dropdown.Menu className="left">
                                    <Dropdown.Item onClick={() => props.navigate('/profile')} text="Profile Settings" />
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={props.logOut} text="Log Out" />
                                </Dropdown.Menu>
                            </Dropdown>
                        </span>
                    </div>
                </div>
                <Divider id="divider" />
            </header>
        )
    }

    return props.pageName === 'Login' ? null : renderHeader()
}

interface Props extends RouteComponentProps {
    showSidebar: boolean
    setShowSidebar: (to: boolean) => any
    currentUser: CurrentUser | null
    profile: UserProfile | null
    logOut: () => void
    isAuthenticated: boolean
}

const Navigation: React.FC<Props> = (props) => {
    const handleSidebarHide = () => {
        props.setShowSidebar(false)
    }

    const getPageNameFromRoute = () => {
        switch (props.location.pathname) {
            case '/login':
                return 'Login'
            case '/myrequests':
                return 'My Requests'
            case '/profile':
                return 'My Profile'
            case '/admin/home':
                return 'Admin Dashboard'
            case '/admin/requests/create':
                return 'Create Request'
            case '/admin/users/list':
                return 'Users List'
            case '/admin/users/create':
                return 'Create User'
            case '/admin/registrants/list':
                return 'Residents'
            case '/admin/registrants/create':
                return 'Add Residents'
            case '/admin/facilities/list':
                return 'Facilities'
            case '/admin/facilities/create':
                return 'Add Facilities'
            case '/admin/forms/units/list':
                return 'Rooms'
            case '/admin/forms/units/create':
                return 'Add Room'
            case '/admin/forms/departments/list':
                return 'Departments'
            case '/admin/forms/departments/create':
                return 'Add Department'
            case '/admin/forms/contacts/list':
                return 'Contacts'
            case '/admin/forms/contacts/create':
                return 'Add Contact'
            case '/admin/forms/requesttypes/list':
                return 'Service Types'
            case '/admin/forms/requesttypes/create':
                return 'Add Service Type'
            case '/admin/notifications':
                return 'Announcements'
            case '/admin/dailyactivities':
                return 'Daily Activities'
            case '/admin/menu':
                return 'Menu'
            case '/admin/reports':
                return 'Reports'
            case '/admin/clone-data':
                return 'Clone Data For Demo'
            case '/admin/university/standard-trainings/create':
                return 'Add Standard University Trainings'
            case '/admin/university/trainings/create':
                return 'Add University Trainings'
            case '/admin/university/standard-trainings/list':
                return 'Standard University Trainings'
            case '/admin/university/trainings/list':
                return 'University Trainings'
            default: {
                // Handle non-exact routes
                if (props.location.pathname.includes('/registrant/')) return 'Edit Resident'
                if (props.location.pathname.includes('/facility/')) return 'Edit Facility'
                if (props.location.pathname.includes('/unit/')) return 'Edit Room'
                if (props.location.pathname.includes('/department/')) return 'Edit Department'
                if (props.location.pathname.includes('/contact/')) return 'Edit Contact'
                if (props.location.pathname.includes('/requesttype/')) return 'Edit Service Type'
                if (props.location.pathname.includes('/user/')) return 'Edit Staff'
                if (props.location.pathname.includes('/standard-trainings/')) return 'Edit Standard University Training'
                if (props.location.pathname.includes('/trainings/')) return 'Edit University Training'
                return 'Speak2 Console'
            }
        }
    }

    const navigate = (to: string) => {
        handleSidebarHide()
        props.history.push(to)
    }

    return (
        <div className="navigation">
            {/* {!props.isAuthenticated && <Redirect to='/login' />} */}
            <PageHeader
                setShowSidebar={props.setShowSidebar}
                showSidebar={props.showSidebar}
                pageName={getPageNameFromRoute()}
                userName={props.currentUser ? props.currentUser.username : null}
                navigate={navigate}
                logOut={props.logOut}
            />
            <Sidebar
                as={Menu}
                animation="push"
                icon="labeled"
                inverted
                onHide={handleSidebarHide}
                vertical
                visible={props.showSidebar}
                width="thin"
            >
                <Menu.Item as="a" onClick={() => navigate('/')}>
                    <Icon name="home" />
                    Home
                </Menu.Item>
                <Menu.Item
                    disabled={!canManageDailyActivities(props.profile)}
                    as="a"
                    onClick={() =>
                        canManageDailyActivities(props.profile) ? navigate('/admin/dailyactivities') : null
                    }
                >
                    <Icon name="paper plane" />
                    Daily Activities
                </Menu.Item>
                <Menu.Item
                    disabled={!canManageMenus(props.profile)}
                    as="a"
                    onClick={() => (canManageMenus(props.profile) ? navigate('/admin/menu') : null)}
                >
                    <Icon name="food" />
                    Menu
                </Menu.Item>
                <Menu.Item as="a" onClick={() => navigate('/myrequests')}>
                    <Icon name="user" />
                    My Requests
                </Menu.Item>
                <Menu.Item
                    disabled={!canManageUsers(props.profile)}
                    as="a"
                    onClick={() => (canManageUsers(props.profile) ? navigate('/admin/users/list') : null)}
                >
                    <Icon name="user" />
                    Staff
                </Menu.Item>
                <Menu.Item
                    disabled={!canAccessFacilityForms(props.profile)}
                    as="a"
                    onClick={() => (canAccessFacilityForms(props.profile) ? navigate('/admin/forms') : null)}
                >
                    <Icon name="setting" />
                    Manage Facility
                </Menu.Item>
                <Menu.Item
                    disabled={!canManageRegistrants(props.profile)}
                    as="a"
                    onClick={() => (canManageRegistrants(props.profile) ? navigate('/admin/registrants/list') : null)}
                >
                    <Icon name="group" />
                    Residents
                </Menu.Item>
                <Menu.Item disabled={!canSeeDashboard(props.profile)} as="a" onClick={() => navigate('/admin/reports')}>
                    <Icon name="th" />
                    Reports
                </Menu.Item>

                {canManageStandardUniversityTrainings(props.profile) ? (
                    <Menu.Item
                        disabled={!canManageStandardUniversityTrainings(props.profile)}
                        as="a"
                        onClick={() =>
                            canManageStandardUniversityTrainings(props.profile)
                                ? navigate('/admin/university/standard-trainings/list')
                                : null
                        }
                    >
                        <Icon name="graduation cap" />
                        University
                    </Menu.Item>
                ) : (
                    <Menu.Item
                        disabled={!canManageUniversityTrainings(props.profile)}
                        as="a"
                        onClick={() =>
                            canManageUniversityTrainings(props.profile)
                                ? navigate('/admin/university/trainings/list')
                                : null
                        }
                    >
                        <Icon name="graduation cap" />
                        University
                    </Menu.Item>
                )}
                <Menu.Item
                    disabled={!canManageFacilities(props.profile)}
                    as="a"
                    onClick={() => (canManageFacilities(props.profile) ? navigate('/admin/facilities/list') : null)}
                >
                    <Icon name="building" />
                    Facilities
                </Menu.Item>
                <Menu.Item
                    disabled={!canManageFacilities(props.profile)}
                    as="a"
                    onClick={() => (canManageFacilities(props.profile) ? navigate('/admin/clone-data') : null)}
                >
                    <Icon name="copy" />
                    Clone Demo Data
                </Menu.Item>
            </Sidebar>
        </div>
    )
}

export default withRouter(Navigation)
