import React from 'react'
import { Tab, Comment, Button } from 'semantic-ui-react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/airbnb.css'
import ItemsList from '../ItemsList'
import { DailyActivity, MenuType } from '../../types'

interface Props {
    loading: boolean
    listItemFilter: string | number
    isSaving: boolean
    isFetching: boolean
    listItems: DailyActivity[] | MenuType[]
    sourcePage: string
    aciveFoodTab?: string
    setListItemDateFilter: (time) => any
    clearDateFilter: () => any
    getPaginatedListItem: () => any
    deleteListItem: (id) => any
    editListItem: (id) => any
    copyListItem: (id) => any
    cancelEditListItem: (id) => any
    handleSubmit: (activity) => any
    handleUpdate: (activity) => any
}

const ListItemsPane: React.FC<Props> = (props: Props) => {
    return (
        <Tab.Pane loading={props.loading} className="items-list-container">
            <Comment.Group>
                <h4>Filter Date: </h4>
                <Flatpickr
                    className="date-filter"
                    value={props.listItemFilter ? new Date(props.listItemFilter).getTime() : null}
                    options={{ dateFormat: 'd.m.Y' }}
                    placeholder="Select Date"
                    onChange={(time: string) => props.setListItemDateFilter(time)}
                />
                <Button
                    type="button"
                    primary
                    loading={props.isSaving}
                    disabled={props.isSaving || !props.listItemFilter}
                    onClick={props.clearDateFilter}
                >
                    Cancel Filter
                </Button>
                {props.listItems.length ? (
                    <ItemsList
                        listItems={props.getPaginatedListItem()}
                        isFetching={props.isFetching}
                        isSaving={props.isSaving}
                        handleSubmit={props.handleSubmit}
                        handleUpdate={props.handleUpdate}
                        deleteListItem={props.deleteListItem}
                        editListItem={props.editListItem}
                        cancelEditListItem={props.cancelEditListItem}
                        copyListItem={props.copyListItem}
                        sourcePage={props.sourcePage}
                        aciveFoodTab={props.aciveFoodTab}
                    />
                ) : (
                    <h3>There are no listed items</h3>
                )}
            </Comment.Group>
        </Tab.Pane>
    )
}

export default ListItemsPane
