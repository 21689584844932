import React from 'react';
import { Item, Button } from 'semantic-ui-react';
import './style.less';

interface Props {
    id: string | undefined;
    text: string;
    time: any;
    sourcePage: string;
    deleteListItem: (id) => any;
    editListItem: (id) => any;
    copyListItem?: (id) => any;
}

const formatTime = (time, sourcePage) => {
    if (sourcePage === 'DailyActivities') {
        return `${new Date(time).toLocaleDateString("en-US", {weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric'})} 
        ${new Date(time).toLocaleTimeString("en-US")}`;
    }
    if (sourcePage === 'Menu') {
        return `${new Date(time).toLocaleDateString("en-US", {weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric'})}`;
    }
};

const ListItemElement: React.FC<Props> = props => {
    
    return (
        <Item className="ListItem">
            <Item.Content verticalAlign="middle">
                <Item.Description as="div">{<div dangerouslySetInnerHTML={{__html: props.text}}></div>}</Item.Description>
                <Item.Meta className="list-item-time">{formatTime(props.time, props.sourcePage)}</Item.Meta>
                <Item.Meta 
                    onClick={() => props.editListItem(props.id)}
                    className="meta_options_column"><Button>{'Edit'}</Button></Item.Meta>
                <Item.Meta 
                    className="meta_options_column"
                    onClick={() => props.deleteListItem(props.id)}><Button>{'Delete'}</Button></Item.Meta>
                <Item.Meta 
                    className="meta_options_column"
                    onClick={() => {
                        if (props.copyListItem) {
                            props.copyListItem(props.id);
                        }
                    }}><Button>{'Copy'}</Button></Item.Meta>
            </Item.Content>
        </Item>
    );
};

export default ListItemElement;
