import React from 'react'
import { Divider } from 'semantic-ui-react'
import OpenRequests from '../OpenRequests'
import Unassigned from '../Unassigned'
import './style.less'

function StaffDashboard() {
    return (
        <div className="staff-dashboard">
            <div className="unassigned-requests">
                <Unassigned />
            </div>
            <Divider id="divider" />
            <div className="my-requests">
                <OpenRequests />
            </div>
        </div>
    )
}

export default StaffDashboard
