import React from 'react'
import { Tab, TabProps, Button } from 'semantic-ui-react'
import UsersList from '../List'
import { fetchAdminUsers, fetchPowerUsers, fetchStaffUsers } from '../../../../services/Users'
import { User, UserProfile } from '../../../../types'

import './style.less'
import { RouteComponentProps, withRouter } from 'react-router'

interface State {
    isFetching: boolean
    error: string | null
    users: User[]
    filter: 'staff' | 'admins' | 'power'
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null;
}

class StaffHome extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isFetching: false,
            error: null,
            users: [],
            filter: 'staff' as 'staff' | 'admins' | 'power',
        };
    }

    panes = [
        {
            menuItem: 'Staff',
            render: () => (
                <Tab.Pane loading={this.state.isFetching}>
                    <UsersList users={this.state.users} error={this.state.error} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Power Users',
            render: () => (
                <Tab.Pane loading={this.state.isFetching}>
                    <UsersList users={this.state.users} error={this.state.error} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Facility Admins',
            render: () => (
                <Tab.Pane loading={this.state.isFetching}>
                    <UsersList users={this.state.users} error={this.state.error} />
                </Tab.Pane>
            ),
        }
    ]

    async fetcher(): Promise<User[]> {
        switch (this.state.filter) {
            case 'staff':
                return fetchStaffUsers()
            case 'admins':
                return fetchAdminUsers()
            case 'power':
                return fetchPowerUsers()
        }
    }

    async fetchUsers() {

        this.setState({ isFetching: true, error: null })
        try {
            const users = await this.fetcher()
            this.setState({
                isFetching: false,
                error: null,
                users,
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                error: e.message,
            })
        }
    }

    navigateToCreate() {
        this.props.history.push('/admin/users/create')
    }

    handleChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        if (data.activeIndex === 0) {
            this.setState({ filter: 'staff' }, this.fetchUsers)
        } else if (data.activeIndex === 1) {
            this.setState({ filter: 'power' }, this.fetchUsers)
        } else {
            this.setState({ filter: 'admins' }, this.fetchUsers)
        }
    }

    componentDidMount() {
        this.fetchUsers()
    }

    render() {
        return (
            <div className="UsersHome">
                <Button
                    content="Create New"
                    icon="add"
                    labelPosition="left"
                    primary
                    onClick={this.navigateToCreate.bind(this)}
                />
                <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={this.panes}
                    onTabChange={this.handleChange.bind(this)}
                />
            </div>
        )
    }
}

export default withRouter(StaffHome)
