import React from 'react'
import './style.less'
import { Dimmer, Loader, Button, List } from 'semantic-ui-react'
import { Facility } from '../../../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchFacilities } from '../../../../services/Facilities'

interface State {
    facilities: Facility[]
    error: string | null
    isFetching: boolean
    page: number
    count: number
    limit: number
}

class FacilitiesList extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            facilities: [],
            error: null,
            isFetching: false,
            page: 0,
            count: 0,
            limit: 10000,
        }
    }

    async componentDidMount() {
        this.setState({
            isFetching: true,
            error: null,
        })

        try {
            const facilities = await fetchFacilities()
            this.setState({
                isFetching: false,
                facilities,
                error: null,
            })
        } catch (e) {
            const msg = e.message || 'Could not fetch Facilities.'
            this.setState({
                isFetching: false,
                error: msg,
            })
        }
    }

    navigateToCreate() {
        return this.props.history.push('/admin/facilities/create')
    }

    goToEdit(id: string) {
        return this.props.history.push(`/admin/facility/${id}`)
    }

    get facilities() {
        return this.state.facilities.map(f => (
            <List.Item key={f._id} onClick={() => this.goToEdit(f._id)}>
                <List.Icon name="building outline" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header as="a">{f.Name}</List.Header>
                    <List.Description as="a">{f.URL}</List.Description>
                </List.Content>
            </List.Item>
        ))
    }

    render() {
        return (
            <div className="FacilitiesList">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Button
                    content="Create New"
                    icon="add"
                    labelPosition="left"
                    primary
                    onClick={this.navigateToCreate.bind(this)}
                />
                <List divided relaxed>
                    {this.facilities}
                </List>
            </div>
        )
    }
}

export default withRouter(FacilitiesList)
