import React, {KeyboardEvent} from 'react'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import { Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../actions/auth'
import './style.css'
import { AppState } from '../../reducers'

import { Button, Input, Icon, Dimmer, Loader } from 'semantic-ui-react'
import { askForPermissioToReceiveNotifications } from '../../services/PushNotifications';

interface Props extends RouteComponentProps {
    onLogIn: (user: string, pass: string) => void
    onLogOut: () => void
    onLoginFromCache: () => void
    isAuthenticated: boolean
    hasError: boolean
    errorCode: string | null
    isFetching: boolean
    cacheLoginFailed: boolean
    passwordChangeRequired: boolean
}

interface State {
    username: string
    password: string
}

class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            username: '',
            password: '',
        }
    }

    async componentDidMount() {
        if (!this.props.cacheLoginFailed) {
            await this.props.onLoginFromCache()
            if (!this.props.cacheLoginFailed)
                await askForPermissioToReceiveNotifications()
        }
    }

    async handleLogIn() {
        await this.props.onLogIn(this.state.username, this.state.password)
        await askForPermissioToReceiveNotifications()
        // this.props.history.push('/')
    }

    handleUsernameChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({
            username: event.currentTarget.value,
        })
    }

    handlePasswordChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({
            password: event.currentTarget.value,
        })
    }

    handleKeyDown(e: KeyboardEvent) {
        if (e.key === 'Enter' && this.state.username && this.state.password) {
            this.handleLogIn()
        }
    }

    renderErrorMessage() {
        const { hasError, errorCode } = this.props
        let message = ''
        if (errorCode === 'NotAuthorizedException' || errorCode === 'UserNotFoundException')
            message = 'Incorrect username or password'
        else message = 'Error during Log In process.'

        return hasError ? <div style={{ color: 'red' }}>{message}</div> : null
    }

    get from() {
        return (this.props.location && this.props.location.state && this.props.location.state.from) || '/'
    }

    renderLoginForm() {
        const redirectToHome = <Redirect to={this.from} />
        const redirectToPasswordChange = <Redirect to='/newpassword' />
        return (
            <div className="Login">
                {this.props.isAuthenticated && redirectToHome}
                {this.props.passwordChangeRequired && redirectToPasswordChange}
                <div className="loginBox">
                    {/* <h1>Login Page</h1> */}
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="" className="logo" />
                    <Input
                        fluid
                        className="input"
                        iconPosition="left"
                        placeholder="Username"
                        onChange={this.handleUsernameChange.bind(this)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                    >
                        <Icon name="user" />
                        <input />
                    </Input>
                    <Input
                        fluid
                        className="input"
                        iconPosition="left"
                        placeholder="Password"
                        type="password"
                        onChange={this.handlePasswordChange.bind(this)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                    >
                        <Icon name="lock" />
                        <input />
                    </Input>
                    <Button
                        fluid
                        primary
                        size="big"
                        onClick={this.handleLogIn.bind(this)}
                        loading={this.props.isFetching}
                        disabled={this.props.isFetching}
                    >
                        LOG IN
                    </Button>
                    <br />
                    {this.renderErrorMessage()}
                </div>
            </div>
        )
    }

    renderLoading() {
        const redirectToHome = <Redirect to={this.from} />
        return (
            <Dimmer active inverted>
                {this.props.isAuthenticated && redirectToHome}
                <Loader content="Loading" />
            </Dimmer>
        )
    }

    render() {
        return this.props.cacheLoginFailed ? this.renderLoginForm() : this.renderLoading()
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        isAuthenticated: authReducer.isAuthenticated,
        hasError: authReducer.hasError,
        errorCode: authReducer.errorCode,
        isFetching: authReducer.fetching,
        cacheLoginFailed: authReducer.cacheLoginFailed,
        passwordChangeRequired: authReducer.passwordChangeRequired,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.AuthAction>) {
    return {
        onLogIn: (username: string, password: string) => dispatch(actions.logIn(username, password)),
        onLogOut: () => dispatch(actions.logOut()),
        onLoginFromCache: () => dispatch(actions.logInFromCache())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Login))
