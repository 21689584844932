import React, { MouseEvent, useState } from 'react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message, Confirm } from 'semantic-ui-react'
import * as Yup from 'yup'

import {
    GenericTextField,
    GenericImageField,
    GenericDataSourceDropdown,
    GenericToggleList,
} from '../../../../components/GenericFormFields'

import PasswordResetInputFields from '../../../../components/PasswordResetInputFields';

import './style.less'
import { Department, User, UserRole, Facility } from '../../../../types'

export interface FormValues {
    Email: string;
    FirstName: string;
    LastName: string;
    Phone: string;
    Cell: string;
    Icon: string;
    Username: string;
    Facility?: string;
    Roles: UserRole[];
    Departments: Department[];
    TemporaryPassword?: string;
    ConfirmPassword?: string;
    PasswordReset?: string;
    ConfirmPasswordReset?: string;
    VerificationCode?: number | string;
}

const ValidationSchema = Yup.object().shape({
    Email: Yup.string()
        .email()
        .required(),
    FirstName: Yup.string().required('First Name is required'),
    LastName: Yup.string().required('Last Name is required'),
    Phone: Yup.string(),
    Cell: Yup.string().required(),
    Icon: Yup.string(),
    Username: Yup.string().required(),
    Facility: Yup.string(),
    Roles: Yup.array()
        .of(Yup.object())
        .required(),
    Departments: Yup.array().of(Yup.object()),
    TemporaryPassword: Yup.string().min(6),
    ConfirmPassword: Yup.string().oneOf([Yup.ref('TemporaryPassword')], 'Passwords must match'),
    PasswordReset: Yup.string().min(8),
    ConfirmPasswordReset: Yup.string().oneOf([Yup.ref('PasswordReset')], 'Passwords must match'),
    VerificationCode: Yup.number().nullable(true)
})

const imagePlaceholder = `${process.env.PUBLIC_URL}/avatar_placeholder.png`

interface Props {
    user: User | null;
    departments: Department[];
    facilities: Facility[];
    roles: UserRole[];
    error: string | null;
    title: string;
    onSave: (data: FormValues) => Promise<any>;
    cancel: () => any;
    onDelete?: (id: string) => Promise<any>;
    startPaswordReset?: (event) => any;
    isSaving?: boolean;
    newPasswordInputFields?: boolean;
}

const UsersForm: React.SFC<Props> = (props: Props) => {
    const hasSaveError = props.error ? true : false
    const getInitialValues = () => ({
        Email: (props.user && props.user.Email) || '',
        FirstName: (props.user && props.user.FirstName) || '',
        LastName: (props.user && props.user.LastName) || '',
        Phone: (props.user && props.user.Phone) || '',
        Cell: (props.user && props.user.Cell) || '',
        Icon: (props.user && props.user.Icon) || '',
        Username: (props.user && props.user.Username) || '',
        Facility: (props.user && props.user.Facility) || undefined,
        Roles: (props.user && props.user.Roles) || [],
        Departments: (props.user && props.user.Departments) || [],
        TemporaryPassword: '',
        ConfirmPassword: '',
        PasswordReset: '',
        ConfirmPasswordReset: '',
        VerificationCode: ''
    })

    const canDelete = () => {
        return props.onDelete && isEdit()
    }

    const isEdit = () => (props.user && props.user._id ? true : false)

    const [showConfirm, setShowConfirm] = useState(false)

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (canDelete()) {
            // @ts-ignore
            props.onDelete(props.user._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }

    const confirm = () => {
        close()
        doDelete()
    }

    const isStaffUser = user => {
        if (user) {
            return user.Roles.some(role => {
                return role.Name === 'Staff';
            });
        }
        return false;
    };

    const togglePasswordResetViews = () => {
        if (props.newPasswordInputFields) {
            return <PasswordResetInputFields 
                        GenericTextField={GenericTextField}
                    />
        }
        if (isStaffUser(props.user) && !props.error) {
            return (
                <Button onClick={props.startPaswordReset} className="password-reset-button">
                    Reset Password
                </Button>
            );
        }
        if (props.error) {
            return <p className="password-reset-warning">{props.error}</p>
        }
    }


    return (
        <div className="UsersForm">
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header="Confirm Delete"
                content="Are you sure you want to delete this item?"
            />
            <h1>{props.title}</h1>
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: FormValues, actions: FormikActions<FormValues>) => {
                    await props.onSave(values)
                    actions.setSubmitting(false)
                }}
                validationSchema={ValidationSchema}
                render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        <FormikField name="Icon" component={GenericImageField} placeholder={imagePlaceholder} />
                        <FormikField required name="FirstName" component={GenericTextField} placeholder="First Name" />
                        <FormikField required name="LastName" component={GenericTextField} placeholder="Last Name" />
                        <FormikField
                            required
                            name="Cell"
                            component={GenericTextField}
                            placeholder="Cell"
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        />
                        <FormikField
                            name="Phone"
                            component={GenericTextField}
                            placeholder="Phone"
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        />
                        <FormikField
                            required
                            name="Username"
                            component={GenericTextField}
                            placeholder="Username"
                            disabled={isEdit()}
                        />

                        {isEdit() && togglePasswordResetViews()}

                        <FormikField
                            required
                            name="Email"
                            component={GenericTextField}
                            placeholder="Email"
                            type="email"
                            disabled={isEdit()}
                        />
                        {(!isEdit() && (
                            <FormikField
                                required
                                name="TemporaryPassword"
                                component={GenericTextField}
                                type="password"
                                placeholder="Temporary Password"
                                autoComplete="new-password"
                            />
                        )) ||
                            null}
                        {(!isEdit() && (
                            <FormikField
                                required
                                name="ConfirmPassword"
                                component={GenericTextField}
                                type="password"
                                placeholder="Confirm Password"
                                autoComplete="new-password-confirm"
                            />
                        )) ||
                            null}
                        {(props.facilities.length && (
                            <FormikField
                                data={props.facilities}
                                required
                                name="Facility"
                                component={GenericDataSourceDropdown}
                                placeholder="Facility"
                                labelFields={['Name']}
                                valueField="_id"
                            />
                        )) ||
                            null}
                        <FormikField
                            data={props.roles}
                            required
                            name="Roles"
                            component={GenericDataSourceDropdown}
                            placeholder="Roles"
                            labelFields={['Name']}
                            multiple
                        />
                        {(!props.facilities.length && (
                            <FormikField
                                data={props.departments}
                                required
                                name="Departments"
                                component={GenericToggleList}
                                placeholder="Departments"
                                labelFields={['Name']}
                            />
                        )) ||
                            null}
                        {hasSaveError && (
                            <Message error>
                                <Message.Header>Error saving User</Message.Header>
                                <p>{props.error}</p>
                            </Message>
                        )}
                        <Button type="submit" disabled={isSubmitting} primary>
                            Submit
                        </Button>
                        <Button basic>
                            Cancel
                        </Button>
                        {canDelete() && (
                            <Button basic color="red" loading={props.isSaving} onClick={handleDelete}>
                                Remove User
                            </Button>
                        )}
                    </Form>
                )}
            />
        </div>
    )
}

export default UsersForm
