import React from 'react'
import { Item } from 'semantic-ui-react'
import UserItem from '../UserItem'
import { UserLike } from '../../types'

import './style.less'

interface Props {
    users: UserLike[]
    onClick?: (id: string) => any
}

const UsersList: React.FC<Props> = (props: Props) => {
    const handleClick = (id: string) => {
        if (props.onClick) props.onClick(id)
    }

    const items = props.users.map(user => {
        return (
            <UserItem
                key={user._id}
                id={user._id}
                name={user.FirstName + ' ' + user.LastName}
                avatarUrl={user.Image}
                details={user.Details}
                onClick={() => handleClick(user._id)}
            />
        )
    })

    return (
        <Item.Group divided unstackable>
            {items}
        </Item.Group>
    )
}

export default UsersList
