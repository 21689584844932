import React from 'react';
import { Form, TextArea, Button } from 'semantic-ui-react';
import { DailyActivity } from '../../types';
import Flatpickr from 'react-flatpickr';
import { withRouter, RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
    isFetching: boolean;
    isSaving: boolean;
    editableActivity?: Partial<DailyActivity>
    handleSubmit: (activity) => any;
    handleUpdate: (activity) => any;
    cancelEditActivity: (id?) => any;
    edit?: boolean;
    _id?: string;
    copyActivity?: DailyActivity | null;
}

interface State {
    activity: Partial<DailyActivity>
}

class ActivityForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            activity: {
                text: '',
                timestamp: ''
            }
        }
    }

    setActivityText(text: string) {
        this.setState({
            activity: {
                ...this.state.activity,
                text
            }
        });
    }

    setActivityTime(time) {
        this.setState({
            activity: {
                ...this.state.activity,
                timestamp: Date.parse(time)
            }
        })
    }

    validForm() {
        return this.state.activity.text
                && this.state.activity.timestamp;
    }

    async handleSubmit() {
        if (this.props.edit) {
            const activity = this.state.activity;
            activity._id = this.props._id;
            this.props.handleUpdate(activity);
        } else {
            this.props.handleSubmit(this.state.activity);
        }
        this.setState({
            activity: {
                text: '',
                timestamp: undefined
            },
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.copyActivity && nextProps.copyActivity !== prevState.copyActivity &&
            nextProps.copyActivity.text && nextProps.copyActivity.timestamp) {
            return {activity: {
                text: nextProps.copyActivity.text,
                timestamp: nextProps.copyActivity.timestamp
            },
            copyActivity: nextProps.copyActivity}
        }
        return prevState;
    }

    componentDidMount() {
        if (this.props.editableActivity) {
            this.setState({
                activity: this.props.editableActivity
            });
        }
    }

    render() {
        return (
            <Form loading={this.props.isFetching} autoComplete="false" onSubmit={this.handleSubmit.bind(this)}>
                <Form.Field>
                    <TextArea
                        value={this.state.activity.text || ''}
                        type="textarea"
                        placeholder="Daily Activity"
                        onChange={e => this.setActivityText.call(this, e.currentTarget.value)}
                        rows={this.props.edit ? 3 : 5}
                    />
                </Form.Field>

                <Form.Field>
                    <Flatpickr
                        defaultdate={this.state.activity.timestamp}
                        value={this.state.activity.timestamp}
                        options={{enableTime: true, dateFormat: "d.m.Y h:i K"}} 
                        placeholder='Select Date'
                        onChange={date => this.setActivityTime(date)}
                    />
                </Form.Field>
                <Button
                    type="submit"
                    primary
                    loading={this.props.isSaving}
                    disabled={this.props.isSaving || !this.validForm()}
                >
                    Save Activity
                </Button>
                <Button
                    onClick={() => {
                        if (this.props.edit) {
                            this.props.cancelEditActivity(this.props._id);
                        } else {
                            this.setState({
                                activity: {
                                    text: '',
                                    timestamp: ''
                                }
                            });
                            this.props.cancelEditActivity();
                        }
                    }}
                >
                    Cancel
                </Button>
            </Form>
        );
    }
}

export default withRouter(ActivityForm);