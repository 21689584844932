import React, { MouseEvent, useState } from 'react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message, Confirm } from 'semantic-ui-react'
import * as Yup from 'yup'

import { GenericTextField } from '../../../../components/GenericFormFields'

import './style.less'
import { Unit } from '../../../../types'

export interface UnitFormValues {
    Name: string
    Code: string
    Notes: string
    AlexaProfile: string
}

const ValidationSchema = Yup.object().shape({
    Name: Yup.string().required('Room Name is required'),
    Code: Yup.string().required('You must specify a unique code for this room.'),
    Notes: Yup.string(),
    AlexaProfile: Yup.string(),
})

interface Props {
    unit: Unit | null
    error: string | null
    title: string
    onSave: (data: UnitFormValues) => Promise<any>
    cancel: () => any
    onDelete?: (id: string) => Promise<any>
    isSaving?: boolean
}

const UnitsForm: React.SFC<Props> = (props: Props) => {
    const hasSaveError = props.error ? true : false
    const getInitialValues = () => ({
        Name: (props.unit && props.unit.Name) || '',
        Code: (props.unit && props.unit.Code) || '',
        Notes: (props.unit && props.unit.Notes) || '',
        AlexaProfile: (props.unit && props.unit.AlexaProfile) || '',
    })

    const canDelete = () => {
        return props.onDelete && props.unit && props.unit._id
    }

    const [showConfirm, setShowConfirm] = useState(false)

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (props.onDelete && props.unit && props.unit._id) {
            props.onDelete(props.unit._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }
    const confirm = () => {
        close()
        doDelete()
    }

    return (
        <div className="UnitsForm">
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header="Confirm Delete"
                content="Are you sure you want to delete this item?"
            />
            <h1>{props.title}</h1>
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: UnitFormValues, actions: FormikActions<UnitFormValues>) => {
                    await props.onSave(values)
                    actions.setSubmitting(false)
                }}
                validationSchema={ValidationSchema}
                render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        <FormikField required name="Name" component={GenericTextField} placeholder="Room Name" />
                        <FormikField required name="Code" component={GenericTextField} placeholder="Room Code" />
                        <FormikField name="Notes" component={GenericTextField} placeholder="Notes" />
                        <FormikField name="AlexaProfile" component={GenericTextField} placeholder="Alexa Profile" />
                        {hasSaveError && (
                            <Message error>
                                <Message.Header>Error saving Room</Message.Header>
                                <p>{props.error}</p>
                            </Message>
                        )}
                        <Button type="submit" disabled={isSubmitting} primary>
                            Submit
                        </Button>
                        <Button basic onClick={handleCancel}>
                            Cancel
                        </Button>
                        {canDelete() && (
                            <Button basic color="red" loading={props.isSaving} onClick={handleDelete}>
                                Remove Room
                            </Button>
                        )}
                    </Form>
                )}
            />
        </div>
    )
}

export default UnitsForm
