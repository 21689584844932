import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../../../actions/requests'
import './style.less'
import { AppState } from '../../../../reducers'
import Requests from '../../../../components/Requests'

function mapStateToProps({ requestsReducer, authReducer }: AppState) {
    return {
        requests: requestsReducer.requests,
        hasError: requestsReducer.hasError,
        errorCode: requestsReducer.errorCode,
        isFetching: requestsReducer.fetching,
        page: requestsReducer.page,
        count: requestsReducer.count,
        limit: requestsReducer.limit,
        unassignedRequests: requestsReducer.unassignedRequests,
        unassignedRequestHasError: requestsReducer.unassignedRequestHasError,
        unassignedRequestErrorCode: requestsReducer.unassignedRequestErrorCode,
        unassignedRequestIsFetching: requestsReducer.unassignedRequestFetching,
        unassignedRequestPage: requestsReducer.unassignedRequestPage,
        unassignedRequestCount: requestsReducer.unassignedRequestCount,
        unassignedRequestLimit: requestsReducer.unassignedRequestLimit,
        userId: authReducer.profile && authReducer.profile._id,
        unassigned: true,
        requestTitle: 'Unassigned Requests',
        emptyMessage: 'No Unassigned Requests',
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.RequestsAction>) {
    return {
        onFetchRequests: () => dispatch(actions.fetchUnassignedRequests()),
        onBgFetchRequests: () => dispatch(actions.fetchUnassignedRequests(false)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Requests))
