import React, { Component, ChangeEvent } from 'react'
import { Dropdown, Input } from 'semantic-ui-react'

import './style.less'
import { User } from '../../types'

interface Props {
    users: User[]
    loading?: boolean
    disabled?: boolean
    onSelectionChange?: (u: User | null) => void
}

interface State {
    searchInput: string
}
class AssignButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            searchInput: '',
        }
    }

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.setState({
            searchInput: e.currentTarget.value,
        })
    }

    getFilteredUsersOptions() {
        return this.props.users
            .filter(u => {
                const fullName = (u.FirstName + ' ' + u.LastName).toLowerCase()
                return fullName.includes(this.state.searchInput.toLowerCase())
            })
            .map(u => ({
                key: u._id,
                text: u.FirstName + ' ' + u.LastName,
                value: u._id,
                image: { avatar: true, src: u.Icon || `${process.env.PUBLIC_URL}/avatar_placeholder.png` },
            }))
    }

    handleSelect(_: any, data: any) {
        console.log(data)
        if (!this.props.onSelectionChange) return
        const user = this.props.users.find(u => u._id === data.value) || null
        this.props.onSelectionChange(user)
    }

    render() {
        return (
            <Dropdown
                scrolling
                text="Assign"
                icon="add"
                labeled
                button
                basic
                className="icon button primary AssignButton"
                loading={this.props.loading}
                disabled={this.props.loading || this.props.disabled}
            >
                <Dropdown.Menu>
                    <Input
                        icon="search"
                        iconPosition="left"
                        className="search"
                        onClick={(e: Event) => e.stopPropagation()}
                        value={this.state.searchInput}
                        onChange={this.handleChange.bind(this)}
                    />
                    <Dropdown.Divider />
                    <Dropdown.Header content="Assign To" />
                    {this.getFilteredUsersOptions().map(option => (
                        <Dropdown.Item key={option.value} {...option} onClick={this.handleSelect.bind(this)} />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export default AssignButton
