import API from './API'
import { Registrant, Unit } from '../types'

export async function fetchRegistrants(): Promise<Registrant[]> {
    /*
        Updated to use lambdaPost because the back-end
        architecture of lambdaGet doesn't allow to pass filters
    */
    const res = await API.lambdaPost('/registrants/list', {
        Filter: { IsActive: 1 },
    })
    const registrants = res.Result as Registrant[]
    return registrants.filter(u => u.IsActive === 1)
}

export async function fetchPaginatedRegistrants(page_no = 1, page_size = 100): Promise<Registrant[]> {
    const res = await API.lambdaPost('/registrants/list', {
        Filter: {
            IsActive: 1,
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ LastName: 'desc' }],
        },
    })

    const registrants = res.Result as Registrant[]
    return registrants
}

export async function fetchOneRegistrant(id: string): Promise<Registrant> {
    const res = await API.lambdaGet(`/registrants/get?_id=${id}`)
    return res as Registrant
}

export async function assignRegistrantToUnit(registrantId: string, unitId: string): Promise<any> {
    const res = await API.lambdaPost('/registrants/units/add', {
        Registrant: registrantId,
        Unit: unitId,
    })
    return res
}

export async function unAssignRegistrantFromUnit(registrantId: string): Promise<any> {
    const res = await API.lambdaPost('/registrants/units/add', {
        Registrant: registrantId,
    })
    return res
}

export async function createAndAssignRegistrant(reg: Partial<Registrant>, unitId: string): Promise<Registrant> {
    const createdId = await API.lambdaPost('/registrants/add', reg)
    await assignRegistrantToUnit(createdId, unitId)
    const createdReg = await fetchOneRegistrant(createdId)
    return createdReg
}

export async function updateRegistrant(
    registrantId: string,
    updatedFields: Partial<Registrant>,
    unit: Unit,
): Promise<Registrant> {
    updatedFields.Unit = unit
    await API.lambdaPost(`/registrants/update`, updatedFields)

    const updated = await fetchOneRegistrant(registrantId)
    return updated
}

export async function deleteRegistrant(registrantId: string): Promise<any> {
    await API.lambdaPost(`/registrants/update`, {
        _id: registrantId,
        IsActive: 0,
        Unit: null,
    })
}
