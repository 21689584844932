import React from 'react'
import { Notification } from '../../../types'
import { Comment } from 'semantic-ui-react'
import { distanceInWordsToNow } from 'date-fns'

interface Props {
    userName: string | null
    imageUrl: string | null
    notification: Notification
}

const NotificationItem: React.FC<Props> = (props: Props) => {
    const imageUrl = props.imageUrl || `${process.env.PUBLIC_URL}/avatar_placeholder.png`
    return (
        <Comment className="NotificationItem Comment">
            <Comment.Avatar src={imageUrl} />
            <Comment.Content>
                {props.userName && (
                    <Comment.Author as="a">{props.userName}</Comment.Author>
                )}
                {!props.userName && <Comment.Author as="a">Unknown User</Comment.Author>}
                <Comment.Metadata>
                    <div>{distanceInWordsToNow(props.notification.DateAdded)} ago</div>
                </Comment.Metadata>
                <Comment.Text>{props.notification.Content.Message}</Comment.Text>
            </Comment.Content>
        </Comment>
    )
}

export default NotificationItem
