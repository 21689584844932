import React, { MouseEvent, useState } from 'react'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message, Confirm } from 'semantic-ui-react'
import * as Yup from 'yup'
import moment from 'moment-timezone'

import { GenericTextField, GenericDropdown, GenericCheckbox } from '../../../../components/GenericFormFields'
import countries from './countries'

import './style.less'
import { Facility } from '../../../../types'

const countryOptions = countries.map((c, i) => ({
    key: i,
    text: c,
    value: c,
}))

const timezoneOptions = moment.tz.names().map(c => ({
    key: c,
    text: c,
    value: c,
}))

export interface FacilityFormValues {
    Name: string
    Phone1: string
    Phone2: string
    Phone3: string
    Email: string
    URL: string
    // TODO: map
    // Lat: 21
    // Lon: 41
    Country: string
    State: string
    City: string
    Address: string
    ZIP: string
    Code: string
    Notes: string
    AlexaProfile: string
    isA4HProfile: boolean
    FacilityTimeZone?: string
}

const FacilitySchema = Yup.object().shape({
    Name: Yup.string().required('Facility Name is required'),
    Phone1: Yup.string(),
    Phone2: Yup.string(),
    Phone3: Yup.string(),
    Email: Yup.string()
        .email('Invalid Email address')
        .required(),
    URL: Yup.string()
        .url('Invalid URL')
        .required(),
    Country: Yup.string(),
    State: Yup.string(),
    City: Yup.string(),
    Address: Yup.string(),
    ZIP: Yup.string(),
    Code: Yup.string().required('You must specify a unique code for this facility.'),
    Notes: Yup.string(),
    AlexaProfile: Yup.string().required('You must specify an Alexa Profile'),
    isA4HProfile: Yup.boolean(),
    FacilityTimeZone: Yup.string().required('You must specify an timezone'),
})

interface Props {
    title: string
    facility: Facility | null
    error: string | null
    onSave: (data: FacilityFormValues) => Promise<any>
    cancel: () => any
    onDelete?: (facilityId: string) => Promise<any>
    isSaving?: boolean
}

const FacilitiesForm: React.SFC<Props> = (props: Props) => {
    const hasSaveError = props.error ? true : false
    const getInitialValues = () => ({
        Name: (props.facility && props.facility.Name) || '',
        Phone1: (props.facility && props.facility.Phone1) || '',
        Phone2: (props.facility && props.facility.Phone2) || '',
        Phone3: (props.facility && props.facility.Phone3) || '',
        Email: (props.facility && props.facility.Email) || '',
        URL: (props.facility && props.facility.URL) || '',
        Country: (props.facility && props.facility.Country) || '',
        State: (props.facility && props.facility.State) || '',
        City: (props.facility && props.facility.City) || '',
        Address: (props.facility && props.facility.Address) || '',
        ZIP: (props.facility && props.facility.ZIP) || '',
        Code: (props.facility && props.facility.Code) || '',
        Notes: (props.facility && props.facility.Notes) || '',
        AlexaProfile: (props.facility && props.facility.AlexaProfile) || '',
        isA4HProfile: (props.facility && props.facility.isA4HProfile) || false,
        FacilityTimeZone: (props.facility && props.facility.FacilityTimeZone) || 'America/New_York',
    })

    const canDelete = () => {
        return props.onDelete && props.facility && props.facility._id
    }

    const [showConfirm, setShowConfirm] = useState(false)

    const handleDelete = (e: MouseEvent) => {
        e.preventDefault()
        setShowConfirm(true)
    }

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault()
        props.cancel()
    }

    const doDelete = () => {
        if (props.onDelete && props.facility && props.facility._id) {
            props.onDelete(props.facility._id)
        }
    }

    const close = () => {
        setShowConfirm(false)
    }
    const confirm = () => {
        close()
        doDelete()
    }

    return (
        <div className="FacilitiesForm">
            <Confirm
                open={showConfirm}
                onCancel={close}
                onConfirm={confirm}
                header="Confirm Delete"
                content="Are you sure you want to delete this item?"
            />
            <h1>{props.title}</h1>
            <Formik
                initialValues={getInitialValues()}
                onSubmit={async (values: FacilityFormValues, actions: FormikActions<FacilityFormValues>) => {
                    console.log(values)
                    await props.onSave(values)
                    actions.setSubmitting(false)
                }}
                validationSchema={FacilitySchema}
                render={({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                    <Form
                        onSubmit={handleSubmit}
                        loading={isSubmitting}
                        error={Object.keys(errors).length > 0 || hasSaveError}
                    >
                        <FormikField required name="Name" component={GenericTextField} placeholder="Facility Name" />
                        <FormikField name="Phone1" component={GenericTextField} placeholder="Phone 1" />
                        <FormikField name="Phone2" component={GenericTextField} placeholder="Phone 2" />
                        <FormikField name="Phone3" component={GenericTextField} placeholder="Phone 3" />
                        <FormikField
                            required
                            name="Email"
                            component={GenericTextField}
                            placeholder="Email"
                            type="email"
                        />
                        <FormikField required name="URL" component={GenericTextField} placeholder="Facility URL" />
                        <FormikField
                            name="Country"
                            component={GenericDropdown}
                            placeholder="Country"
                            options={countryOptions}
                            search
                        />
                        <FormikField
                            required
                            name="FacilityTimeZone"
                            component={GenericDropdown}
                            placeholder="timezone"
                            options={timezoneOptions}
                            search
                        />
                        <FormikField name="State" component={GenericTextField} placeholder="State" />
                        <FormikField name="City" component={GenericTextField} placeholder="City" />
                        <FormikField name="Address" component={GenericTextField} placeholder="Address" />
                        <FormikField name="ZIP" component={GenericTextField} placeholder="ZIP" />
                        <FormikField required name="Code" component={GenericTextField} placeholder="Facility Code" />
                        <FormikField name="Notes" component={GenericTextField} placeholder="Notes" />
                        <FormikField name="isA4HProfile" component={GenericCheckbox} placeholder="A4H Facility?" />

                        <FormikField
                            required
                            name="AlexaProfile"
                            component={GenericTextField}
                            placeholder="Alexa Profile"
                        />

                        {hasSaveError && (
                            <Message error>
                                <Message.Header>Error saving Facility</Message.Header>
                                <p>{props.error}</p>
                            </Message>
                        )}
                        <Button type="submit" disabled={isSubmitting} primary>
                            Submit
                        </Button>
                        <Button basic onClick={handleCancel}>
                            Cancel
                        </Button>
                        {canDelete() && (
                            <Button basic color="red" loading={props.isSaving} onClick={handleDelete}>
                                Remove Facility
                            </Button>
                        )}
                    </Form>
                )}
            />
        </div>
    )
}

export default FacilitiesForm
