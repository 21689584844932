import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import './style.less'

interface Props {
    percentage: number
    color: string
    textTop: string
    textBottom: string
    onClick: (event: any) => void
}

const RadialProgress: React.FC<Props> = (props: Props) => {
    return (
        <div className="radial-progress" onClick={props.onClick}>
            <CircularProgressbarWithChildren
                value={props.percentage}
                styles={{
                    trail: {
                        strokeWidth: 4,
                        stroke: props.color,
                    },
                    text: {
                        textAnchor: 'middle',
                        alignmentBaseline: 'middle',
                    },
                }}
            >
                <div className="text-top">{props.textTop}</div>
            </CircularProgressbarWithChildren>
            <div className="text-bottom">{props.textBottom}</div>
        </div>
    )
}

export default RadialProgress
