import React from 'react'
import { Form, Message } from 'semantic-ui-react'
import { FieldProps } from 'formik'
import ToggleList from '../../ToggleList'

interface CustomProps {
    required?: boolean
    data: any[]
    labelFields: string[]
    placeholder: string
    disabled?: boolean
}

const GenericToggleList: React.FC<any> = (props: FieldProps<any> & CustomProps) => {
    const errMsg = (
        <Message error>
            <p>{props.form.errors[props.field.name]}</p>
        </Message>
    )
    const hasError = props.form.errors[props.field.name] && props.form.touched[props.field.name] ? true : false

    return (
        <Form.Field required={props.required || false} error={hasError}>
            <label>{props.placeholder}</label>
            <ToggleList
                {...props}
                onSelectionChange={(items: any) => props.form.setFieldValue(props.field.name, items)}
                selected={props.field.value}
            />
            {hasError && errMsg}
        </Form.Field>
    )
}

export default GenericToggleList
