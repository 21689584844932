import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../../../actions/requests'
import './style.less'
import { AppState } from '../../../../reducers'
import Requests from '../../../../components/Requests'

function mapStateToProps({ requestsReducer, authReducer }: AppState) {
    return {
        requests: requestsReducer.requests,
        hasError: requestsReducer.hasError,
        errorCode: requestsReducer.errorCode,
        isFetching: requestsReducer.fetching,
        page: requestsReducer.page,
        count: requestsReducer.count,
        limit: requestsReducer.limit,
        userId: authReducer.profile && authReducer.profile._id,
        unassigned: false,
        requestTitle: 'My Requests',
        emptyMessage: 'No Open Requests',
    }
}

function mapDispatchToOpenedProps(dispatch: ThunkDispatch<any, any, actions.RequestsAction>) {
    return {
        onFetchRequests: (userId: string) => {
            console.log(userId, 'UserId')
            return dispatch(actions.fetchAndFilter(userId, 'OPEN'))
        },
        onBgFetchRequests: (userId: string) => {
            return dispatch(actions.fetchAndFilter(userId, 'OPEN', false, 'default'))
        },
    }
}
const OpenRequestsComponent = connect(
    mapStateToProps,
    mapDispatchToOpenedProps,
    // @ts-ignore
)(withRouter(Requests))

function OpenRequests() {
    return <OpenRequestsComponent />
}

export default OpenRequests
