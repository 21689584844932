import React from 'react'
import UsersForm, { FormValues } from '../UsersForm'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchDepartments } from '../../../../services/Departments'
import { Dimmer, Loader } from 'semantic-ui-react'
import { Department, UserRole, Facility, UserProfile } from '../../../../types'
import { createUserWithDepartments, fetchAssignableUserRoles } from '../../../../services/Users';
import { fetchFacilities } from '../../../../services/Facilities';
import { isSysAdmin } from '../../../../services/Permissions';

interface State {
    isSaving: boolean
    error: string | null
    isFetching: boolean
    departments: Department[]
    roles: UserRole[]
    facilities: Facility[]
}

interface Props extends RouteComponentProps {
    profile: UserProfile
}

class RequestTypesCreate extends React.Component<Props, State> {
    state = {
        isSaving: false,
        error: null,
        isFetching: false,
        departments: [],
        roles: [],
        facilities: [],
    }

    goBack() {
        this.props.history.goBack()
    }

    async facilitiesPromise() {
        if (isSysAdmin(this.props.profile)) return fetchFacilities()
        return Promise.resolve([])
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const [departments, roles, facilities] = await Promise.all([
                fetchDepartments(),
                fetchAssignableUserRoles(),
                this.facilitiesPromise()
            ])
            this.setState({ isFetching: false, departments, roles, facilities })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            // console.log(data)
            const saveData = { ...data };
            delete saveData.VerificationCode;
            delete saveData.PasswordReset;
            delete saveData.ConfirmPasswordReset;
            await createUserWithDepartments(saveData, saveData.Departments, saveData.Roles);
            this.goBack();
        } catch (e) {
            this.setState({ isSaving: false, error: e.message });
        }
    }

    render() {
        return (
            <div className="RequestTypesCreate">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.departments.length && (
                    <UsersForm
                        title="Create User"
                        user={null}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(RequestTypesCreate)
