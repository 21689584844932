import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import './style.less'
import { Request } from '../../types'
import { Loader, Dimmer } from 'semantic-ui-react'
import RequestsList from '../RequestsList'
import { registerOnNotificationHandler } from '../../services/PushNotifications'

const REFRESH_REQUESTS_TIME_MS = 20000

interface Props extends RouteComponentProps {
    onFetchRequests: (userId: string) => void
    onBgFetchRequests?: () => any
    requests: Request[]
    count: number
    page: number
    limit: number
    hasError: boolean
    errorCode: string | null
    isFetching: boolean
    userId: string | null
    unassignedRequests?: Request[]
    unassignedRequestCount?: number
    unassignedRequestPage?: number
    unassignedRequestLimit?: number
    unassignedRequestHasError?: boolean
    unassignedRequestErrorCode?: string | null
    unassignedRequestIsFetching?: boolean
    unassigned: boolean
    requestTitle?: string
    emptyMessage?: string
}

class Requests extends React.Component<Props> {
    intervalId: number | null = null
    unsubscribeHandler: firebase.Unsubscribe | null = null
    componentDidMount() {
        if (!this.props.userId) return
        this.props.onFetchRequests(this.props.userId)
        if (this.props.onBgFetchRequests) {
            this.intervalId = window.setInterval(this.props.onBgFetchRequests, REFRESH_REQUESTS_TIME_MS)
            // Refresh requests on notification
            this.unsubscribeHandler = registerOnNotificationHandler(this.props.onBgFetchRequests, this.props.history)
        }
    }

    renderErrorMessage() {
        const { hasError, errorCode, unassignedRequestHasError, unassignedRequestErrorCode, unassigned } = this.props
        let message = ''
        if (
            errorCode === 'NotAuthorizedException' ||
            errorCode === 'UserNotFoundException' ||
            unassignedRequestErrorCode === 'NotAuthorizedException' ||
            unassignedRequestErrorCode === 'UserNotFoundException'
        ) {
            message = 'Incorrect username or password'
        } else {
            message = 'Error during Log In process.'
        }

        return (unassigned ? (
            unassignedRequestHasError
        ) : (
            hasError
        )) ? (
            <div style={{ color: 'red' }}>{message}</div>
        ) : null
    }

    goToSingle(id: string) {
        this.props.history.push(`/request/${id}`)
    }

    componentWillUnmount() {
        if (this.intervalId) window.clearInterval(this.intervalId)
        if (this.unsubscribeHandler) this.unsubscribeHandler()
    }

    render() {
        return (
            <div className="Requests">
                <Dimmer
                    active={
                        this.props.unassigned ? this.props.unassignedRequestIsFetching || false : this.props.isFetching
                    }
                    inverted
                >
                    <Loader
                        active={
                            this.props.unassigned
                                ? this.props.unassignedRequestIsFetching || false
                                : this.props.isFetching
                        }
                    />
                </Dimmer>
                <h2>{this.props.requestTitle ? this.props.requestTitle : 'Requests'}</h2>
                {(this.props.unassigned && this.props.unassignedRequests
                    ? this.props.unassignedRequests.length
                    : this.props.requests.length) !== 0 ? (
                    <RequestsList
                        requests={this.props.unassigned ? this.props.unassignedRequests || [] : this.props.requests}
                        onClick={this.goToSingle.bind(this)}
                        unassigned={this.props.unassigned}
                    />
                ) : (
                    <div className="no-requests">
                        {this.props.emptyMessage ? this.props.emptyMessage : 'No Requests'}
                    </div>
                )}
            </div>
        )
    }
}

export default Requests
