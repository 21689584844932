import React from 'react'
import UnitsForm, { UnitFormValues } from '../UnitsForm'
import { withRouter, RouteComponentProps } from 'react-router'
import { createUnit } from '../../../../services/Units'

interface State {
    isSaving: boolean
    error: string | null
}

class UnitsCreate extends React.Component<RouteComponentProps, State> {
    state = {
        isSaving: false,
        error: null,
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: UnitFormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            await createUnit(data)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    render() {
        return (
            <UnitsForm
                cancel={this.goBack.bind(this)}
                title="Create Room"
                unit={null}
                onSave={this.handleSave.bind(this)}
                {...this.state}
            />
        )
    }
}

export default withRouter(UnitsCreate)
