import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import RegistrantEditor from '../RegistrantEditor'
import { Unit, Registrant, User } from '../../../../types'
import { fetchPaginatedUnits } from '../../../../services/Units'

import { fetchOneRegistrant, updateRegistrant, deleteRegistrant } from '../../../../services/Registrants'
import { fetchStaffRoleUsers } from '../../../../services/Users'

interface State {
    units: Unit[]
    isFetching: boolean
    hasError: boolean
    error: string | null
    isSaving: boolean
    registrant: Registrant | null
    staffUsers: (User | { _id: string; FirstName: string })[]
}
interface RouteInfo {
    id: string
}

class EditRegistrants extends React.Component<RouteComponentProps<RouteInfo>, State> {
    id: string
    constructor(props: RouteComponentProps<RouteInfo>) {
        super(props)
        this.id = props.match.params.id
        this.state = {
            units: [],
            isFetching: false,
            hasError: false,
            error: null,
            isSaving: false,
            registrant: null,
            staffUsers: []
        }
    }

    async componentDidMount() {
        try {
            this.setState({
                isFetching: true,
                hasError: false,
                error: null,
            })
            const [units, registrant, staffUsers] = await Promise.all([fetchPaginatedUnits(1,500), fetchOneRegistrant(this.id), fetchStaffRoleUsers()])

            // If the value of PrimaryCaregiver in DB is 'Other', map the value of PrimaryCaregiver to object format to make it compatible with
            // existing code and semantic-ui dropdown element
            if (registrant.PrimaryCaregiver === 'Other') {
                registrant.PrimaryCaregiver = { _id: '1', FirstName: 'Other' };
            } else if (registrant.PrimaryCaregiver && staffUsers.length) {
                // If it holds a value other than 'Other', map it to the object of the caregiver (User), to make it compatible with
                // exissting code and semantic-ui dropdown element
                registrant.PrimaryCaregiver = staffUsers.filter(user => {
                    return user._id === registrant.PrimaryCaregiver;
                })[0];
            }

            this.setState({
                isFetching: false,
                hasError: false,
                units,
                registrant,
                staffUsers
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message || 'Could not fetch registrant, units or .',
            })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(registrant: Partial<Registrant>, unit: Unit) {
        if (!registrant._id) return
        this.setState({
            isSaving: true,
            hasError: false,
            error: null,
        })
        try {
            await updateRegistrant(registrant._id, registrant, unit)
            this.setState({
                isSaving: false,
                hasError: false,
                error: null,
            })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                hasError: true,
                error: e.message || 'Could not save Resident.',
            })
        }
    }

    async handleDelete(registrantId: string) {
        this.setState({
            isSaving: true,
            hasError: false,
            error: null,
        })
        try {
            await deleteRegistrant(registrantId)
            this.setState({
                isSaving: false,
                hasError: false,
            })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    render() {
        return (
            <div className="EditRegistrant">
                <RegistrantEditor
                    cancel={this.goBack.bind(this)}
                    {...this.state}
                    onSave={this.handleSave.bind(this)}
                    onDelete={this.handleDelete.bind(this)}
                />
            </div>
        )
    }
}

export default withRouter(EditRegistrants)
