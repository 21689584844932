import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import * as RequestsService from '../services/Requests'
import { FetchRequestsResponse } from '../types'

export const FETCH_REQUESTS = 'requests/FETCH_REQUESTS'
export type FETCH_REQUESTS = typeof FETCH_REQUESTS

export const SET_FETCHING = 'requests/SET_FETCHING'
export type SET_FETCHING = typeof SET_FETCHING

export const SET_PAGINATE_LOADING = 'requests/SET_PAGINATE_LOADING'
export type SET_PAGINATE_LOADING = typeof SET_PAGINATE_LOADING

export const SET_REQUESTS = 'requests/SET_REQUESTS'
export type SET_REQUESTS = typeof SET_REQUESTS

export const SET_PAGINATED_REQUESTS = 'requests/SET_PAGINATED_REQUESTS'
export type SET_PAGINATED_REQUESTS = typeof SET_PAGINATED_REQUESTS

export const SET_BG_REQUESTS = 'requests/SET_BG_REQUESTS'
export type SET_BG_REQUESTS = typeof SET_BG_REQUESTS

export const SET_ERROR = 'requests/SET_ERROR'
export type SET_ERROR = typeof SET_ERROR

export const UNASSIGNED_REQUESTS_FETCH_REQUESTS = 'requests/UNASSIGNED_REQUESTS_FETCH_REQUESTS'
export type UNASSIGNED_REQUESTS_FETCH_REQUESTS = typeof UNASSIGNED_REQUESTS_FETCH_REQUESTS

export const UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING = 'requests/UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING'
export type UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING = typeof UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING

export const UNASSIGNED_REQUESTS_SET_FETCHING = 'requests/UNASSIGNED_REQUESTS_SET_FETCHING'
export type UNASSIGNED_REQUESTS_SET_FETCHING = typeof UNASSIGNED_REQUESTS_SET_FETCHING

export const UNASSIGNED_REQUESTS_SET_REQUESTS = 'requests/UNASSIGNED_REQUESTS_SET_REQUESTS'
export type UNASSIGNED_REQUESTS_SET_REQUESTS = typeof UNASSIGNED_REQUESTS_SET_REQUESTS

export const UNASSIGNED_REQUESTS_SET_BG_REQUESTS = 'requests/UNASSIGNED_REQUESTS_SET_BG_REQUESTS'
export type UNASSIGNED_REQUESTS_SET_BG_REQUESTS = typeof UNASSIGNED_REQUESTS_SET_BG_REQUESTS

export const UNASSIGNED_REQUESTS_SET_ERROR = 'requests/UNASSIGNED_REQUESTS_SET_ERROR'
export type UNASSIGNED_REQUESTS_SET_ERROR = typeof UNASSIGNED_REQUESTS_SET_ERROR

export interface FetchRequests {
    type: FETCH_REQUESTS
}

export interface SetFetching {
    type: SET_FETCHING
    isFetching: boolean
}

export interface SetPaginateLoading {
    type: SET_PAGINATE_LOADING
    isPaginateLoading: boolean
}

export interface SetRequests {
    type: SET_REQUESTS
    response: FetchRequestsResponse
}

export interface SetPaginatedRequests {
    type: SET_PAGINATED_REQUESTS
    response: FetchRequestsResponse
}

export interface SetBgRequests {
    type: SET_BG_REQUESTS
    response: FetchRequestsResponse
}

export interface SetError {
    type: SET_ERROR
    errorCode: string | null
    hasError: boolean
}

export type RequestsAction =
    | FetchRequests
    | SetFetching
    | SetPaginateLoading
    | SetPaginatedRequests
    | SetBgRequests
    | SetRequests
    | SetError

export interface UnassignedFetchRequests {
    type: UNASSIGNED_REQUESTS_FETCH_REQUESTS
}

export interface UnassignedSetFetching {
    type: UNASSIGNED_REQUESTS_SET_FETCHING
    isFetching: boolean
}

export interface UnassignedSetPaginateLoading {
    type: UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING
    isPaginateLoading: boolean
}

export interface UnassignedSetRequests {
    type: UNASSIGNED_REQUESTS_SET_REQUESTS
    response: FetchRequestsResponse
}

export interface UnassignedSetBgRequests {
    type: UNASSIGNED_REQUESTS_SET_BG_REQUESTS
    response: FetchRequestsResponse
}

export interface UnassignedSetError {
    type: UNASSIGNED_REQUESTS_SET_ERROR
    errorCode: string | null
    hasError: boolean
}

export interface requestTypeItems {
    setFetching: SET_FETCHING | UNASSIGNED_REQUESTS_SET_FETCHING
    setPaginateLoading: SET_PAGINATE_LOADING | UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING
    setRequests: SET_REQUESTS | UNASSIGNED_REQUESTS_SET_REQUESTS
    setBgRequests: SET_BG_REQUESTS | UNASSIGNED_REQUESTS_SET_BG_REQUESTS
    setPaginatedRequests: SET_PAGINATED_REQUESTS
    setError: SET_ERROR | UNASSIGNED_REQUESTS_SET_ERROR
}

export interface requestTypeReference {
    unassigned: requestTypeItems
    default: requestTypeItems
}

export type UnassignedRequestsAction =
    | UnassignedFetchRequests
    | UnassignedSetFetching
    | UnassignedSetPaginateLoading
    | UnassignedSetRequests
    | UnassignedSetBgRequests
    | UnassignedSetError

const requestTypeReference = {
    unassigned: {
        setFetching: UNASSIGNED_REQUESTS_SET_FETCHING,
        setPaginateLoading: UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING,
        setRequests: UNASSIGNED_REQUESTS_SET_REQUESTS,
        setBgRequests: UNASSIGNED_REQUESTS_SET_BG_REQUESTS,
        setError: UNASSIGNED_REQUESTS_SET_ERROR,
    },
    default: {
        setFetching: SET_FETCHING,
        setPaginateLoading: SET_PAGINATE_LOADING,
        setRequests: SET_REQUESTS,
        setPaginatedRequests: SET_PAGINATED_REQUESTS,
        setBgRequests: SET_BG_REQUESTS,
        setError: SET_ERROR,
    },
}

// Action Creators

export function setFetching(
    isFetching: boolean,
    requestType: keyof requestTypeReference = 'default',
): SetFetching | UnassignedSetFetching {
    return {
        // @ts-ignore // TODO: Resolve typescript Error
        type: requestTypeReference[requestType].setFetching,
        isFetching,
    }
}

export function setPaginateLoading(
    isPaginateLoading: boolean,
    requestType: keyof requestTypeReference = 'default',
): SetPaginateLoading | UnassignedSetPaginateLoading {
    return {
        // @ts-ignore // TODO: Resolve typescript Error
        type: requestTypeReference[requestType].setPaginateLoading,
        isPaginateLoading,
    }
}

export function setRequests(
    response: FetchRequestsResponse,
    requestType: keyof requestTypeReference = 'default',
): SetRequests {
    return {
        // @ts-ignore // TODO: Resolve typescript Error
        type: requestTypeReference[requestType].setRequests,
        response,
    }
}

export function setPaginatedRequests(
    response: FetchRequestsResponse,
    requestType: keyof requestTypeReference = 'default',
): SetPaginatedRequests {
    return {
        // @ts-ignore // TODO: Resolve typescript Error
        type: requestTypeReference[requestType].setPaginatedRequests,
        response,
    }
}

export function setBgRequests(
    response: FetchRequestsResponse,
    requestType: keyof requestTypeReference = 'default',
): SetPaginatedRequests {
    return {
        // @ts-ignore // TODO: Resolve typescript Error
        type: requestTypeReference[requestType].setBgRequests,
        response,
    }
}

export function setError(
    hasError: boolean,
    errorCode: string | null,
    requestType: keyof requestTypeReference = 'default',
): SetError {
    return {
        // @ts-ignore // TODO: Resolve typescript Error
        type: requestTypeReference[requestType].setError,
        hasError,
        errorCode,
    }
}

export function fetchRequests(
    withLoading = true,
    requestType: keyof requestTypeReference = 'default',
    paginated: boolean = false,
): ThunkAction<Promise<void>, {}, {}, RequestsAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        if (withLoading) dispatch(setFetching(true, requestType))
        if (paginated) dispatch(setPaginateLoading(true, requestType))
        dispatch(setError(false, null, requestType))
        try {
            const response = await RequestsService.fetchUnassignedRequests()
            if (paginated) {
                dispatch(setPaginatedRequests(response, requestType))
            } else if (!withLoading) {
                dispatch(setBgRequests(response, requestType))
            } else {
                dispatch(setRequests(response, requestType))
            }
        } catch (e) {
            console.log(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setError(true, errorCode, requestType))
        }
        if (withLoading) dispatch(setFetching(false, requestType))
        if (paginated) dispatch(setPaginateLoading(false, requestType))
    }
}

export function fetchUnassignedRequests(withLoading = true): ThunkAction<Promise<void>, {}, {}, RequestsAction> {
    return fetchRequests(withLoading, 'unassigned')
}

export function fetchAllRequests(
    withLoading = true,
    requestType: keyof requestTypeReference = 'default',
    paginated: boolean = false,
): ThunkAction<Promise<void>, {}, {}, RequestsAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        if (withLoading) dispatch(setFetching(true, requestType))
        if (paginated) dispatch(setPaginateLoading(true, requestType))
        dispatch(setError(false, null, requestType))
        try {
            const response = await RequestsService.fetchAllRequests()
            if (paginated) {
                dispatch(setPaginatedRequests(response, requestType))
            } else if (!withLoading) {
                dispatch(setBgRequests(response, requestType))
            } else {
                dispatch(setRequests(response, requestType))
            }
        } catch (e) {
            console.log(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setError(true, errorCode, requestType))
        }
        if (withLoading) dispatch(setFetching(false, requestType))
        if (paginated) dispatch(setPaginateLoading(false, requestType))
    }
}

export function fetchActiveRequests(
    withLoading = true,
    requestType: keyof requestTypeReference = 'default',
    pageNo: number = 1,
    pageSize: number = 100,
    paginated: boolean = false,
): ThunkAction<Promise<void>, {}, {}, RequestsAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        if (withLoading) dispatch(setFetching(true, requestType))
        if (paginated) dispatch(setPaginateLoading(true, requestType))
        dispatch(setError(false, null, requestType))
        try {
            const response = await RequestsService.fetchActiveRequests(pageNo, pageSize)
            if (paginated) {
                dispatch(setPaginatedRequests(response, requestType))
            } else if (!withLoading) {
                dispatch(setBgRequests(response, requestType))
            } else {
                dispatch(setRequests(response, requestType))
            }
        } catch (e) {
            console.log(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setError(true, errorCode, requestType))
        }
        if (withLoading) dispatch(setFetching(false, requestType))
        if (paginated) dispatch(setPaginateLoading(false, requestType))
    }
}

export function fetchAndFilter(
    userId: string,
    filter: 'OPEN' | 'CLOSED',
    withLoading = true,
    requestType: keyof requestTypeReference = 'default',
    paginated: boolean = false,
): ThunkAction<Promise<void>, {}, {}, RequestsAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        if (withLoading) dispatch(setFetching(true, requestType))
        if (paginated) dispatch(setPaginateLoading(true, requestType))
        dispatch(setError(false, null, requestType))
        try {
            const fetcher =
                filter === 'OPEN' ? RequestsService.fetchMyOpenRequests : RequestsService.fetchMyClosedRequests
            const response = await fetcher(userId)
            if (paginated) {
                dispatch(setPaginatedRequests(response, requestType))
            } else if (!withLoading) {
                dispatch(setBgRequests(response, requestType))
            } else {
                dispatch(setRequests(response, requestType))
            }
        } catch (e) {
            console.log(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setError(true, errorCode, requestType))
        }
        if (withLoading) dispatch(setFetching(false, requestType))
        if (paginated) dispatch(setPaginateLoading(false, requestType))
    }
}

export function fetchEscalatedRequests(
    withLoading = true,
    requestType: keyof requestTypeReference = 'default',
    pageNo: number = 1,
    pageSize: number = 100,
    paginated: boolean = false,
): ThunkAction<Promise<void>, {}, {}, RequestsAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        if (withLoading) dispatch(setFetching(true, requestType))
        if (paginated) dispatch(setPaginateLoading(true, requestType))
        dispatch(setError(false, null, requestType))
        try {
            const response = await RequestsService.fetchEscalatedRequests(pageNo, pageSize)
            if (paginated) {
                dispatch(setPaginatedRequests(response, requestType))
            } else if (!withLoading) {
                dispatch(setBgRequests(response, requestType))
            } else {
                dispatch(setRequests(response, requestType))
            }
        } catch (e) {
            console.log(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setError(true, errorCode, requestType))
        }
        if (withLoading) dispatch(setFetching(false, requestType))
        if (paginated) dispatch(setPaginateLoading(false, requestType))
    }
}

export function fetchResolvedRequests(
    withLoading = true,
    requestType: keyof requestTypeReference = 'default',
    pageNo: number = 1,
    pageSize: number = 100,
    paginated: boolean = false,
): ThunkAction<Promise<void>, {}, {}, RequestsAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        if (withLoading) dispatch(setFetching(true, requestType))
        if (paginated) dispatch(setPaginateLoading(true, requestType))
        dispatch(setError(false, null, requestType))
        try {
            const response = await RequestsService.fetchResolvedRequests(pageNo, pageSize)
            if (paginated) {
                dispatch(setPaginatedRequests(response, requestType))
            } else if (!withLoading) {
                dispatch(setBgRequests(response, requestType))
            } else {
                dispatch(setRequests(response, requestType))
            }
        } catch (e) {
            console.log(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setError(true, errorCode, requestType))
        }
        if (withLoading) dispatch(setFetching(false, requestType))
        if (paginated) dispatch(setPaginateLoading(false, requestType))
    }
}

export function fetchNegativeRequests(
    withLoading = true,
    requestType: keyof requestTypeReference = 'default',
    pageNo: number = 1,
    pageSize: number = 100,
    paginated: boolean = false,
): ThunkAction<Promise<void>, {}, {}, RequestsAction> {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        if (withLoading) dispatch(setFetching(true, requestType))
        if (paginated) dispatch(setPaginateLoading(true, requestType))
        dispatch(setError(false, null, requestType))
        try {
            const response = await RequestsService.fetchNegativeRequests(pageNo, pageSize)
            if (paginated) {
                dispatch(setPaginatedRequests(response, requestType))
            } else if (!withLoading) {
                dispatch(setBgRequests(response, requestType))
            } else {
                dispatch(setRequests(response, requestType))
            }
        } catch (e) {
            console.log(e)
            const errorCode = e.code || 'UnknownErrorCode'
            dispatch(setError(true, errorCode, requestType))
        }
        if (withLoading) dispatch(setFetching(false, requestType))
        if (paginated) dispatch(setPaginateLoading(false, requestType))
    }
}
