import React from 'react'
import './style.less'
import { UserProfile } from '../../../../types'
import { RouteComponentProps, withRouter } from 'react-router'
import TrainingList from '../../../../components/TrainingList'

interface State {}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

class ListUniversityTrainings extends React.Component<Props, State> {
    render() {
        return (
            <div className="container">
                <TrainingList />
            </div>
        )
    }
}

export default withRouter(ListUniversityTrainings)
