import React from 'react'
import { Notification, User, UserProfile } from '../../../types'

import './style.less'
import { fetchAllNotifications, sendNotification } from '../../../services/Notifications'
import { fetchActiveUsers } from '../../../services/Users'
import { Dimmer, Loader, Comment, Header } from 'semantic-ui-react'
import NotificationItem from './NotificationItem'
import NotificationsForm from './Form'
import { AppState } from '../../../reducers'
import { connect } from 'react-redux'

interface State {
    notifications: Notification[]
    users: User[]
    isFetching: boolean
    error: string | null
    isSaving: boolean
}

interface Props {
    profile: UserProfile | null
}

class Notifications extends React.Component<Props, State> {
    state: State = {
        notifications: [],
        users: [],
        isFetching: false,
        error: null,
        isSaving: false,
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const [_, users] = await Promise.all([this.refreshNotifications(), fetchActiveUsers()])
            this.setState({ isFetching: false, users })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    async refreshNotifications() {
        const notifications = await fetchAllNotifications()
        this.setState({ notifications })
    }

    async handleSend(message: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await sendNotification(message)
            this.setState({ isSaving: false, error: null })
            this.refreshNotifications()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    findNotificationUser(notification: Notification): User | UserProfile | null {
        const res = this.state.users.find(u => u._id === notification.AddedBy) || null
        if (!res && this.props.profile) {
            return this.props.profile._id === notification.AddedBy ? this.props.profile : null
        }
        return res
    }

    render() {
        const notificationItems = this.state.notifications.map(n => {
            const notificationUser = this.findNotificationUser(n)
            const userName = notificationUser ? `${notificationUser.FirstName} ${notificationUser.LastName}` : null
            const imageUrl = notificationUser ? notificationUser.Icon : null

            return <NotificationItem key={n._id} userName={userName} imageUrl={imageUrl} notification={n} />
        })
        return (
            <div className="Notifications">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <NotificationsForm onSubmit={this.handleSend.bind(this)} isSaving={this.state.isSaving} />
                <Comment.Group>
                    <Header as="h3" dividing>
                        Previously Sent
                    </Header>
                    {this.state.notifications.length && this.state.users.length && notificationItems}
                </Comment.Group>
            </div>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
}

function mapDispatchToProps() {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Notifications)
