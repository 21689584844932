import React from 'react'
import FacilitiesForm, { FacilityFormValues } from '../FacilitiesForm'
import { createFacility } from '../../../../services/Facilities'
import { withRouter, RouteComponentProps } from 'react-router'

interface State {
    isSaving: boolean
    error: string | null
}

class FacilitiesCreate extends React.Component<RouteComponentProps, State> {
    state = {
        isSaving: false,
        error: null,
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: FacilityFormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            await createFacility(data)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    render() {
        return (
            <FacilitiesForm
                title="Create Facility"
                cancel={this.goBack.bind(this)}
                facility={null}
                onSave={this.handleSave.bind(this)}
                {...this.state}
            />
        )
    }
}

export default withRouter(FacilitiesCreate)
