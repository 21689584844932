import React from 'react'
import { UserProfile, UniversityTraining } from '../../../../types'
import { RouteComponentProps, withRouter } from 'react-router'
import { newStandardTraining } from '../../../../services/StandardUniversityTrainings'
import { Formik, FormikActions, Field as FormikField } from 'formik'
import { Form, Button, Message } from 'semantic-ui-react'
import * as Yup from 'yup'
import { GenericTextField, GenericImageField, GenericDropdown } from '../../../../components/GenericFormFields'
interface State {
    hasSaveError: boolean
    error: any
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

export interface FormValues {
    image: string
    topic: string
    lesson: string
    active: boolean
    url: string
}

const ValidationSchema = Yup.object().shape({
    image: Yup.string(),
    topic: Yup.string().required('Topic is required'),
    lesson: Yup.string().required('Lesson is required'),
    active: Yup.boolean(),
    url: Yup.string(),
})

class CreateStandardUniversityTrainings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            hasSaveError: false,
            error: null,
        }
    }

    onSubmit = async (values) => {
        const { topic, lesson, image, active, type, url } = values
        try {
            const training: UniversityTraining = {
                topic,
                lesson,
                image,
                active,
                url,
                type,
                AddedBy: this.props.profile ? this.props.profile._id : '',
            }
            await newStandardTraining(training)
            this.props.history.push('/admin/university/standard-trainings/list')
        } catch (e) {
            this.setState({
                error: e,
            })
        }
    }

    getInitialValues = () => ({
        image: '',
        topic: '',
        lesson: '',
        active: true,
        type: 'standard',
        url: '',
    })

    render() {
        const placeholderUrl = `${process.env.PUBLIC_URL}/university_training_placeholder.png`
        return (
            <div className="UsersForm">
                <Formik
                    initialValues={this.getInitialValues()}
                    onSubmit={async (values: FormValues, actions: FormikActions<FormValues>) => {
                        await this.onSubmit(values)
                        actions.setSubmitting(false)
                    }}
                    validationSchema={ValidationSchema}
                    render={({
                        values,
                        errors,
                        status,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <Form
                            onSubmit={handleSubmit}
                            loading={isSubmitting}
                            error={Object.keys(errors).length > 0 || this.state.hasSaveError}
                        >
                            <FormikField name="image" component={GenericImageField} placeholder={placeholderUrl} />
                            <FormikField required name="topic" component={GenericTextField} placeholder="Topic" />
                            <FormikField required name="lesson" component={GenericTextField} placeholder="Lesson" />
                            <FormikField
                                name="active"
                                component={GenericDropdown}
                                placeholder="Status"
                                options={[
                                    { key: 'True', text: 'Active', value: true },
                                    { key: 'False', text: 'Inactive', value: false },
                                ]}
                            ></FormikField>

                            <FormikField name="url" component={GenericTextField} placeholder="URL" />

                            {this.state.hasSaveError && (
                                <Message error>
                                    <Message.Header>Error saving User</Message.Header>
                                    <p>{this.state.error}</p>
                                </Message>
                            )}
                            <Button type="submit" disabled={isSubmitting} primary>
                                Submit
                            </Button>
                            <Button
                                basic
                                onClick={() => this.props.history.push('/admin/university/standard-trainings/list')}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                />
            </div>
        )
    }
}

export default withRouter(CreateStandardUniversityTrainings)
