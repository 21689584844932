import React, { useState, useEffect } from 'react'
import { fetchReportsSummary } from '../../services/Reports'
import DatePicker from 'react-datepicker'
import RadialDropdown from '../RadialDropdown'
import { Input } from 'semantic-ui-react'
import './style.less'
import { fetchRegistrants } from '../../services/Registrants'
import 'react-datepicker/dist/react-datepicker.css'

import { Registrant, BasicReportSummaryFilters } from '../../types'

interface Props {
    filters: BasicReportSummaryFilters
    handleFilterChange: (newFilters: BasicReportSummaryFilters) => void
    history: any
}

const ReportSummaryBasics: React.SFC<Props> = ({ filters, handleFilterChange, history }) => {
    const [error, setError] = useState()

    const [residentsOptionsList, setResidentsOptionsList] = useState<Registrant[]>([])

    useEffect(() => {
        const populateFilters = () => {
            Promise.all([fetchRegistrants()]).then(([residentsList]) => {
                setResidentsOptionsList(residentsList)
            })
        }

        populateFilters()
    }, [])

    const redirectToCreate = () => {
        history.push('/admin/requests/create')
    }

    return (
        <div className="Reports">
            <div className="report-panel-row">
                <div>
                    <div>
                        <div className="text-top-datepicker">Start Date</div>

                        <DatePicker
                            selected={filters.fromDate}
                            onChange={date => {
                                handleFilterChange({ ...filters, fromDate: date })
                            }}
                            selectsStart
                            startDate={filters.fromDate}
                            endDate={filters.toDate}
                            maxDate={new Date()}
                            customInput={<Input />}
                        />
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <div className="text-top-datepicker">End Date</div>
                        <DatePicker
                            selected={filters.toDate}
                            onChange={date => {
                                handleFilterChange({ ...filters, toDate: date })
                            }}
                            selectsEnd
                            endDate={filters.toDate}
                            minDate={filters.fromDate}
                            maxDate={new Date()}
                            customInput={<Input />}
                        />
                    </div>
                </div>
            </div>
            <div className="justify-center flex flex-1">
                <div className="report-panel-row radial">
                    <RadialDropdown
                        text="Residents"
                        color="#53A4CC"
                        selectedValue={filters.resident}
                        options={[
                            { value: 'all', label: 'All' },
                            ...residentsOptionsList.map(item => {
                                console.log(item._id, `${item.FirstName} ${item.LastName}`)
                                return { value: item._id, label: `${item.FirstName} ${item.LastName}` }
                            }),
                        ]}
                        onClick={value => {
                            handleFilterChange({ ...filters, resident: value })
                        }}
                    />
                    <RadialDropdown
                        text="Request Type"
                        color="#53A4CC"
                        selectedValue={filters.type}
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'Menu', label: 'Menu' },
                            { value: 'DailyActivities', label: 'Daily Activities' },
                        ]}
                        onClick={value => {
                            if (value === 'Menu') {
                                handleFilterChange({ ...filters, type: value })
                            } else {
                                handleFilterChange({ ...filters, food: 'all', type: value })
                            }
                        }}
                    />
                    {filters.type === 'Menu' ? (
                        <RadialDropdown
                            text="Food"
                            color="#53A4CC"
                            selectedValue={filters.food}
                            options={[
                                { value: 'all', label: 'All' },
                                { value: 'breakfast', label: 'Breakfast' },
                                { value: 'lunch', label: 'Lunch' },
                                { value: 'snacks', label: 'Snacks' },
                                { value: 'dinner', label: 'Dinner' },
                            ]}
                            onClick={value => {
                                handleFilterChange({ ...filters, food: value })
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ReportSummaryBasics
