import React from 'react'
import { Form, Message, Checkbox } from 'semantic-ui-react'
import { FieldProps } from 'formik'

interface CustomProps {
    required?: boolean
    labelFields: string[]
    disabled?: boolean
    placeholder: string
}

const GenericCheckbox: React.FC<any> = (props: FieldProps<any> & CustomProps) => {
    const errMsg = (
        <Message error>
            <p>{props.form.errors[props.field.name]}</p>
        </Message>
    )

    const hasError = props.form.errors[props.field.name] && props.form.touched[props.field.name] ? true : false

    return (
        <Form.Field required={props.required || false} error={hasError}>
            <label>{props.placeholder}</label>

            <Checkbox
                {...props}
                toggle
                checked={props.field.value}
                onChange={(event, data) => {
                    console.log('data: ', data)
                    props.form.setFieldValue(props.field.name, data.checked)
                }}
                disabled={props.disabled || false}
            />

            {hasError && errMsg}
        </Form.Field>
    )
}

export default GenericCheckbox
