import React, { useEffect } from 'react'
import { Dimmer, Loader, Card } from 'semantic-ui-react'
import LoadMore from '../LoadMore'
import BasicRequestItem from '../BasicRequestItem'
import { withRouter } from 'react-router'

const RequestList = props => {
    useEffect(() => {
        console.log('isFetching, ', props.isFetching)
    }, [props.isFetching])

    const items =
        props.requests &&
        props.requests.map(req => (
            <BasicRequestItem
                key={req._id}
                categoryImageUrl={req.Department_Icon}
                firstName={req.Registrant_FirstName}
                lastName={req.Registrant_LastName}
                type={req.Type}
                userAvatarUrl={req.Registrant_Image}
                roomNo={req.Unit_Name}
                status={req.Status}
                createdAt={req.RequestedTime}
                info={req.Type === 'DailyActivities' ? req.Details.day : req.Details.food}
            />
        ))
    return (
        <>
            <Dimmer active={props.isFetching} inverted>
                <Loader active={props.isFetching} />
            </Dimmer>
            {items && items.length ? (
                <div className="Departments">
                    <Card.Group>{items}</Card.Group>
                    <LoadMore
                        isLoading={props.isPaginateLoading}
                        hasMore={props.hasMorePage}
                        next={() => props.nextPage()}
                    />
                </div>
            ) : (
                <div className="no-requests">No Requests</div>
            )}
        </>
    )
}

export default withRouter(RequestList)
