import firebase from 'firebase'
import API from './API'
import { toast } from 'react-toastify'

export function initializeFirebase(firebaseConfig: Object) {
    firebase.initializeApp(firebaseConfig)
    console.log('Firebase initialized')
}

export async function saveTokenToBackend(token: string): Promise<void> {
    try {
        await API.lambdaPost('/users/regtoken', {
            RegToken: token,
        })
    } catch (e) {
        console.error(e)
    }
}

export async function askForPermissioToReceiveNotifications() {
    try {
        const messaging = firebase.messaging()
        await messaging.requestPermission()
        const token = await messaging.getToken()
        console.log('token do send notifications to:', token)
        if (token) await saveTokenToBackend(token)
        else throw new Error('Could not get Push Notifications token')

        return token
    } catch (error) {
        console.error(error)
    }
}

const notify = (link: string, body: string, history: any) => {
    if (!toast.isActive(link)) {
        window.navigator.vibrate([300, 100, 300, 100, 300])
        toast(body, {
            toastId: link,
            onClick: () => {
                console.log('clicked')
                const path = link.split('https://speak2.live')[1]
                history.push(path)
            },
            delay: 8000,
            onOpen: () => {
                const audio = new Audio(`${process.env.PUBLIC_URL}/Bubble-pop-sound-effect.mp3`)
                audio.play()
            },
        })
    }
}

export function registerOnNotificationHandler(handler: () => any, history: any) {
    try {
        const messaging = firebase.messaging()
        return messaging.onMessage(payload => {
            const { notification } = payload
            handler()
            notify(notification.click_action, notification.body, history)
            console.log(notification)
            console.log('Notification' in window && Notification.permission === 'granted')
            // Show notification when app is in foreground

            try {
                if ('Notification' in window && Notification.permission === 'granted') {
                    // Web only
                    const n = new Notification(notification.title, { ...notification })
                    n.onclick = (e: Event) => {
                        e.preventDefault() // prevent the browser from focusing the Notification's tab
                        window.open(notification.click_action, '_blank')
                    }
                }
            } catch (e) {}
        })
    } catch (e) {
        console.log(e)
        return null
    }
}
