import React from 'react'
import FacilitiesForm, { FacilityFormValues } from '../FacilitiesForm'
import { fetchOneFacility, updateFacility, deleteFacility } from '../../../../services/Facilities'
import { withRouter, RouteComponentProps } from 'react-router'
import { Facility } from '../../../../types'
import { Dimmer, Loader } from 'semantic-ui-react'

interface State {
    isFetching: boolean
    facility: Facility | null
    isSaving: boolean
    error: string | null
}

interface RouteInfo {
    id: string
}

class FacilitiesCreate extends React.Component<RouteComponentProps<RouteInfo>, State> {
    id: string
    constructor(props: RouteComponentProps<RouteInfo>) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            facility: null,
            isFetching: false,
        }

        this.id = props.match.params.id
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const facility = await fetchOneFacility(this.id)
            this.setState({ isFetching: false, facility })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: FacilityFormValues) {
        this.setState({ isSaving: true, error: null })
        const postData = {
            ...data,
            _id: this.id,
        }
        try {
            await updateFacility(postData)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    async handleDelete(facilityId: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteFacility(facilityId)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    render() {
        return (
            <div className="EditFacility">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.facility && (
                    <FacilitiesForm
                        title="Edit Facility"
                        facility={this.state.facility}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(FacilitiesCreate)
