import API from './API'
import { Contact, Identifiable } from '../types'

export async function fetchContacts(): Promise<Contact[]> {
    const res = await API.lambdaGet('/contacts/list')
    const contacts = res.Result as Contact[]
    return contacts.filter(u => u.IsActive === 1)
}

export async function fetchOneContact(id: string): Promise<Contact> {
    const res = await API.lambdaGet(`/contacts/get?_id=${id}`)
    return res as Contact
}

export async function createContact(department: Partial<Contact>): Promise<Contact> {
    const id = await API.lambdaPost('/contacts/add', department)
    const created = await fetchOneContact(id)
    return created
}

export async function updateContact(contact: Partial<Contact> & Identifiable): Promise<Contact> {
    if (!contact._id) throw new Error('Updated Contact object must contain _id field')
    await API.lambdaPost('/contacts/update', contact)
    const updated = await fetchOneContact(contact._id)
    return updated
}

export async function deleteContact(contactId: String): Promise<any> {
    await API.lambdaPost('/contacts/update', {
        _id: contactId,
        IsActive: 0,
    })
}
