import React from 'react'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import { Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../actions/auth'
import './style.css'
import { AppState } from '../../reducers'

import { Button, Input, Icon } from 'semantic-ui-react'
import { CurrentUser } from '../../types'

interface Props extends RouteComponentProps {
    onPasswordChange: (user: CurrentUser, newPass: string) => void
    isAuthenticated: boolean
    hasError: boolean
    errorCode: string | null
    isFetching: boolean
    cacheLoginFailed: boolean
    currentUser: CurrentUser | null
}

interface State {
    password: string
    confirm: string
}

class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            password: '',
            confirm: '',
        }
    }

    renderErrorMessage() {
        const { hasError, errorCode } = this.props
        let message = ''
        if (errorCode === 'InvalidPasswordException') message = 'New password is invalid.'
        else message = 'Error during password change process.'

        return hasError ? <div style={{ color: 'red' }}>{message}</div> : null
    }

    handlePasswordInputChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({
            password: event.currentTarget.value,
        })
    }

    handleConfirmInputChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({
            confirm: event.currentTarget.value,
        })
    }

    handlePasswordChange() {
        if (!this.props.currentUser) {
            console.log('No current user')
            return
        }
        this.props.onPasswordChange(this.props.currentUser, this.state.password)
    }

    get validPassword(): boolean {
        if (this.state.password === '') return true
        return this.state.password.length > 6
    }

    get validConfirm(): boolean {
        if (this.state.confirm === '') return true
        return this.state.confirm === this.state.password
    }

    get validForm(): boolean {
        return (
            this.state.password.length > 6 && this.state.confirm.length > 6 && this.validConfirm && this.validPassword
        )
    }

    render() {
        const redirectToHome = <Redirect to="/" />

        return (
            <div className="NewPassword">
                {this.props.isAuthenticated && redirectToHome}
                <div className="formBox">
                    {/* <h1>Login Page</h1> */}
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="" className="logo" />
                    <h4>A password change is required before you continue. Please input a new password below.</h4>
                    <Input
                        fluid
                        type="password"
                        className="input"
                        iconPosition="left"
                        placeholder="New Password"
                        error={!this.validPassword}
                        onChange={this.handlePasswordInputChange.bind(this)}
                    >
                        <Icon name="lock" />
                        <input />
                    </Input>
                    <Input
                        fluid
                        className="input"
                        iconPosition="left"
                        placeholder="Confirm Password"
                        type="password"
                        error={!this.validConfirm}
                        onChange={this.handleConfirmInputChange.bind(this)}
                    >
                        <Icon name="lock" />
                        <input />
                    </Input>
                    <Button
                        fluid
                        primary
                        size="big"
                        onClick={this.handlePasswordChange.bind(this)}
                        loading={this.props.isFetching}
                        disabled={this.props.isFetching || !this.validForm}
                    >
                        CHANGE PASSWORD
                    </Button>
                    <br />
                    {this.renderErrorMessage()}
                </div>
            </div>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        isAuthenticated: authReducer.isAuthenticated,
        hasError: authReducer.passwordChangeError,
        errorCode: authReducer.errorCode,
        isFetching: authReducer.fetching,
        cacheLoginFailed: authReducer.cacheLoginFailed,
        currentUser: authReducer.currentUser,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.AuthAction>) {
    return {
        onPasswordChange: (user: CurrentUser, newPass: string) => dispatch(actions.changePassword(user, newPass)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Login))
