import React from 'react'

import './style.less'
import { RouteComponentProps, withRouter } from 'react-router'
import { Dimmer, Loader, Icon, Tab, TabProps, Pagination, PaginationProps } from 'semantic-ui-react'
import { UserProfile } from '../../../types'
import 'flatpickr/dist/themes/airbnb.css'
import 'rc-time-picker/assets/index.css'
import { newMenu, fetchMenuItems, deleteMenu, updateMenu } from '../../../services/Menus'
import MenuFormEditor from '../../../components/MenuFormEditor'
import ListItemsPane from '../../../components/ListItemsPane'

import { MenuType } from '../../../types/menu'
import { capitalizeFirstLetter } from './utils'

interface State {
    isFetching: boolean
    error: string | null
    isSaving: boolean
    menus: MenuType[]
    menuLength: number
    openCreateForm: boolean
    menuFilter: string | number
    activeFoodCategoryTab: string
    activeHistoryTab: string
    activePageNumber: any
    copyMenu: MenuType | null
    activeHistoryTabIndex: number
    activeFoodCategoryTabIndex: number
    warning: string
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

class Menu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isFetching: false,
            isSaving: false,
            error: null,
            menus: [],
            menuLength: 0,
            openCreateForm: false,
            menuFilter: '',
            activeHistoryTab: 'active',
            activeFoodCategoryTab: 'breakfast',
            activePageNumber: 1,
            copyMenu: null,
            activeHistoryTabIndex: 0,
            activeFoodCategoryTabIndex: 0,
            warning: '',
        }
    }

    openCloseCreateMenuForm(): void {
        // If the form was opened by copy commant, reset the copy value
        if (this.state.copyMenu) {
            this.setState({
                openCreateForm: !this.state.openCreateForm,
                copyMenu: null,
                warning: '',
            })
        } else {
            this.setState({
                openCreateForm: !this.state.openCreateForm,
                warning: '',
            })
        }
    }

    editMenu(id: string): void {
        const menus = this.state.menus.map((menu: MenuType) => {
            if (menu._id === id) menu.edit = true
            return menu
        })
        this.setState({
            ...this.state,
            menus,
            warning: '',
        })
    }

    cancelEditMenu(id?: string): void {
        if (id) {
            const menus = this.state.menus.map((menu: MenuType) => {
                if (menu._id === id) delete menu.edit
                return menu
            })
            this.setState({
                ...this.state,
                menus,
                copyMenu: null,
                warning: '',
            })
        } else {
            this.setState({
                openCreateForm: false,
                copyMenu: null,
                warning: '',
            })
        }
    }

    copyMenu(id: string): void {
        let menu = this.state.menus.find((menu: MenuType) => menu._id === id)
        document.getElementsByClassName('page-header')[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
        if (menu) {
            this.setState({
                openCreateForm: true,
                copyMenu: menu,
                warning: '',
            })
        }
    }

    async getMenus(currentPage, pageLimit, menuType, filters = {}): Promise<{ items: MenuType[]; totalItems: number }> {
        this.setState({ isFetching: true, error: null })
        const menus = await fetchMenuItems(currentPage, pageLimit, menuType, filters)
        this.setState({ isFetching: false })

        return {
            items: menus.Result,
            totalItems: menus.TotRecords,
        }
    }

    async handleSubmit(menu: MenuType): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
        })

        menu.alexaReadableString = menu.alexaReadableCollection
            .map((element) => {
                return element.insert
            })
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            }, '')

        try {
            const insertNewMenu = await newMenu({
                text: menu.text,
                alexaReadableCollection: menu.alexaReadableCollection,
                alexaReadableString: menu.alexaReadableString,
                timestamp: menu.timestamp,
                category: this.state.activeFoodCategoryTab,
                AddedBy: this.props.profile ? this.props.profile._id : '',
                dateString: menu.dateString,
            })

            if (!insertNewMenu) {
                this.setState({
                    warning: 'Menu category already exists for the selected day',
                })
            }

            await this.populateStateWithMenus('save')
        } catch (error) {
            this.setState({
                isSaving: false,
                error: error.message || 'Could not save Menu.',
            })
        }
    }

    async handleUpdate(menu): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
        })

        menu.alexaReadableString = menu.alexaReadableCollection
            .map((element) => {
                return element.insert
            })
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            }, '')

        try {
            console.log('menu', menu)
            const updateMenuResponse = await updateMenu({
                _id: menu._id,
                text: menu.text,
                alexaReadableCollection: menu.alexaReadableCollection,
                alexaReadableString: menu.alexaReadableString,
                timestamp: menu.timestamp,
                category: menu.category,
                UpdatedBy: this.props.profile ? this.props.profile._id : '',
                dateString: menu.dateString,
            })

            if (!updateMenuResponse) {
                this.setState({
                    warning: 'Menu category already exists for the selected day',
                })
            }

            await this.populateStateWithMenus('update')
        } catch (error) {
            this.setState({
                isSaving: false,
                isFetching: false,
                error: error.message || 'Could not update Menu.',
            })
        }
    }

    async deleteMenu(id): Promise<void> {
        this.setState({
            ...this.state,
            isSaving: true,
            warning: '',
        })
        try {
            await deleteMenu(id)
            await this.populateStateWithMenus('delete')
        } catch (error) {
            this.setState({
                ...this.state,
                isSaving: false,
                error: error,
            })
        }
    }

    async setMenuDateFilter(time) {
        const date = new Date(Date.parse(time)).toLocaleDateString('en-US')
        const filteredMenus = await this.getMenus(1, this.paginationPerPageNumber, null, {
            timestamp: { $gte: Date.parse(time), $lt: Date.parse(time) + 86400000 },
            category: this.state.activeFoodCategoryTab,
        })

        this.setState({
            menuFilter: date,
            menus: filteredMenus.items,
            menuLength: filteredMenus.totalItems,
            activePageNumber: 1,
            warning: '',
        })
    }

    async clearDateFilter() {
        const filteredMenus = await this.getMenus(1, this.paginationPerPageNumber, this.state.activeHistoryTab, {
            category: this.state.activeFoodCategoryTab,
        })
        this.setState({
            menuFilter: '',
            menus: filteredMenus.items,
            menuLength: filteredMenus.totalItems,
            activePageNumber: 1,
        })
    }

    async handleCategoriesTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        const foodCategories = ['breakfast', 'lunch', 'dinner', 'snack', 'alternative']
        this.cancelEditMenu()
        if (
            data.activeIndex === 0 ||
            data.activeIndex === 1 ||
            data.activeIndex === 2 ||
            data.activeIndex === 3 ||
            data.activeIndex === 4
        ) {
            const filteredMenus = await this.getMenus(1, this.paginationPerPageNumber, 'active', {
                category: foodCategories[data.activeIndex],
            })
            this.setState({
                activeFoodCategoryTab: foodCategories[data.activeIndex],
                activeHistoryTab: 'active',
                activeHistoryTabIndex: 0,
                menuFilter: '',
                menus: filteredMenus.items,
                menuLength: filteredMenus.totalItems,
                activePageNumber: 1,
                warning: '',
            })
        }
    }

    async handleHistoryTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        this.cancelEditMenu()
        if (data.activeIndex === 0 || data.activeIndex === 1) {
            const filteredMenus = await this.getMenus(
                1,
                this.paginationPerPageNumber,
                data.activeIndex === 0 ? 'active' : 'history',
                {
                    category: this.state.activeFoodCategoryTab,
                },
            )
            this.setState({
                activeHistoryTab: data.activeIndex === 0 ? 'active' : 'history',
                menuFilter: '',
                menus: filteredMenus.items,
                menuLength: filteredMenus.totalItems,
                activePageNumber: 1,
                activeHistoryTabIndex: data.activeIndex,
            })
        }
    }

    async changePage(e, data: PaginationProps) {
        const response = await this.getMenus(
            data.activePage,
            this.paginationPerPageNumber,
            this.state.activeHistoryTab,
            {
                category: this.state.activeFoodCategoryTab,
            },
        )

        this.setState({
            activePageNumber: data.activePage,
            menus: response.items,
            menuLength: response.totalItems,
        })
    }

    getPaginatedMenu(): MenuType[] {
        return this.state.menus
    }

    async populateStateWithMenus(readMenuIntent): Promise<void> {
        try {
            let activeFoodCategoryTab = this.state.activeFoodCategoryTab
            if (readMenuIntent === 'mount') activeFoodCategoryTab = 'breakfast'

            if (
                (readMenuIntent === 'update' || readMenuIntent === 'delete') &&
                this.state.activeHistoryTab === 'history'
            ) {
                const filteredMenus = await this.getMenus(1, this.paginationPerPageNumber, 'history', {
                    category: this.state.activeFoodCategoryTab,
                })
                this.setState({
                    ...this.state,
                    isFetching: false,
                    isSaving: false,
                    openCreateForm: false,
                    activeFoodCategoryTab: activeFoodCategoryTab,
                    menus: filteredMenus.items,
                    menuLength: filteredMenus.totalItems,
                    activePageNumber: 1,
                    activeHistoryTabIndex: 1,
                    activeFoodCategoryTabIndex: 0,
                })
            } else {
                const filteredMenus = await this.getMenus(1, this.paginationPerPageNumber, 'active', {
                    category: this.state.activeFoodCategoryTab,
                })
                this.setState({
                    ...this.state,
                    isFetching: false,
                    isSaving: false,
                    openCreateForm: false,
                    activeFoodCategoryTab: activeFoodCategoryTab,
                    menus: filteredMenus.items,
                    menuLength: filteredMenus.totalItems,
                    activePageNumber: 1,
                    activeHistoryTabIndex: 0,
                    activeFoodCategoryTabIndex: 0,
                })
            }
        } catch (error) {
            this.setState({
                ...this.state,
                isFetching: false,
                isSaving: false,
                error: error.message,
            })
        }
    }

    async componentDidMount(): Promise<void> {
        await this.populateStateWithMenus('mount')
    }

    paginationPerPageNumber = 5

    panes2 = [
        {
            menuItem: 'Active',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.menuFilter}
                    setListItemDateFilter={this.setMenuDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.menus}
                    getPaginatedListItem={this.getPaginatedMenu.bind(this)}
                    deleteListItem={this.deleteMenu.bind(this)}
                    editListItem={this.editMenu.bind(this)}
                    copyListItem={this.copyMenu.bind(this)}
                    cancelEditListItem={this.cancelEditMenu.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage="Menu"
                    aciveFoodTab={this.state.activeFoodCategoryTab}
                />
            ),
        },
        {
            menuItem: 'History',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.menuFilter}
                    setListItemDateFilter={this.setMenuDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.menus}
                    getPaginatedListItem={this.getPaginatedMenu.bind(this)}
                    deleteListItem={this.deleteMenu.bind(this)}
                    editListItem={this.editMenu.bind(this)}
                    copyListItem={this.copyMenu.bind(this)}
                    cancelEditListItem={this.cancelEditMenu.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage="Menu"
                    aciveFoodTab={this.state.activeFoodCategoryTab}
                />
            ),
        },
    ]

    panes = [
        {
            menuItem: 'Breakfast',
            render: () => {
                if (this.state.error) return this.state.error
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
        {
            menuItem: 'Lunch',
            render: () => {
                if (this.state.error) return this.state.error
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
        {
            menuItem: 'Dinner',
            render: () => {
                if (this.state.error) return this.state.error
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
        {
            menuItem: 'Snack',
            render: () => {
                if (this.state.error) {
                    return this.state.error
                }
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
        {
            menuItem: 'Alternative',
            render: () => {
                if (this.state.error) {
                    return this.state.error
                }
                return (
                    <Tab
                        activeIndex={this.state.activeHistoryTabIndex}
                        className="active-history-tab"
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes2}
                        onTabChange={this.handleHistoryTabChange.bind(this)}
                    />
                )
            },
        },
    ]

    render() {
        return (
            <div className="DailyMenus">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>

                <button className="create-menu" onClick={this.openCloseCreateMenuForm.bind(this)}>
                    <Icon name="add" className="button-icon" />
                    <div className="text">{`Create ${capitalizeFirstLetter(this.state.activeFoodCategoryTab)}`}</div>
                </button>

                {this.state.openCreateForm ? (
                    <MenuFormEditor
                        isFetching={this.state.isFetching}
                        isSaving={this.state.isSaving}
                        handleSubmit={this.handleSubmit.bind(this)}
                        handleUpdate={this.handleUpdate.bind(this)}
                        cancelEditMenu={this.cancelEditMenu.bind(this)}
                        copyMenu={this.state.copyMenu}
                        aciveFoodTab={this.state.activeFoodCategoryTab}
                    />
                ) : null}

                {this.state.warning ? <h3 className="warning-existing-menu">{this.state.warning}</h3> : null}

                <Tab
                    className="menu-tab"
                    menu={{ secondary: true }}
                    panes={this.panes}
                    onTabChange={this.handleCategoriesTabChange.bind(this)}
                />

                {this.state.menuLength > this.paginationPerPageNumber ? (
                    <div className="pagination-holder">
                        <Pagination
                            activePage={this.state.activePageNumber}
                            totalPages={Math.ceil(this.state.menuLength / this.paginationPerPageNumber)}
                            onPageChange={this.changePage.bind(this)}
                            siblingRange={1}
                        />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default withRouter(Menu)
