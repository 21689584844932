import API from './API'
import { isJustStaff, isFacilityAdmin, isJustPowerUser } from './Permissions'
import { User, UserRole, UserProfile, Department, Identifiable } from '../types'

function formatPhone(phone: string) {
    const p = phone.replace(/\(|\)|\s|-/g, '')
    return `+${p}`
}

export async function fetchActiveUsers(): Promise<User[]> {
    const res = await API.lambdaGet('/users/list')
    const users = res.Result as User[]
    return users.filter(u => u.IsActive === 1)
}

export async function fetchOneUser(id: string): Promise<User> {
    const res = await API.lambdaGet(`/users/get?_id=${id}`)
    return res as User
}

export async function fetchStaffUsers(): Promise<User[]> {
    const res = await API.lambdaGet('/users/list')
    const users = res.Result as User[]
    return users.filter(user => isJustStaff(user) && user.IsActive === 1)
}

export async function fetchStaffRoleUsers(): Promise<User[]> {
    const users = await API.lambdaGet('/users/list');

    const staffUsers = users.Result.filter(({ Roles }: { Roles: UserRole[] }) => {
        return Roles.filter((Role: UserRole) => {
            return Role.Name = 'Staff';
        }).length ? true : false;
    });
    
    return staffUsers;
}

export async function fetchPowerUsers(): Promise<User[]> {
    const res = await API.lambdaGet('/users/list')
    const users = res.Result as User[]
    return users.filter(user => isJustPowerUser(user) && user.IsActive === 1)
}

export async function fetchAdminUsers(): Promise<User[]> {
    const res = await API.lambdaGet('/users/list')
    const users = res.Result as User[]
    return users.filter(user => isFacilityAdmin(user) && user.IsActive === 1)
}

export async function fetchUserRoles(): Promise<UserRole[]> {
    const res = await API.lambdaGet('/roles/list')
    const roles = res.Result as UserRole[]
    return roles
}

export async function fetchAssignableUserRoles(): Promise<UserRole[]> {
    const roles = await fetchUserRoles()
    return roles.filter(role => role.Name !== 'System Admin' && role.IsActive === 1)
}

export async function addUserToRole(userId: string, roleId: string): Promise<any> {
    const res = await API.lambdaPost('/users/roles/add', {
        User: userId,
        Role: roleId,
    })
    return res
}

export async function removeUserFromRole(userId: string, roleId: string): Promise<any> {
    const res = await API.lambdaPost('/users/roles/remove', {
        User: userId,
        Role: roleId,
    })
    return res
}

export async function addUserToDepartment(userId: string, departmentId: string): Promise<any> {
    const res = await API.lambdaPost('/users/departments/add', {
        User: userId,
        Department: departmentId,
    })
    return res
}

export async function removeUserFromDepartment(userId: string, departmentId: string): Promise<any> {
    const res = await API.lambdaPost('/users/departments/remove', {
        User: userId,
        Department: departmentId,
    })
    return res
}

export async function createUserWithDepartments(
    userData: Partial<User>,
    departments: Department[],
    roles: UserRole[],
): Promise<any> {
    const dataClone = { ...userData }
    if (dataClone.Departments) delete dataClone.Departments
    if (dataClone.Roles) delete dataClone.Roles
    if (dataClone.Phone) dataClone.Phone = formatPhone(dataClone.Phone)
    if (dataClone.Cell) dataClone.Cell = formatPhone(dataClone.Cell)

    const createdId = await API.lambdaPost('/users/add', {
        ...dataClone,
        IsActive: 1,
    })

    const roleAddPromises = roles.map(role => addUserToRole(createdId, role._id))

    const departmentAddPromises = departments.map(d => addUserToDepartment(createdId, d._id))

    await Promise.all([...departmentAddPromises, ...roleAddPromises])

    const newUser = await fetchOneUser(createdId)
    return newUser
}

export async function updateUser(updated: Partial<User> & Identifiable, oldUser: User): Promise<any> {
    const existingDepIds = oldUser.Departments.map(d => d._id)
    const newDepIds = (updated.Departments && updated.Departments.map(d => d._id)) || []
    const depsToAdd = newDepIds.filter(d => !existingDepIds.includes(d))
    const depsToRemove = existingDepIds.filter(d => !newDepIds.includes(d))

    const existingRoleIds = oldUser.Roles.map(role => role._id)
    const newRoleIds = (updated.Roles && updated.Roles.map(r => r._id)) || []
    const rolesToAdd = newRoleIds.filter(r => !existingRoleIds.includes(r))
    const rolesToRemove = existingRoleIds.filter(r => !newRoleIds.includes(r))

    const dataClone = {...updated}
    if (dataClone.Phone) dataClone.Phone = formatPhone(dataClone.Phone)
    if (dataClone.Cell) dataClone.Cell = formatPhone(dataClone.Cell)

    await API.lambdaPost('/users/update', dataClone)

    const addDepsPromises = depsToAdd.map(d => addUserToDepartment(updated._id, d))
    const removeDepsPromises = depsToRemove.map(d => removeUserFromDepartment(updated._id, d))
    const addRolesPromises = rolesToAdd.map(r => addUserToRole(updated._id, r))
    const removeRolesPromises = rolesToRemove.map(r => removeUserFromRole(updated._id, r))

    await Promise.all([...addDepsPromises, ...removeDepsPromises, ...addRolesPromises, ...removeRolesPromises])
}

export async function fetchProfile(): Promise<UserProfile> {
    const res = await API.lambdaGet('/users/profile/get')
    return res as UserProfile
}

export async function updateProfile(
    newProfile: Pick<UserProfile, 'FirstName' | 'LastName' | 'Phone' | 'Cell' | 'Icon'>,
): Promise<UserProfile> {
    const dataClone = {...newProfile}
    if (dataClone.Phone) dataClone.Phone = formatPhone(dataClone.Phone)
    if (dataClone.Cell) dataClone.Cell = formatPhone(dataClone.Cell)
    await API.lambdaPost('/users/profile/update', dataClone)
    const updated = await fetchProfile()
    return updated
}

export async function deleteUser(userId: string) {
    await API.lambdaPost('/users/update', {
        _id: userId,
        IsActive: 0,
    })
}
