import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import './style.less'

interface Props {
    isLoading: boolean
    hasMore: boolean
    next: () => any
}

const LoadMore = (props: Props) => {
    return props.hasMore ? (
        <div className="admin-load-more">
            <Button primary loading={props.isLoading} onClick={props.next}>
                Load More
            </Button>
        </div>
    ) : (
        <div></div>
    )
}

LoadMore.propTypes = {
    next: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
}

export default LoadMore
