import React from 'react'
import { Card, Image, Icon, Label } from 'semantic-ui-react'
import { differenceInMinutes } from 'date-fns'

import './style.less'
import { RequestStatus } from '../../types';
import { format } from 'date-fns'

interface Props {
    categoryImageUrl?: string
    firstName: string
    lastName: string
    roomNo: string
    escalationTimestamp: number
    escalationEnabled: boolean
    userAvatarUrl?: string
    onClick: () => any
    detailed?: boolean
    status: RequestStatus
    createdAt: Date
    info?: string
    departmentName?: string
}

const getStatusLabel = (status: RequestStatus) => {
    switch (status) {
        case 'Open':
            return <Label ribbon color="orange">{status}</Label>
        case 'Closed':
            return <Label ribbon color="grey">{status}</Label>
        case 'Accepted':
            return <Label ribbon color="green">{status}</Label>
        
    }
}

const AdminRequestItem: React.FC<Props> = props => {
    const categoryImageUrl = props.categoryImageUrl || `${process.env.PUBLIC_URL}/request_type_placeholder.png`
    const userAvatarUrl = props.userAvatarUrl || `${process.env.PUBLIC_URL}/avatar_placeholder.png`
    const minutesLeft = differenceInMinutes(new Date(props.escalationTimestamp), new Date())
    // const isClosed = props.status === 'Closed'
    const isOpen = props.status === 'Open'

    const extraDetails = (
        <div className="extra-details-wrapper">
            <div className="detail-item">
                <Icon className="clock-outline" name="clock outline" />
                Created: {format(props.createdAt, 'MM/DD hh:mm A')}
            </div>
            <div className="detail-item">
                <Icon className="info-circle" name="info circle" />
                {props.info || ''}
            </div>
            <div className="detail-item">
                <Icon className="building" name="building" />
                {props.departmentName || ''}
            </div>

        </div>
        
    )

    return (
        <Card className="AdminRequestItem" onClick={props.onClick}>
            <Card.Content>
                <Image
                    floated="left"
                    size="mini"
                    circular
                    src={userAvatarUrl}
                />
                <Card.Header>{props.firstName + ' ' + props.lastName}</Card.Header>
                <Card.Meta>Room {props.roomNo}</Card.Meta>
                <Card.Description>
                    <div className="request-details">
                        <Icon className="hourglass-half" name="hourglass half" />
                        {isOpen ? (props.escalationEnabled ? (minutesLeft < 0 ? 'Escalated' : minutesLeft + ' minutes until escalation'): 'Escalation Disabled') : 'Accepted'}
                        <div className="extra-details">
                            {props.detailed && extraDetails}
                        </div>
                    </div>
                    <Image
                        floated="right"
                        circular
                        size="mini"
                        src={categoryImageUrl}
                    />
                    <div className="status-label-container">
                        {getStatusLabel(props.status)}
                    </div>
                </Card.Description>
            </Card.Content>
        </Card>
    )
}

export default AdminRequestItem
