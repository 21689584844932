import API from './API'

export async function fetchBroadcastMessages(page_no: number, page_size: number): Promise<any[]> {
    const res = await API.lambdaPost('/speak2family-broadcast/list', {
        QueryOptions: {
            page_no,
            page_size,
        },
    })
    console.log('ree', res)
    if (res.Result) return res.Result

    return []
}

export async function approveBroadcastMessage(id): Promise<any> {
    const res = await API.lambdaPost('/speak2family-broadcast/approve', {
        id,
    })
}

export async function rejectBroadcastMessage(id): Promise<any> {
    const res = await API.lambdaPost('/speak2family-broadcast/reject', {
        id,
    })
    // return res.success
}
