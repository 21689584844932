import React from 'react'
import './style.less'
import { Dimmer, Loader, Button } from 'semantic-ui-react'
import { Registrant, UserLike } from '../../../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import UsersList from '../../../../components/UsersList'
import LoadMore from '../../../../components/LoadMore'
import { fetchPaginatedRegistrants } from '../../../../services/Registrants'

interface State {
    registrants: Registrant[]
    hasError: boolean
    error: string | null
    isFetching: boolean
    isPaginating: boolean
    page: number
    count: number
    limit: number
    hasMorePages: boolean
}

class RegistrantsList extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            registrants: [],
            hasError: false,
            error: null,
            isFetching: false,
            isPaginating: false,
            page: 1,
            count: 0,
            limit: 50,
            hasMorePages: true,
        }
    }

    async loadMore() {
        try {
            if (this.state.page === 1) {
                this.setState({
                    isFetching: true,
                    hasError: false,
                    error: null,
                })
            } else {
                this.setState({
                    isPaginating: true,
                    hasError: false,
                    error: null,
                })
            }

            const registrants = await fetchPaginatedRegistrants(this.state.page, this.state.limit)
            const hasMorePages = !(registrants.length < this.state.limit)

            this.setState({
                isFetching: false,
                isPaginating: false,
                registrants: [...this.state.registrants, ...registrants],
                hasError: false,
                error: null,
                hasMorePages,
                page: this.state.page + 1,
            })
        } catch (e) {
            const msg = e.message || 'Could not fetch Rooms.'
            this.setState({
                isFetching: false,
                isPaginating: false,
                hasError: true,
                error: msg,
            })
        }
    }

    async componentDidMount() {
        await this.loadMore()
    }

    navigateToCreate() {
        return this.props.history.push('/admin/registrants/create')
    }

    get userLikeArray(): UserLike[] {
        return this.state.registrants.map(r => ({
            _id: r._id,
            FirstName: r.FirstName || '',
            LastName: r.LastName || '',
            Details: r.SpecialHandling || r.Notes || '',
            Image: r.Image,
        }))
    }

    goToEdit(id: string) {
        return this.props.history.push(`/admin/registrant/${id}`)
    }

    render() {
        return (
            <div className="RegistrantsList">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Button
                    content="Create New"
                    icon="add"
                    labelPosition="left"
                    primary
                    onClick={this.navigateToCreate.bind(this)}
                />
                <UsersList users={this.userLikeArray} onClick={this.goToEdit.bind(this)} />
                <LoadMore
                    next={() => {
                        this.loadMore()
                    }}
                    isLoading={this.state.isPaginating}
                    hasMore={this.state.hasMorePages}
                />
            </div>
        )
    }
}

export default withRouter(RegistrantsList)
