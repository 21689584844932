import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import * as actions from '../../actions/auth'
import './style.less'
import { AppState } from '../../reducers'

import { Form, Button, Message } from 'semantic-ui-react'
import { UserProfile, CurrentUser } from '../../types'
import { updateProfile } from '../../services/Users'

import ChangePassword from './ChangePassword'
import EditableImage from '../../components/EditableImage'
import MaskedInput from 'react-text-mask';

interface Props extends RouteComponentProps {
    profile: UserProfile | null
    currentUser: CurrentUser | null
    setProfile: (profile: UserProfile) => any
}

interface State {
    FirstName: string
    LastName: string
    Phone: string | null
    Cell: string | null
    Icon: string | null
    showPasswordForm: boolean
    saving: boolean
    saveError: string | null
    success: boolean
    modalOpen: boolean
}

class ProfileSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        if (props.profile) {
            const { FirstName, LastName, Phone, Cell, Icon } = props.profile
            this.state = {
                FirstName,
                LastName,
                Phone,
                Cell,
                Icon,
                showPasswordForm: false,
                saving: false,
                saveError: null,
                success: false,
                modalOpen: false,
            }
        }
    }

    handleChange(property: keyof State, value: string | boolean | null) {
        this.setState({
            [property]: value,
        } as Pick<State, keyof State>)
    }

    async handleSave() {
        const { FirstName, LastName, Cell, Phone, Icon } = this.state
        this.setState({
            saving: true,
            saveError: null,
            success: false,
        })
        try {
            const newProfile = await updateProfile({
                FirstName,
                LastName,
                Phone,
                Cell,
                Icon,
            })
            this.setState({
                saving: false,
                saveError: null,
                success: true,
            })
            this.props.setProfile(newProfile)
        } catch (e) {
            console.log(e)
            this.setState({
                saving: false,
                saveError: e.message,
                success: false,
            })
        }
    }

    renderErrorMessage(header: string, body: string) {
        return (
            <Message error>
                <Message.Header>{header}</Message.Header>
                <p>{body}</p>
            </Message>
        )
    }

    renderSucessMessage(header: string, body: string) {
        return (
            <Message success>
                <Message.Header>{header}</Message.Header>
                <p>{body}</p>
            </Message>
        )
    }

    onPasswordChangeSuccess() {
        this.setState({
            saveError: null,
            success: true,
        })
    }

    onPasswordChangeError(e: string) {
        this.setState({
            success: false,
            saveError: e,
        })
    }

    render() {
        if (!this.props.profile) return null
        const placeholderSrc = `${process.env.PUBLIC_URL}/avatar_placeholder.png`

        return (
            <div className="ProfileSettings">
                <div className="formBox">
                    <EditableImage
                        url={this.state.Icon}
                        placeholder={placeholderSrc}
                        onSelected={newUrl => this.handleChange('Icon', newUrl)}
                    />
                    <div className="username">{this.props.profile.Username}</div>
                    <Form
                        className="settings-form"
                        onSubmit={this.handleSave.bind(this)}
                        error={this.state.saveError ? true : false}
                        success={this.state.success}
                    >
                        <Form.Group widths="equal">
                            <Form.Input
                                fluid
                                label="First name"
                                onChange={e => this.handleChange('FirstName', e.currentTarget.value)}
                                value={this.state.FirstName || ''}
                                placeholder="First name"
                            />
                            <Form.Input
                                fluid
                                label="Last name"
                                onChange={e => this.handleChange('LastName', e.currentTarget.value)}
                                value={this.state.LastName || ''}
                                placeholder="Last name"
                            />
                        </Form.Group>
                        <Form.Field>
                            <label>Email</label>
                            <Form.Input
                                value={this.props.profile.Email || ''}
                                placeholder="Email"
                                type="email"
                                autoComplete="false"
                                name="registeremail"
                                disabled
                            />
                        </Form.Field>
                        <Form.Field>
                            <a onClick={() => this.setState({ showPasswordForm: true })}>Change Password</a>
                            {this.state.showPasswordForm && (
                                <ChangePassword
                                    onError={this.onPasswordChangeError.bind(this)}
                                    onSuccess={this.onPasswordChangeSuccess.bind(this)}
                                    currentUser={this.props.currentUser}
                                />
                            )}
                        </Form.Field>
                        <Form.Field>
                            <label>Phone</label>
                            <Form.Input
                                placeholder="Phone"
                                children={
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        type="text"
                                        value={this.state.Phone || ''}
                                        onChange={e => this.handleChange('Phone', e.currentTarget.value)}
                                        autoComplete="false"
                                        placeholder="Phone"
                                    />
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Cell</label>
                            <Form.Input
                                placeholder="Cell"
                                children={
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        type="text"
                                        value={this.state.Cell || ''}
                                        onChange={e => this.handleChange('Cell', e.currentTarget.value)}
                                        autoComplete="false"
                                        placeholder="Cell"
                                    />
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            {this.renderErrorMessage('Could not update profile', this.state.saveError || '')}
                            {this.renderSucessMessage('Success', 'Profile saved successfully!')}
                        </Form.Field>
                        <Button
                            type="submit"
                            primary
                            floated="right"
                            loading={this.state.saving}
                            disabled={this.state.saving}
                        >
                            Save Changes
                        </Button>
                    </Form>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
        currentUser: authReducer.currentUser,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.AuthAction>) {
    return {
        setProfile: (profile: UserProfile) => dispatch(actions.setProfile(profile)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ProfileSettings))
