import React from 'react'
import { Item } from 'semantic-ui-react'
import { distanceInWordsToNow } from 'date-fns'
import './style.less'

interface Props {
    id: string
    residentName: string
    requestTime: Date
    requestName: string
    room: string
    escalatedIndicator: boolean
    avatarUrl?: string
    onClick: () => any
}

const RequestItem: React.FC<Props> = props => {
    const avatarUrl = props.avatarUrl || `${process.env.PUBLIC_URL}/avatar_placeholder.png`

    return (
        <Item className="RequestItem very specific class" onClick={props.onClick}>
            <Item.Image avatar size="tiny" className="profile-pic" src={avatarUrl} />

            <Item.Content verticalAlign="middle">
                <Item.Header as="a">{props.residentName}</Item.Header>
                <Item.Meta>
                    {props.requestName}
                    <span style={{ color: 'red' }}>{props.escalatedIndicator ? '*' : ''}</span>
                </Item.Meta>
                <Item.Meta>
                    Room: <b>{props.room}</b>
                </Item.Meta>
                <Item.Extra>{distanceInWordsToNow(props.requestTime)} ago</Item.Extra>
            </Item.Content>
        </Item>
    )
}

export default RequestItem
