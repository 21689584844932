import React, { useState, useEffect } from 'react'
import { fetchRequests } from '../services/Requests'
import { Request } from '../types'
import { registerOnNotificationHandler } from '../services/PushNotifications'
import { ReportSummaryFilters } from '../types'

const REFRESH_REQUESTS_TIME_MS = 30000

export const useAdminPanelRequests = (
    filters: ReportSummaryFilters,
    sortBy: object[] | undefined,
    shouldBG = false,
    isReport = false,
    pageNo = 1,
    pageSize = 40,
    history,
) => {
    const [requests, setRequests] = useState<Request[]>([])
    const [error, setError] = useState()
    const [pageCount, setPageCount] = useState(1)
    const [isFetching, setIsFetching] = useState(false)
    const [isPaginating, setIsPaginating] = useState(false)
    const [hasMorePages, setHasMorePages] = useState(true)

    const loadRequests = async (
        requestFilters: ReportSummaryFilters,
        requestPageNo: number,
        requestPageSize: number,
        requestSortBy: Object[] | undefined,
        isBG: boolean,
        isRequestPaginating: boolean,
    ) => {
        if (!isBG && !isRequestPaginating) {
            setRequests([])
            setIsFetching(true)
        }

        if (isRequestPaginating) {
            setIsPaginating(true)
        }

        return fetchRequests(
            requestFilters.requestType,
            requestFilters.fromDate,
            requestFilters.toDate,
            null,
            requestFilters.staff === 'all' ? null : requestFilters.staff,
            requestFilters.resident === 'all' ? null : requestFilters.resident,
            requestFilters.department === 'all' ? null : requestFilters.department,
            requestPageNo,
            requestPageSize,
            requestSortBy,
        ).then(({ count, requests: freshRequests }) => {
            console.log(count, requestPageSize * requestPageNo, count >= requestPageSize * requestPageNo)
            setRequests(freshRequests)
            setIsFetching(false)
            setIsPaginating(false)
            setPageCount(Math.ceil(count / requestPageSize))
            setHasMorePages(count >= requestPageSize * requestPageNo)
        })
    }

    useEffect(() => {
        const requestPageSize = isReport ? pageSize : pageSize * pageNo
        const requestPageNo = isReport ? pageNo : 1

        loadRequests(filters, requestPageNo, requestPageSize, sortBy, false, pageNo !== 1)
    }, [filters, sortBy, isReport, pageSize, pageNo])

    useEffect(() => {
        if (shouldBG) {
            const bGFunction = () => {
                const requestPageSize = isReport ? pageSize : pageSize * pageNo
                const requestPageNo = isReport ? pageNo : 1

                loadRequests(filters, requestPageNo, requestPageSize, sortBy, true, false)
            }

            const unsubscribeHandler = registerOnNotificationHandler(bGFunction, history)
            const timerId = window.setInterval(bGFunction, REFRESH_REQUESTS_TIME_MS)

            return () => {
                if (timerId) window.clearInterval(timerId)
                if (unsubscribeHandler) unsubscribeHandler()
            }
        }
    }, [filters, sortBy, isReport, pageSize, pageNo, shouldBG])

    return {
        requests,
        error,
        isFetching,
        isPaginating,
        hasMorePages,
        pageCount,
    }
}
