import React from 'react'
import ContactsForm, { FormValues } from '../ContactsForm'

import { withRouter, RouteComponentProps } from 'react-router'
import { Contact, Registrant } from '../../../../types'
import { Dimmer, Loader } from 'semantic-ui-react'
import { fetchOneContact, deleteContact, updateContact } from '../../../../services/Contacts'
import { fetchRegistrants } from '../../../../services/Registrants'

interface State {
    isFetching: boolean
    contact: Contact | null
    registrants: Registrant[]
    isSaving: boolean
    error: string | null
}

interface RouteInfo {
    id: string
}

class ContactEdit extends React.Component<RouteComponentProps<RouteInfo>, State> {
    id: string
    constructor(props: RouteComponentProps<RouteInfo>) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            contact: null,
            isFetching: false,
            registrants: [],
        }

        this.id = props.match.params.id
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const [contact, registrants] = await Promise.all([fetchOneContact(this.id), fetchRegistrants()])
            this.setState({ isFetching: false, contact, registrants })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        const postData = {
            ...data,
            _id: this.id,
        }
        try {
            await updateContact(postData)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    async handleDelete(id: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteContact(id)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    render() {
        return (
            <div className="EditContact">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.contact && (
                    <ContactsForm
                        title="Edit Contact"
                        contact={this.state.contact}
                        onSave={this.handleSave.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        cancel={this.goBack.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(ContactEdit)
