import API from './API'
import { Notification } from '../types'

export async function fetchAllNotifications(): Promise<Notification[]> {
    const res = await API.lambdaPost('/facilities/notifications/list', {    })
    res.Result.sort((a: number, b: number) => a < b ? 1 : -1)
    return res.Result as Notification[]
}

export async function sendNotification(message: string): Promise<any> {
    await API.lambdaPost('/facilities/notifications/send', {
        Message: message,
    })
}
