import React, { useState, useEffect, useCallback } from 'react'
import ReactTable from '../../../components/Tables'
// import { fetchRequestReports, downloadRequestReport } from '../../../services/Reports'

import { fetchRequests } from '../../../services/Requests'
import { Request } from '../../../types'
import { Dimmer, Loader, Icon, Button } from 'semantic-ui-react'
import ReportSummary from '../../../components/Dashboard/ReportSummary'
import './style.less'
import * as jsonexport from 'jsonexport/dist'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ReportSummaryFilters } from '../../../types'
import { useAdminPanelRequests } from '../../../hooks/adminPanel'

const Reports = props => {
    const [isDownloadingReport, setIsDownloadingReport] = useState(false)
    const [sortBy, setSortBy] = useState([{ RequestedTime: 'asc' }])
    const [pageNo, setPageNo] = useState(1)
    const [filters, setFilters] = useState<ReportSummaryFilters>({
        requestType: 'active',
        fromDate: new Date(),
        toDate: new Date(),
        staff: 'all',
        resident: 'all',
        department: 'all',
        reload: 0,
    })

    const { requests, error, isFetching, isPaginating, hasMorePages, pageCount } = useAdminPanelRequests(
        filters,
        sortBy,
        false,
        true,
        pageNo,
        50,
        props.history,
    )

    const sentiments = [
        <Icon className="face" name="frown outline" color="red" size="big" />,
        <Icon className="face" name="frown outline" color="orange" size="big" />,
        <Icon className="face" name="meh outline" color="yellow" size="big" />,
        <Icon className="face" name="smile outline" color="olive" size="big" />,
        <Icon className="face" name="smile outline" color="green" size="big" />,
    ]

    const saveRequestReport = async () => {
        setIsDownloadingReport(true)
        const PAGE_SIZE = 100

        // Download the first page to get the request count
        const { requests: requestReports, count } = await fetchRequests(
            filters.requestType,
            filters.fromDate,
            filters.toDate,
            null,
            filters.staff === 'all' ? null : filters.staff,
            filters.resident === 'all' ? null : filters.resident,
            filters.department === 'all' ? null : filters.department,
            1,
            PAGE_SIZE,
            sortBy,
        )

        // make all other requests in parallel to save download time
        const totalPage = Math.ceil(count / PAGE_SIZE)
        const pages = [...new Array(totalPage + 1)].map((e, index) => index)
        const toBeDownloaded = pages.slice(2)
        const allReportPromise = toBeDownloaded.map(page => {
            return fetchRequests(
                filters.requestType,
                filters.fromDate,
                filters.toDate,
                null,
                filters.staff === 'all' ? null : filters.staff,
                filters.resident === 'all' ? null : filters.resident,
                filters.department === 'all' ? null : filters.department,
                page,
                PAGE_SIZE,
                sortBy,
            )
        })
        console.log(pages, toBeDownloaded, 'tbd')
        const allReports = await Promise.all(allReportPromise)

        // flatten the reports
        const reports = allReports.reduce((accl, report) => {
            return [...accl, ...report.requests]
        }, requestReports)

        console.log('reports', reports)

        //@ts-ignore
        const flattenColumns = columns.reduce((accl, coln) => {
            // flatten the columns
            return [...accl, ...coln.columns]
        }, [])

        // build the output to resemble the onscreen page
        const transformedReports = reports.map(report => {
            return flattenColumns.reduce((accl, col) => {
                const { Header, accessor, id } = col
                const obj = {
                    [Header]: id ? accessor(report) : report[accessor],
                }
                return {
                    ...accl,
                    ...obj,
                }
            }, {})
        })

        console.log('transformedreports', transformedReports)

        // convert the json to csv
        // save the csv into a file
        jsonexport(transformedReports, (err, csv) => {
            console.log('csv', csv, typeof csv, err)
            const file = new File([csv], 'reports.csv', { type: 'text/csv;charset=utf-8' })
            saveAs(file, 'reports.csv')
            setIsDownloadingReport(false)
        })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Resident',
                columns: [
                    {
                        Header: 'FirstName',
                        accessor: 'Registrant_FirstName',
                    },
                    {
                        Header: 'LastName',
                        accessor: 'Registrant_LastName',
                    },
                ],
            },
            {
                Header: 'Request Info',
                columns: [
                    {
                        Header: 'Request Name',
                        accessor: 'Name',
                    },
                    {
                        Header: 'Room',
                        accessor: 'Unit_Name',
                    },
                    {
                        Header: 'Request Type',
                        accessor: 'RequestType_Name',
                    },
                    {
                        Header: 'Department',
                        accessor: 'Department_Name',
                    },
                    {
                        Header: 'Requested Time',
                        id: 'RequestedTime',
                        accessor: row => {
                            const date = new Date(row.RequestedTime)

                            return row.RequestedTime && date.toLocaleString()
                        },
                    },
                    // {
                    //     Header: 'Time Created',
                    // },
                    {
                        Header: 'Accepted By',
                        id: 'AcceptedByName',
                        accessor: row => row.AcceptedByName || '-',
                    },
                    {
                        Header: 'Accepted Time',
                        id: 'AcceptedTime',
                        accessor: row => {
                            const date = new Date(row.AcceptedTime)

                            return (row.AcceptedTime && date.toLocaleString()) || '-'
                        },
                    },
                    {
                        Header: 'Escalated Time',
                        id: 'EscalationTimeout',
                        accessor: row => {
                            const date = new Date(row.EscalationTimeout)

                            return (row.EscalationTimeout && date.toLocaleString()) || '-'
                        },
                    },
                    {
                        Header: 'Closed By',
                        id: 'ClosedByName',
                        accessor: row => row.ClosedByName || '-',
                    },
                    {
                        Header: 'Closed Time',
                        id: 'ClosedTime',
                        accessor: row => {
                            const date = new Date(row.ClosedTime)

                            return (row.ClosedTime && date.toLocaleString()) || '-'
                        },
                    },
                    {
                        Header: 'Sentiment',
                        accessor: 'Sentiment',
                        Cell: ({ cell: { value } }) => (value && sentiments[value - 1]) || <></>,
                    },
                ],
            },
        ],
        [],
    )

    return (
        <div className="DashboardHome">
            <ReportSummary
                filters={filters}
                handleFilterChange={updatedFilters => {
                    setPageNo(1)
                    setFilters(updatedFilters)
                }}
                shouldCreateRequestBeShown={false}
                history={props.history}
            />
            <Dimmer active={isFetching || isPaginating} inverted>
                <Loader active={isFetching || isPaginating} />
            </Dimmer>
            <ReactTable
                columns={columns}
                data={requests}
                controlledPageCount={pageCount}
                setSortBy={sort => {
                    setPageNo(1)

                    setSortBy(sort)
                }}
                setPageNo={no => {
                    setPageNo(no)
                }}
                // fetchData={fetchData}
                saveRequestReport={saveRequestReport}
                isDownloadingReport={isDownloadingReport}
            />
        </div>
    )
}

export default withRouter(Reports)
