import React from 'react'
import ProtectedRoute from '../../../components/ProtectedRoute'

import { AppState } from '../../../reducers'
import { connect } from 'react-redux'
import { UserProfile } from '../../../types'
import ListUnits from '../../../pages/AdminPanel/Units/List'
import EditUnit from '../../../pages/AdminPanel/Units/Edit'
import CreateUnit from '../../../pages/AdminPanel/Units/Create'
import ListDepartments from '../../../pages/AdminPanel/Departments/List'
import EditDepartment from '../../../pages/AdminPanel/Departments/Edit'
import CreateDepartment from '../../../pages/AdminPanel/Departments/Create'
import ListContacts from '../../../pages/AdminPanel/Contacts/List'
import EditContact from '../../../pages/AdminPanel/Contacts/Edit'
import CreateContact from '../../../pages/AdminPanel/Contacts/Create'
import ListRequestTypes from '../../../pages/AdminPanel/RequestTypes/List'
import EditRequestTypes from '../../../pages/AdminPanel/RequestTypes/Edit'
import CreateRequestType from '../../../pages/AdminPanel/RequestTypes/Create'
import Speak2FamilyBroadcastIncomingList from '../../../pages/AdminPanel/Speak2FamilyBroadcast/List'
import {
    canManageUnits,
    canManageDepartments,
    canManageContacts,
    canManageRequestTypes,
    canManageUsers,
} from '../../../services/Permissions'
import Tabs from './Tabs'

interface Props {
    isAuthenticated: boolean
    profile: UserProfile | null
}
interface State {}

const tabItems = (profile: UserProfile | null) => [
    {
        name: 'Contacts',
        url: '/admin/forms/contacts/list',
        icon: 'address book',
        disabled: !canManageContacts(profile),
    },
    {
        name: 'Rooms',
        url: '/admin/forms/units/list',
        icon: 'bed',
        disabled: !canManageUnits(profile),
    },
    {
        name: 'Departments',
        url: '/admin/forms/departments/list',
        icon: 'hospital',
        disabled: !canManageDepartments(profile),
    },
    {
        name: 'Service Types',
        url: '/admin/forms/requesttypes/list',
        icon: 'wrench',
        disabled: !canManageRequestTypes(profile),
    },
    {
        name: 'Speak2 Family Broadcast',
        url: '/admin/forms/speak2family/list',
        icon: 'street view',
        disabled: !canManageUsers(profile),
    },
]

class FormIndex extends React.Component<Props, State> {
    render() {
        return (
            <Tabs
                tabItems={tabItems(this.props.profile)}
                render={() => (
                    <div className="FormsIndex">
                        <ProtectedRoute
                            path="/admin/forms/units/list"
                            exact
                            component={ListUnits}
                            condition={canManageUnits(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/units/create"
                            exact
                            component={CreateUnit}
                            condition={canManageUnits(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/unit/:id"
                            exact
                            component={EditUnit}
                            condition={canManageUnits(this.props.profile)}
                            redirect="/"
                        />

                        <ProtectedRoute
                            path="/admin/forms/departments/list"
                            exact
                            component={ListDepartments}
                            condition={canManageDepartments(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/departments/create"
                            exact
                            component={CreateDepartment}
                            condition={canManageDepartments(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/department/:id"
                            exact
                            component={EditDepartment}
                            condition={canManageDepartments(this.props.profile)}
                            redirect="/"
                        />

                        <ProtectedRoute
                            path="/admin/forms/contacts/list"
                            exact
                            component={ListContacts}
                            condition={canManageContacts(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/contacts/create"
                            exact
                            component={CreateContact}
                            condition={canManageContacts(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/contact/:id"
                            exact
                            component={EditContact}
                            condition={canManageContacts(this.props.profile)}
                            redirect="/"
                        />

                        <ProtectedRoute
                            path="/admin/forms/requesttypes/list"
                            exact
                            component={ListRequestTypes}
                            condition={canManageRequestTypes(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/requesttypes/create"
                            exact
                            component={CreateRequestType}
                            condition={canManageRequestTypes(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/requesttype/:id"
                            exact
                            component={EditRequestTypes}
                            condition={canManageRequestTypes(this.props.profile)}
                            redirect="/"
                        />
                        <ProtectedRoute
                            path="/admin/forms/speak2family/list"
                            exact
                            component={Speak2FamilyBroadcastIncomingList}
                            condition={canManageUsers(this.props.profile)}
                            redirect="/"
                        />
                    </div>
                )}
            />
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        isAuthenticated: authReducer.isAuthenticated,
        profile: authReducer.profile,
    }
}

// function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.AuthAction>) {
//     return {
//         onLogOut: () => dispatch(actions.logOut()),
//     }
// }

function mapDispatchToProps() {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FormIndex)
