import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// import Requests from './pages/StaffApp/Requests/Unassigned'
import StaffDashboard from './pages/StaffApp/Requests/StaffDashboard'
import SingleRequest from './pages/StaffApp/Requests/SingleRequest'
import Login from './pages/Login'
import NewPassword from './pages/NewPassword'
import ProfileSettings from './pages/ProfileSettings'
import Navigation from './components/Navigation'
import ProtectedRoute from './components/ProtectedRoute'

import './App.css'
import 'semantic-ui-less/semantic.less'

import { AppState } from './reducers'
import * as actions from './actions/auth'
import { connect } from 'react-redux'
import { Sidebar, Segment } from 'semantic-ui-react'
import { CurrentUser, UserProfile } from './types'
import MyRequests from './pages/StaffApp/Requests/MyRequests'

import AdminHome from './pages/AdminPanel/Home'
import AdminSingleRequest from './pages/AdminPanel/Requests/Single'
import {
    canManageRequests,
    canManageUsers,
    canManageRegistrants,
    canManageFacilities,
    canAccessFacilityForms,
    canManageNotifications,
    isSysAdmin,
    canManageDailyActivities,
    canManageMenus,
    canSeeDashboard,
    canManageUniversityTrainings,
    canManageStandardUniversityTrainings,
} from './services/Permissions'
import CreateRequest from './pages/AdminPanel/Requests/Create'
import UsersHome from './pages/AdminPanel/Staff/Home'
import CreateUser from './pages/AdminPanel/Staff/Create'
import EditUser from './pages/AdminPanel/Staff/Edit'
import CreateRegistrant from './pages/AdminPanel/Registrants/Create'
import EditRegistrant from './pages/AdminPanel/Registrants/Edit'
import ListRegistrants from './pages/AdminPanel/Registrants/List'
import ListFacilities from './pages/AdminPanel/Facilities/List'
import EditFacility from './pages/AdminPanel/Facilities/Edit'
import CreateFacility from './pages/AdminPanel/Facilities/Create'
import Notifications from './pages/AdminPanel/Notifications'
import { ThunkDispatch } from 'redux-thunk'
import FormsIndex from './pages/AdminPanel/FormsIndex'
import DailyActivities from './pages/AdminPanel/DailyActivities'
import Menu from './pages/AdminPanel/Menu'
import Reports from './pages/AdminPanel/Reports'
import CloneData from './pages/AdminPanel/CloneData'
import ListUniversityTrainings from './pages/AdminPanel/UniversityTrainings/List'
import CreateUniversityTrainings from './pages/AdminPanel/UniversityTrainings/Create'
import EditUniversityTrainings from './pages/AdminPanel/UniversityTrainings/Edit'
import ListStandardUniversityTrainings from './pages/AdminPanel/StandardUniversityTrainings/List'
import CreateStandardUniversityTrainings from './pages/AdminPanel/StandardUniversityTrainings/Create'
import EditStandardUniversityTrainings from './pages/AdminPanel/StandardUniversityTrainings/Edit'

toast.configure({ autoClose: 1000 })

interface Props {
    isAuthenticated: boolean
    currentUser: CurrentUser | null
    profile: UserProfile | null
    passwordChangeRequired: boolean
    onLogOut: () => void
}
interface State {
    showSidebar: boolean
}

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            showSidebar: false,
        }
    }

    setShowSidebar(to: boolean) {
        this.setState({
            showSidebar: to,
        })
    }

    render() {
        let HomePage: any = canManageRequests(this.props.profile)
            ? (props: any) => <AdminHome profile={this.props.profile} />
            : (props: any) => <StaffDashboard />
        if (isSysAdmin(this.props.profile)) HomePage = (props: any) => <UsersHome profile={this.props.profile} />
        return (
            <Fragment>
                <Router>
                    <Sidebar.Pushable as={Segment} className="App">
                        <Navigation
                            showSidebar={this.state.showSidebar}
                            setShowSidebar={this.setShowSidebar.bind(this)}
                            currentUser={this.props.currentUser}
                            profile={this.props.profile}
                            logOut={this.props.onLogOut}
                            isAuthenticated={this.props.isAuthenticated}
                        />
                        <Sidebar.Pusher>
                            <Switch>
                                <ProtectedRoute
                                    path="/"
                                    exact
                                    render={HomePage}
                                    condition={this.props.isAuthenticated}
                                    redirect="/login"
                                />
                                <ProtectedRoute
                                    path="/myrequests"
                                    exact
                                    component={MyRequests}
                                    condition={this.props.isAuthenticated}
                                    redirect="/login"
                                />
                                <ProtectedRoute
                                    path="/request/:id"
                                    exact
                                    component={SingleRequest}
                                    condition={this.props.isAuthenticated}
                                    redirect="/login"
                                />
                                <Route
                                    path="/login"
                                    component={Login}
                                    // condition={!this.props.isAuthenticated}
                                    // redirect="/"
                                />
                                <ProtectedRoute
                                    path="/newpassword"
                                    component={NewPassword}
                                    condition={this.props.passwordChangeRequired}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/profile"
                                    component={ProfileSettings}
                                    condition={this.props.isAuthenticated}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/request/:id"
                                    exact
                                    component={AdminSingleRequest}
                                    condition={canManageRequests(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/requests/create"
                                    exact
                                    component={CreateRequest}
                                    condition={canManageRequests(this.props.profile)}
                                    redirect="/"
                                />

                                <ProtectedRoute
                                    path="/admin/users/list"
                                    exact
                                    component={() => <UsersHome profile={this.props.profile} />}
                                    condition={canManageUsers(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/users/create"
                                    exact
                                    render={(props: any) => <CreateUser {...props} profile={this.props.profile} />}
                                    condition={canManageUsers(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/user/:id"
                                    exact
                                    render={(props: any) => <EditUser {...props} profile={this.props.profile} />}
                                    condition={canManageUsers(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/registrants/list"
                                    exact
                                    component={ListRegistrants}
                                    condition={canManageRegistrants(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/registrants/create"
                                    exact
                                    component={CreateRegistrant}
                                    condition={canManageRegistrants(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/registrant/:id"
                                    exact
                                    component={EditRegistrant}
                                    condition={canManageRegistrants(this.props.profile)}
                                    redirect="/"
                                />

                                <ProtectedRoute
                                    path="/admin/facilities/list"
                                    exact
                                    component={ListFacilities}
                                    condition={canManageFacilities(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/facilities/create"
                                    exact
                                    component={CreateFacility}
                                    condition={canManageFacilities(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/facility/:id"
                                    exact
                                    component={EditFacility}
                                    condition={canManageFacilities(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/forms/"
                                    component={FormsIndex}
                                    condition={canAccessFacilityForms(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/notifications"
                                    exact
                                    component={Notifications}
                                    condition={canManageNotifications(this.props.profile)}
                                    redirect="/"
                                />
                                <ProtectedRoute
                                    path="/admin/dailyactivities"
                                    exact
                                    component={() => <DailyActivities profile={this.props.profile} />}
                                    condition={canManageDailyActivities(this.props.profile)}
                                    redirect="/"
                                    profile={this.props.profile}
                                />
                                <ProtectedRoute
                                    path="/admin/menu"
                                    exact
                                    component={() => <Menu profile={this.props.profile} />}
                                    condition={canManageMenus(this.props.profile)}
                                    redirect="/"
                                    profile={this.props.profile}
                                />
                                <ProtectedRoute
                                    path="/admin/reports"
                                    exact
                                    condition={canSeeDashboard(this.props.profile)}
                                    redirect="/"
                                    component={() => <Reports />}
                                    profile={this.props.profile}
                                />
                                <ProtectedRoute
                                    path="/admin/clone-data"
                                    exact
                                    component={CloneData}
                                    condition={canManageFacilities(this.props.profile)}
                                    redirect="/"
                                />

                                <ProtectedRoute
                                    path="/admin/university/trainings/list"
                                    exact
                                    component={() => <ListUniversityTrainings profile={this.props.profile} />}
                                    condition={canManageUniversityTrainings(this.props.profile)}
                                    redirect="/"
                                    profile={this.props.profile}
                                />
                                <ProtectedRoute
                                    path="/admin/university/trainings/create"
                                    exact
                                    component={() => <CreateUniversityTrainings profile={this.props.profile} />}
                                    condition={canManageUniversityTrainings(this.props.profile)}
                                    redirect="/"
                                    profile={this.props.profile}
                                />
                                <ProtectedRoute
                                    path="/admin/university/trainings/:id"
                                    exact
                                    component={() => <EditUniversityTrainings profile={this.props.profile} />}
                                    condition={canManageUniversityTrainings(this.props.profile)}
                                    redirect="/"
                                    profile={this.props.profile}
                                />
                                <ProtectedRoute
                                    path="/admin/university/standard-trainings/list"
                                    exact
                                    component={() => <ListStandardUniversityTrainings profile={this.props.profile} />}
                                    condition={canManageStandardUniversityTrainings(this.props.profile)}
                                    redirect="/"
                                    profile={this.props.profile}
                                />
                                <ProtectedRoute
                                    path="/admin/university/standard-trainings/create"
                                    exact
                                    component={() => <CreateStandardUniversityTrainings profile={this.props.profile} />}
                                    condition={canManageStandardUniversityTrainings(this.props.profile)}
                                    redirect="/"
                                    profile={this.props.profile}
                                />
                                <ProtectedRoute
                                    path="/admin/university/standard-trainings/:id"
                                    exact
                                    component={() => <EditStandardUniversityTrainings profile={this.props.profile} />}
                                    condition={canManageStandardUniversityTrainings(this.props.profile)}
                                    redirect="/"
                                    profile={this.props.profile}
                                />
                            </Switch>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Router>
                <ToastContainer />
            </Fragment>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        isAuthenticated: authReducer.isAuthenticated,
        currentUser: authReducer.currentUser,
        profile: authReducer.profile,
        passwordChangeRequired: authReducer.passwordChangeRequired,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, actions.AuthAction>) {
    return {
        onLogOut: () => dispatch(actions.logOut()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
