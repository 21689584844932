import React from 'react'
import { Item } from 'semantic-ui-react'
import './style.less'

interface Props {
    id: string
    name: string
    details: string
    avatarUrl?: string
    onClick: () => any
}

const UserItem: React.FC<Props> = props => {
    const avatarUrl = props.avatarUrl || `${process.env.PUBLIC_URL}/avatar_placeholder.png`
    
    return (
        <Item className="UserItem very specific userclass" onClick={props.onClick}>
            <Item.Image
                avatar
                size="tiny"
                className="profile-pic"
                src={avatarUrl}
            />

            <Item.Content verticalAlign="middle">
                <Item.Header as="a">{props.name}</Item.Header>
                <Item.Meta>{props.details}</Item.Meta>
            </Item.Content>
        </Item>
    )
}

export default UserItem
