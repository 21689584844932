import React from 'react'
import './style.less'
import { UserProfile } from '../../../../types'
import { RouteComponentProps, withRouter } from 'react-router'
import TrainingList from '../../../../components/TrainingList'

interface State {}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

class ListStandardUniversityTrainings extends React.Component<Props, State> {
    render() {
        console.log('listStandard: ')
        return (
            <div className="container">
                <TrainingList type="standard" />
            </div>
        )
    }
}

export default withRouter(ListStandardUniversityTrainings)
