import React from 'react'
import { Card, Image, Icon, Label } from 'semantic-ui-react'

import './style.less'
import { RequestStatus } from '../../types'
import { format } from 'date-fns'

interface Props {
    categoryImageUrl?: string
    firstName: string
    lastName: string
    roomNo: string
    type: string
    userAvatarUrl?: string
    status: RequestStatus
    createdAt: Date
    info: string
}

const BasicRequestItem: React.FC<Props> = props => {
    const userAvatarUrl = props.userAvatarUrl || `${process.env.PUBLIC_URL}/avatar_placeholder.png`

    //const getQueryDetail = (props.type === 'DailyActivities') ? new Date(props.info).toLocaleDateString() : props.info;

    return (
        <Card className="AdminRequestItem basic-request-item">
            <Card.Content>
                <Image floated="left" size="mini" circular src={userAvatarUrl} />
                <Card.Header>{props.firstName + ' ' + props.lastName}</Card.Header>
                <Card.Meta>Room {props.roomNo}</Card.Meta>
                <Card.Description>
                    <div className="request-details">
                        <div className="extra-details">
                            <div className="extra-details-wrapper">
                                <div className="detail-item">
                                    {format(props.createdAt, 'MM/DD/YYYY hh:mm A')}
                                    <br />
                                    Query: {props.info}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="status-label-container">
                        <Label ribbon color={props.type === 'DailyActivities' ? 'orange' : 'green'}>
                            {props.type}
                        </Label>
                    </div>
                </Card.Description>
            </Card.Content>
        </Card>
    )
}

export default BasicRequestItem
