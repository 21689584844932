import React, { useMemo } from 'react'
import { useTable, useSortBy, UsePaginationState, usePagination } from 'react-table'
import { Table, Icon, Menu, Button } from 'semantic-ui-react'

const ReactTables = ({
    columns,
    data,
    controlledPageCount = 10,
    // fetchData,
    saveRequestReport,
    isDownloadingReport,
    setSortBy,
    setPageNo,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 50, sortBy: [{ id: 'RequestedTime', desc: true }] },
            manualSorting: true,
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        useSortBy,
        usePagination,
    )

    React.useEffect(() => {
        const transformedSortBy = sortBy.map(({ id, desc }) => ({
            [id]: desc ? 'asc' : 'desc',
        }))
        setSortBy(transformedSortBy)
        setPageNo(pageIndex + 1)
    }, [sortBy, pageIndex])

    const downloadReports = () => {
        saveRequestReport()
    }

    return (
        <>
            <Button primary onClick={() => downloadReports()} disabled={isDownloadingReport}>
                {isDownloadingReport ? 'Preparing Result' : 'Download Reports'}
            </Button>

            <Table celled {...getTableProps()}>
                <Table.Header>
                    {headerGroups.map((headerGroup, index) => (
                        <Table.Row {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${index}`}>
                            {headerGroup.headers.map((column, hgIndex) => (
                                //@ts-ignore
                                <Table.HeaderCell
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    key={`hgIndex-${hgIndex}`}
                                >
                                    {column.render('Header')}

                                    <span>
                                        {//
                                        //@ts-ignore
                                        column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <Icon name="angle down" />
                                            ) : (
                                                <Icon name="angle up" />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) =>
                            //@ts-ignore

                            prepareRow(row) || (
                                <Table.Row {...row.getRowProps()} key={`row-${i}`}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <Table.Cell {...cell.getCellProps()} key={`cell-${index}`}>
                                                {cell.render('Cell')}
                                            </Table.Cell>
                                        )
                                    })}
                                </Table.Row>
                            ),
                    )}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="3">
                            <Menu floated="right" pagination>
                                <Menu.Item as="a" icon onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    <Icon name="chevron left" />
                                </Menu.Item>
                                {pageOptions.map((e, i) => (
                                    <Menu.Item
                                        as="a"
                                        active={pageIndex === e}
                                        onClick={() => gotoPage(e)}
                                        key={`footer-${i}`}
                                    >
                                        {e + 1}
                                    </Menu.Item>
                                ))}

                                <Menu.Item
                                    as="a"
                                    icon
                                    onClick={() => {
                                        nextPage()
                                        console.log('rrr')
                                    }}
                                    disabled={!canNextPage}
                                >
                                    <Icon name="chevron right" />
                                </Menu.Item>
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    )
}

export default ReactTables
