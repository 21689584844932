import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Tab, TabProps } from 'semantic-ui-react'

interface TabItem {
    url: string
    name: string
    icon: string
    disabled?: boolean
}

interface Props extends RouteComponentProps {
    render: React.FC<RouteComponentProps>
    tabItems: TabItem[]
}

interface State {
    activeIndex: number
}

class Tabs extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        let idx = this.props.tabItems.findIndex(item => item.url === this.props.location.pathname)

        this.state = {
            activeIndex: idx,
        }
    }

    get panes() {
        return this.props.tabItems.map(item => ({
            menuItem: {
                content: item.name,
                icon: item.icon,
                disabled: item.disabled,
                key: item.url,
            },
            render: () => <Tab.Pane>{this.props.render({ ...this.props })}</Tab.Pane>,
        }))
    }

    setActiveItem(index: number) {
        this.setState({ activeIndex: index })
        const item = this.props.tabItems[index]
        if (!item) return
        this.props.history.push(item.url)
    }

    handleChange(e: any, data: TabProps) {
        if (data.activeIndex === undefined) return
        this.setActiveItem(data.activeIndex as number)
    }

    render() {
        return (
            <div className="Tabs">
                <Tab
                    menu={{ fluid: true, vertical: true, tabular: true }}
                    panes={this.panes}
                    onTabChange={this.handleChange.bind(this)}
                    activeIndex={this.state.activeIndex}
                />
            </div>
        )
    }
}

export default withRouter(Tabs)
