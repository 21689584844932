import React from 'react'
import { Form, InputOnChangeData } from 'semantic-ui-react'

interface Props {
    isSaving: boolean
    onSubmit: (message: string) => Promise<any>
}

interface State {
    message: string
}

class NotificationsForm extends React.Component<Props, State> {
    state: State = {
        message: '',
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        this.setState({
            message: data.value,
        })
    }

    handleSubmit() {
        this.props.onSubmit(this.state.message)
    }

    render() {
        return (
            <Form loading={this.props.isSaving} onSubmit={this.handleSubmit.bind(this)}>
                <Form.Group widths={2}>
                    <Form.Input
                        placeholder="New Announcement"
                        value={this.state.message}
                        onChange={this.handleChange.bind(this)}
                    />
                    <Form.Button type="submit" primary>
                        Send Announcement
                    </Form.Button>
                </Form.Group>
            </Form>
        )
    }
}

export default NotificationsForm
