import API from './API'
import { FetchRequestsResponse } from '../types'

import { Request } from '../types'

interface getTimestampLimitsResponse {
    startTimestamp: Number
    endTimestamp: Number
}

interface requestFilters {
    Status?: object | string
    RequestedTime?: object
    Sentiment?: number
    AcceptedBy?: string | null
    EscalatedTo?: object
    Registrant?: string
    Department?: string
}

interface requestOptions {
    Filter: requestFilters
    QueryOptions: {
        page_no: number
        page_size: number
        sort: object[]
    }
}

function parseFetchResponse(response: any, page: number = 1, limit: number = 100): FetchRequestsResponse {
    const res: FetchRequestsResponse = {
        count: response.TotRecords || 0,
        page,
        limit,
        requests: response.Result,
    }
    return res
}

/**
 * Identifies the start and end timestamps of the local date
 *
 *
 */
function getTimestampLimits(fromDate: Date, toDate: Date) {
    const fromYear = fromDate.getFullYear()
    const fromMonth = fromDate.getMonth()
    const fromDay = fromDate.getDate()

    const toYear = toDate.getFullYear()
    const toMonth = toDate.getMonth()
    const toDay = toDate.getDate()

    return {
        // from morning 12 am
        startTimestamp: new Date(fromYear, fromMonth, fromDay).getTime(),
        // Till the end time night 11:59:59 pm
        endTimestamp: new Date(toYear, toMonth, toDay, 23, 59, 59).getTime(),
    }
}

export async function fetchAllRequests(): Promise<FetchRequestsResponse> {
    const res = await API.lambdaPost(`/requests/list`, {
        Filter: {},
        QueryOptions: {
            page_no: 1,
            page_size: 100,
            sort: [{ RequestedTime: 'asc' }],
        },
    })
    const parsed = parseFetchResponse(res)
    return parsed
}

export async function fetchActiveRequests(page_no = 1, page_size = 100): Promise<FetchRequestsResponse> {
    const res = await API.lambdaPost(`/requests/list`, {
        Filter: {
            Status: { $ne: 'Closed' },
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ RequestedTime: 'asc' }],
        },
    })
    const parsed = parseFetchResponse(res, page_no, page_size)
    return parsed
}

export async function fetchFilteredRequests(
    filters: Partial<Request>,
    page_no = 1,
    page_size = 100,
): Promise<FetchRequestsResponse> {
    const res = await API.lambdaPost(`/requests/list`, {
        Filter: {
            ...filters,
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ RequestedTime: 'asc' }],
        },
    })
    const parsed = parseFetchResponse(res, page_no, page_size)
    return parsed
}

export async function fetchUnassignedRequests(): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests({
        Status: 'Open',
        AcceptedBy: null,
    })
    return res
}

export async function fetchMyOpenRequests(userId: string): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests({
        Status: 'Accepted',
        AcceptedBy: userId,
    })
    return res
}

export async function fetchMyClosedRequests(userId: string): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests({
        Status: 'Closed',
        AcceptedBy: userId,
    })
    return res
}

export async function fetchEscalatedRequests(page_no = 1, page_size = 100): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests(
        {
            EscalatedTo: { $exists: true },
            Status: 'Open',
        },
        page_no,
        page_size,
    )
    return res
}

export async function fetchResolvedRequests(page_no = 1, page_size = 100): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests({ Status: 'Closed' }, page_no, page_size)
    return res
}

export async function fetchNegativeRequests(page_no = 1, page_size = 100): Promise<FetchRequestsResponse> {
    const res = await fetchFilteredRequests({ Sentiment: 1 }, page_no, page_size)
    return res
}

export async function fetchOneRequest(requestId: string): Promise<Request | null> {
    try {
        const res = await API.lambdaGet(`/requests/get?_id=${requestId}`)
        return res as Request
    } catch (e) {
        if (e.statusCode === 404) return null
        throw e
    }
}

export async function acceptRequest(requestId: string): Promise<Request> {
    await API.lambdaGet(`/requests/accept?_id=${requestId}`)
    const updated = await fetchOneRequest(requestId)
    if (!updated) throw new Error('Could not fetch updated request')
    return updated
}

export async function closeRequest(requestId: string): Promise<Request> {
    await API.lambdaGet(`/requests/close?_id=${requestId}`)
    const updated = await fetchOneRequest(requestId)
    if (!updated) throw new Error('Could not fetch updated request')
    return updated
}

export async function setRequestSentiment(requestId: string, sentiment: number): Promise<Request> {
    await API.lambdaPost(`/requests/sentiment`, {
        _id: requestId,
        Sentiment: sentiment,
    })
    const updated = await fetchOneRequest(requestId)
    if (!updated) throw new Error('Could not fetch updated request')
    return updated
}

export async function assignRequest(requestId: string, userId: string): Promise<Request> {
    await API.lambdaPost(`/requests/assign`, {
        _id: requestId,
        AssignTo: userId,
    })
    const updated = await fetchOneRequest(requestId)
    if (!updated) throw new Error('Could not fetch updated request')
    return updated
}

export async function createRequest(request: Partial<Request>): Promise<Request> {
    const created = await API.lambdaPost('/requests/add', request)
    const req = created.RequestData as Request
    return req
}

export async function fetchRequests(
    requestState: string,
    fromDate: Date | null,
    toDate: Date | null,
    userId: string | null,
    staff: string | null,
    resident: string | null,
    department: string | null,
    page_no = 1,
    page_size = 100,
    sortBy: object[] = [{ RequestedTime: 'asc' }],
): Promise<FetchRequestsResponse> {
    const requestOptions: requestOptions = {
        Filter: {},
        QueryOptions: {
            page_no,
            page_size,
            sort: sortBy,
        },
    }

    switch (requestState) {
        case 'active':
            requestOptions.Filter.Status = { $ne: 'Closed' }
            break

        case 'unassigned':
            requestOptions.Filter.Status = 'Open'
            requestOptions.Filter.AcceptedBy = null
            break

        case 'open':
            requestOptions.Filter.Status = 'Accepted'
            requestOptions.Filter.AcceptedBy = userId
            break

        case 'closed':
            requestOptions.Filter.Status = 'Closed'
            requestOptions.Filter.AcceptedBy = userId
            break

        case 'escalated':
            requestOptions.Filter.EscalatedTo = { $ne: null }
            break

        case 'resolved':
            requestOptions.Filter.Status = 'Closed'
            break

        case 'negative':
            requestOptions.Filter.Sentiment = 1
            break

        default:
            break
    }

    if (fromDate && toDate) {
        const { startTimestamp, endTimestamp } = getTimestampLimits(fromDate, toDate)
        requestOptions.Filter.RequestedTime = { $gt: startTimestamp, $lt: endTimestamp }
    }

    if (staff) {
        requestOptions.Filter.AcceptedBy = staff
    }

    if (resident) {
        requestOptions.Filter.Registrant = resident
    }

    if (department) {
        requestOptions.Filter.Department = department
    }

    const res = await API.lambdaPost(`/requests/list`, requestOptions)
    const parsed = parseFetchResponse(res)
    return parsed
}
