import React, { useEffect } from 'react'
import { Dimmer, Loader, Card } from 'semantic-ui-react'
import LoadMore from '../../../components/LoadMore'
import AdminRequestItem from '../../../components/AdminRequestItem'
import { withRouter } from 'react-router'

const RequestList = props => {
    const navigateToSingle = (id: string) => {
        return props.history.push(`/admin/request/${id}`)
    }

    useEffect(() => {
        console.log('isFetching, ', props.isFetching)
    }, [props.isFetching])

    const items = props.requests.map(req => (
        <AdminRequestItem
            key={req._id}
            categoryImageUrl={req.Department_Icon}
            firstName={req.Registrant_FirstName}
            lastName={req.Registrant_LastName}
            userAvatarUrl={req.Registrant_Image}
            roomNo={req.Unit_Name}
            escalationEnabled={req.EscalationEnabled}
            escalationTimestamp={req.EscalationATimeout}
            status={req.Status}
            createdAt={req.RequestedTime}
            onClick={() => navigateToSingle(req._id)}
        />
    ))
    return (
        <>
            <Dimmer active={props.isFetching} inverted>
                <Loader active={props.isFetching} />
            </Dimmer>
            {items.length ? (
                <div className="Departments">
                    <Card.Group>{items}</Card.Group>
                    <LoadMore
                        isLoading={props.isPaginateLoading}
                        hasMore={props.hasMorePage}
                        next={() => props.nextPage()}
                    />
                </div>
            ) : (
                <div className="no-requests">No Requests</div>
            )}
        </>
    )
}

export default withRouter(RequestList)
