import React, { useState, useEffect } from 'react'
import './style.less'
import { Dimmer, Loader, Button, Dropdown, Input } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { cloneData } from '../../../services/CloneData'
import { fetchFacilities } from '../../../services/Facilities'
import { toast } from 'react-toastify'

const CloneData = () => {
    const defaultFilters = {
        facility: '',
    }
    const [filters, setFilters] = useState<any>(defaultFilters)
    const [loading, setLoading] = useState<boolean>(false)
    const [dateFilters, setDateFilters] = useState<any>({})
    const [facilities, setFacilities] = useState<any[]>([])

    const getFacilities = async () => {
        setLoading(true)
        const facilities = await fetchFacilities()
        setFacilities(
            facilities.map((facility) => {
                const { Name, _id } = facility
                return { text: Name, value: _id }
            }),
        )
        setLoading(false)
    }

    const handleFilterChange = (filters) => {
        setFilters(filters)
    }

    const handleDateChange = (dateFilters) => {
        setDateFilters(dateFilters)
        if (dateFilters.fromDate && dateFilters.toDate) {
            setFilters({ ...filters, ...dateFilters })
        } else {
            const { fromDate, toDate, ...filtersApartFromDate } = filters
            if (fromDate || toDate) {
                setFilters(filtersApartFromDate)
            }
        }
    }

    const add = async () => {
        setLoading(true)
        try {
            const res = await cloneData(filters)

            res.itemsAdded
                ? toast.success(`Added  ${res.itemsAdded} Requests`, {
                      position: 'bottom-center',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                  })
                : toast.warn(res.message, {
                      position: 'bottom-center',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                  })
        } catch (e) {
            console.log(e)
            toast.error('Something went wrong. Please try again', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        getFacilities()
    }, [])

    return (
        <div className="clone-page">
            <div className="filters">
                <div>
                    <div className="caption">Start Date</div>
                    <DatePicker
                        selected={dateFilters.fromDate}
                        onChange={(date) => {
                            handleDateChange({ ...dateFilters, fromDate: date && date.getTime() })
                        }}
                        selectsStart
                        startDate={dateFilters.fromDate}
                        endDate={dateFilters.toDate}
                        maxDate={new Date()}
                        customInput={<Input />}
                    />
                </div>
                <div>
                    <div className="caption">End Date</div>
                    <DatePicker
                        selected={dateFilters.toDate}
                        onChange={(date) => {
                            handleDateChange({
                                ...dateFilters,
                                toDate: date && date.getTime() + 23 * 3600 * 1000 + 59 * 60 * 1000 + 59 * 1000,
                            })
                        }}
                        selectsEnd
                        startDate={dateFilters.fromDate}
                        endDate={dateFilters.toDate}
                        maxDate={new Date()}
                        customInput={<Input />}
                    />
                </div>

                <div className="facility-dropdown">
                    <div className="caption">Facility</div>
                    <Dropdown
                        fluid
                        selection
                        options={facilities}
                        defaultValue={filters.facility}
                        onChange={(event, data) => {
                            handleFilterChange({ ...filters, facility: data.value })
                        }}
                    />
                </div>
                <div className="button-container">
                    <Button className="tiny primary" onClick={add}>
                        Clone
                    </Button>
                </div>
            </div>
            <div className="realtive">
                <Loader active={loading}></Loader>
            </div>
        </div>
    )
}

export default withRouter(CloneData)
