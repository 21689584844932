import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { distanceInWordsToNow, format } from 'date-fns'

import { Image, Button, Icon, Dimmer, Loader } from 'semantic-ui-react'

import { fetchOneRequest, closeRequest, setRequestSentiment, assignRequest } from '../../../../services/Requests'
import { Request, User } from '../../../../types'

import './style.less'
import AssignButton from '../../../../components/AssignButton'
import { fetchStaffUsers } from '../../../../services/Users'

interface RouteInfo {
    id: string
}
interface Props extends RouteComponentProps<RouteInfo> {}

interface State {
    isFetching: boolean
    isFetchingUsers: boolean
    hasError: boolean
    error: string | null
    request: Request | null
    users: User[]
}

class SingleRequest extends React.Component<Props, State> {
    id: string
    constructor(props: Props) {
        super(props)
        this.id = props.match.params.id
        this.state = {
            isFetching: false,
            isFetchingUsers: false,
            hasError: false,
            error: null,
            request: null,
            users: [],
        }
    }

    componentDidMount() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        fetchOneRequest(this.id).then(res => {
            this.setState({
                isFetching: false,
                request: res,
                hasError: res ? false : true,
                error: res ? null : `Could not not find request ${this.id}`,
            })
            this.fetchUsers(res && res.Department)
        })
    }

    goHome() {
        this.props.history.push('/')
    }

    formatAcceptedTime(time: Date | undefined) {
        if (time) {
            if (new Date(time).getFullYear() > new Date().getFullYear()) {
                return format(new Date(time), 'MMM DD, YYYY, hh:mm a')
            }
            return format(new Date(time), 'MMM DD, hh:mm a')
        }
        return undefined
    }

    fetchUsers(departmentId: string | null) {
        this.setState({
            isFetchingUsers: true,
            hasError: false,
            error: null,
        })
        fetchStaffUsers().then(res => {
            this.setState({
                isFetchingUsers: false,
                users: res.filter(user => {
                    if (!departmentId) return true
                    const departmentIds = user.Departments && user.Departments.map(e => e._id)
                    return departmentIds.includes(departmentId)
                }),
            })
        })
    }

    async handleAssign(user: User | null) {
        if (!user) return
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })

        try {
            const newRequest = await assignRequest(this.id, user._id)

            this.setState({
                isFetching: false,
                hasError: false,
                error: null,
                request: newRequest,
            })
            this.goHome()
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    async handleClose() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        try {
            const newRequest = await closeRequest(this.id)
            this.setState({
                request: newRequest,
                isFetching: false,
                hasError: false,
            })
            this.goHome()
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    async setSentiment(sentiment: number) {
        if (!this.state.request) return
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
            request: {
                ...this.state.request,
                Sentiment: sentiment,
            },
        })
        try {
            const newRequest = await setRequestSentiment(this.id, sentiment)
            this.setState({
                request: newRequest,
                isFetching: false,
                hasError: false,
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    renderFaces(request: Request) {
        const isAccepted = request.Status === 'Accepted'
        const isClosed = request.Status === 'Closed'
        const faces = [
            <Icon
                key={1}
                className="face"
                onClick={() => this.setSentiment(1)}
                name="frown outline"
                color="red"
                size={request.Sentiment ? (request.Sentiment === 1 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={2}
                className="face"
                onClick={() => this.setSentiment(2)}
                name="frown outline"
                color="orange"
                size={request.Sentiment ? (request.Sentiment === 2 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={3}
                className="face"
                onClick={() => this.setSentiment(3)}
                name="meh outline"
                color="yellow"
                size={request.Sentiment ? (request.Sentiment === 3 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={4}
                className="face"
                onClick={() => this.setSentiment(4)}
                name="smile outline"
                color="olive"
                size={request.Sentiment ? (request.Sentiment === 4 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={5}
                className="face"
                onClick={() => this.setSentiment(5)}
                name="smile outline"
                color="green"
                size={request.Sentiment ? (request.Sentiment === 5 ? 'huge' : 'big') : 'big'}
            />,
        ]
        const faceButtons = <div className="faces">{faces}</div>
        if (isAccepted && !request.Sentiment) return faceButtons
        if ((isAccepted || isClosed) && request.Sentiment) return faceButtons
        return null
    }

    renderRequest(request: Request) {
        const isOpen = request.Status === 'Open'
        const isClosed = request.Status === 'Closed'

        const closeBtn = (
            <Button
                basic
                disabled={isClosed || this.state.isFetching}
                loading={this.state.isFetching}
                primary
                icon="play"
                content="Close"
                id="actionButton"
                onClick={this.handleClose.bind(this)}
            />
        )

        const avatarUrl = request.Registrant_Image || `${process.env.PUBLIC_URL}/avatar_placeholder.png`
        const reqTypeIcon = request.Department_Icon || `${process.env.PUBLIC_URL}/request_type_placeholder.png`
        console.log(request)

        return (
            <div className="request-view">
                <div className="header">
                    <div className="image-container">
                        <Image circular size="tiny" src={reqTypeIcon} />
                    </div>
                    <div className="short-details">
                        <div className="req-info-text">
                            {request.Registrant_FirstName + ' ' + request.Registrant_LastName}
                        </div>
                        <div className="req-info-text">Room {request.Unit && request.Unit_Name}</div>
                        <div className="req-info-text">
                            Created at {this.formatAcceptedTime(request.RequestedTime)}, <br />{' '}
                            {distanceInWordsToNow(request.RequestedTime)} ago
                        </div>
                        <div className="req-info-text">{request.Name}</div>
                    </div>
                    <div className="image-container">
                        <Image circular size="tiny" src={avatarUrl} />
                    </div>
                </div>
                <div className="req-info-text">{request.Details}</div>
                {request.AcceptedTime && (
                    <div className="req-info-text">
                        <h3 className="primary">Accepted by: {request.AcceptedByName || ''}</h3>
                        <h3 className="primary">Time Accepted: {this.formatAcceptedTime(request.AcceptedTime)}</h3>
                        {request.ClosedTime && (
                            <h3 className="primary">Time Closed: {this.formatAcceptedTime(request.ClosedTime)}</h3>
                        )}
                    </div>
                )}
                <AssignButton
                    users={this.state.users}
                    loading={this.state.isFetchingUsers || this.state.isFetching}
                    onSelectionChange={this.handleAssign.bind(this)}
                    disabled={!isOpen}
                />
                {closeBtn}
                {this.renderFaces(request)}
                {(this.state.hasError && <div className="error">{this.state.error}</div>) || null}
            </div>
        )
    }

    renderLoadingOrError() {
        const { isFetching, hasError, error } = this.state
        if (isFetching) {
            return (
                <Dimmer active={isFetching} inverted>
                    <Loader active={isFetching} />
                </Dimmer>
            )
        }

        if (hasError && error) {
            return <div className="error">{error}</div>
        }
    }

    render() {
        const { request } = this.state
        return (
            <div className="SingleRequestAdmin">
                {request ? this.renderRequest(request) : this.renderLoadingOrError()}
            </div>
        )
    }
}

export default withRouter(SingleRequest)
