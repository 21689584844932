import API from './API'
import { Department, Identifiable } from '../types'

export async function fetchDepartments(): Promise<Department[]> {
    const res = await API.lambdaGet('/departments/list')
    const departments = res.Result as Department[]
    return departments.filter(u => u.IsActive === 1)
}

export async function fetchOneDepartment(id: string): Promise<Department> {
    const res = await API.lambdaGet(`/departments/get?_id=${id}`)
    return res as Department
}

export async function createDepartment(department: Partial<Department>): Promise<Department> {
    const id = await API.lambdaPost('/departments/add', department)
    const created = await fetchOneDepartment(id)
    return created
}

export async function updateDepartment(department: Partial<Department> & Identifiable): Promise<Department> {
    if (!department._id) throw new Error('Updated Department object must contain _id field')
    await API.lambdaPost('/departments/update', department)
    const updated = await fetchOneDepartment(department._id)
    return updated
}

export async function deleteDepartment(departmentId: String): Promise<any> {
    await API.lambdaPost('/departments/update', {
        _id: departmentId,
        IsActive: 0,
    })
}
