import React, { Component } from 'react'
import { Checkbox } from 'semantic-ui-react'

import './style.less'
import { Identifiable } from '../../types'

type ImplicitIdentifiable = any & Identifiable

interface Props {
    data: ImplicitIdentifiable[]
    labelFields: string[]
    disabled?: boolean
    onSelectionChange?: (u: ImplicitIdentifiable[]) => void
    selected?: Identifiable[]
}

interface Item {
    data: Identifiable
    selected: boolean
}

interface State {
    items: Item[]
}

class ToggleList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const isSelected = (item: Identifiable) => {
            return this.props.selected && this.props.selected.some(el => el._id === item._id)
        }
        this.state = {
            items: this.props.data.map(item => ({
                data: item,
                selected: isSelected(item) || false,
            })),
        }
    }

    joinLabelFields(item: any) {
        return this.props.labelFields.map(label => item[label]).join(' ')
    }

    toggleItem(item: Item) {
        const index = this.state.items.findIndex(i => i.data._id === item.data._id)
        const oldValue = this.state.items.find(i => i.data._id === item.data._id)
        if (index === -1 || !oldValue) return
        const newState = this.state.items
        newState[index] = { data: item.data, selected: !item.selected }
        this.setState(
            {
                items: newState,
            },
            this.handleChange.bind(this),
        )
    }

    handleChange() {
        if (!this.props.onSelectionChange) return
        const selected = this.state.items.filter(i => i.selected === true).map(i => i.data)
        this.props.onSelectionChange(selected)
    }

    render() {
        const toggleList = this.state.items.map(d => {
            const name = this.joinLabelFields(d.data)
            return (
                <div className="toggle" key={d.data._id} style={{ marginBottom: '10px' }}>
                    <Checkbox
                        toggle
                        label={name}
                        checked={d.selected}
                        onChange={() => this.toggleItem(d)}
                        disabled={this.props.disabled}
                    />
                </div>
            )
        })
        return <div className="ToogleList">{toggleList}</div>
    }
}

export default ToggleList
