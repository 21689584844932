import React, { useState, useEffect } from 'react'
import { Tab } from 'semantic-ui-react'
// import Reports from './Reports'
import './style.less'
import { withRouter } from 'react-router'
import Requests from '../../../components/Dashboard/Requests'
import Basics from '../../../components/Dashboard/Basics'

const Home = props => {
    console.log('Home props', props)
    const panes = [
        {
            menuItem: 'Requests',
            render: () => (
                <Tab.Pane attached={false}>
                    <Requests profile={props.profile} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Standard',
            render: () => (
                <Tab.Pane attached={false}>
                    <Basics />
                </Tab.Pane>
            ),
        },
    ]

    return (
        <div className="DashboardHome">
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
    )
}

export default withRouter(Home)
