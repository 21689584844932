import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { Dropdown } from 'semantic-ui-react'
import './style.less'

interface Props {
    text: string
    color: string
    selectedValue: string
    options: { value: string; label: string }[]
    onClick: (value: string) => void
}

const RadialDropdown: React.FC<Props> = (props: Props) => {
    const items = props.options.map(item => {
        return {
            key: item.value,
            value: item.value,
            text: item.label,
        }
    })
    return (
        <div className="radial-progress">
            <CircularProgressbarWithChildren
                value={0}
                styles={{
                    trail: {
                        strokeWidth: 4,
                        stroke: props.color,
                    },
                    text: {
                        textAnchor: 'middle',
                        alignmentBaseline: 'middle',
                    },
                }}
            >
                <div className="radial-dropdown">
                    <Dropdown
                        fluid
                        selection
                        options={items}
                        value={props.selectedValue}
                        onChange={(event, data) => {
                            props.onClick(`${data.value}`)
                        }}
                    />
                </div>
            </CircularProgressbarWithChildren>
            <div className="text-bottom">{props.text}</div>
        </div>
    )
}

export default RadialDropdown
