import React from 'react'
import { Item } from 'semantic-ui-react'
import ListItemElement from '../ListItemElement'

import './style.less'
import ActivityForm from '../ActivityForm'
import MenuFormEditor from '../MenuFormEditor'

interface Props {
    listItems: any[]
    isFetching: boolean
    isSaving: boolean
    sourcePage: string
    aciveFoodTab?: string
    deleteListItem: (id) => any
    editListItem: (id) => any
    copyListItem: (id) => any | undefined
    handleSubmit: (activity) => any
    handleUpdate: (activity) => any
    cancelEditListItem: (id) => any
}

const ItemsList: React.FC<Props> = (props: Props) => {
    const itemsList = props.listItems.map((listItem: any) => {
        if (listItem.edit) {
            return props.sourcePage === 'DailyActivities' ? (
                <ActivityForm
                    key={listItem._id}
                    isFetching={props.isFetching}
                    editableActivity={listItem}
                    isSaving={props.isSaving}
                    handleSubmit={props.handleSubmit}
                    handleUpdate={props.handleUpdate}
                    cancelEditActivity={props.cancelEditListItem}
                    edit={true}
                    _id={listItem._id}
                />
            ) : (
                <MenuFormEditor
                    key={listItem._id}
                    isFetching={props.isFetching}
                    editableMenu={listItem}
                    isSaving={props.isSaving}
                    handleSubmit={props.handleSubmit}
                    handleUpdate={props.handleUpdate}
                    cancelEditMenu={props.cancelEditListItem}
                    edit={true}
                    _id={listItem._id}
                    aciveFoodTab={props.aciveFoodTab}
                />
            )
        }
        return (
            <ListItemElement
                key={listItem._id}
                id={listItem._id}
                text={listItem.text}
                time={listItem.timestamp}
                sourcePage={props.sourcePage}
                deleteListItem={props.deleteListItem}
                editListItem={props.editListItem}
                copyListItem={props.copyListItem}
            />
        )
    })

    return <Item.Group relaxed>{itemsList}</Item.Group>
}

export default ItemsList
