import React, { useState, useEffect } from 'react'
import { useAdminPanelRequests } from '../../hooks/adminPanel'
import { Tab } from 'semantic-ui-react'
import RequestList from '../../pages/AdminPanel/Home/RequestList'
// import Reports from './Reports'
import { ReportSummaryFilters } from '../../types'
import './style.less'
import { withRouter } from 'react-router'
//import Timeline from './Timeline'
import ReportSummary from './ReportSummary'

const Requests = props => {
    const [filters, setFilters] = useState<ReportSummaryFilters>({
        requestType: 'active',
        fromDate: new Date(),
        toDate: new Date(),
        staff: 'all',
        resident: 'all',
        department: 'all',
        reload: 0,
    })
    const [sortBy, setSortBy] = useState([{ RequestedTime: 'asc' }])
    const [pageNo, setPageNo] = useState(1)

    const { requests, error, isFetching, isPaginating, hasMorePages } = useAdminPanelRequests(
        filters,
        sortBy,
        true,
        false,
        pageNo,
        40,
        props.history,
    )

    const panes = [
        {
            menuItem: 'In Progress',
            render: () => (
                <Tab.Pane attached={false}>
                    <RequestList
                        requests={requests}
                        isFetching={isFetching}
                        isPaginateLoading={isPaginating}
                        nextPage={() => {
                            setPageNo(pageNo + 1)
                        }}
                        hasMorePage={hasMorePages}
                    />
                </Tab.Pane>
            ),
        },
    ]

    return (
        <div className="requests">
            <ReportSummary
                filters={filters}
                handleFilterChange={updatedFilters => {
                    setFilters(updatedFilters)
                    setPageNo(1)
                }}
                shouldCreateRequestBeShown={true}
                requests={requests}
                history={props.history}
                profile={props.profile}
            />
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
    )
}

export default withRouter(Requests)
