import { API as awsAPI } from 'aws-amplify'
import axios from 'axios'

type Headers = { [key: string]: string }

class APIService {
    private makeHeaders(override: Headers = {}): Headers {
        const defaultHeaders = { 'Content-Type': 'application/json' }
        return {
            ...defaultHeaders,
            ...override,
        }
    }

    public async lambdaGet(path: string, query?: Object) {
        try {
            let params = {}
            if (query) {
                params = { ...params, queryStringParameters: query }
            }

            const res = await awsAPI.get('lambda', path, params)
            if (!res.Success) throw new Error(res.Error)
            return res.Content
        } catch (e) {
            throw e
        }
    }

    public async lambdaPost(path: string, body: object) {
        const headers = this.makeHeaders()
        try {
            const res = await awsAPI.post('lambda', path, {
                headers,
                body,
            })
            if (!res.Success) throw new Error(res.Error)

            return res.Content
        } catch (e) {

            throw e
        }
    }

    public async lambdaPut(path: string, body: object) {
        const headers = this.makeHeaders()
        try {
            const res = await awsAPI.put('lambda', path, {
                headers,
                body,
            })
            if (!res.Success) throw new Error(res.Error)

            return res.Content
        } catch (e) {
            throw e
        }
    }

    public async putFile(url: string, file: File, onProgress?: (percent: number) => any): Promise<any> {
        const res = await axios.put(url, file, {
            headers: {
                'Content-Type': file.type,
            },
            onUploadProgress: progressEvent => {
                if (onProgress) {
                    const percent = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
                    onProgress(parseFloat(percent))
                }
            },
        })

        return res
    }

    public async lambdaDeleteById(path: string, id) {

        let params = {}
        
        if (id) {
            params = {queryStringParameters: id }
        }

        try {
            const response = await awsAPI.del('lambda', path, params);
            if (!response.Success) throw new Error(response.Error);
            return response.Content;
        } catch (error) {
            throw error;
        }
    }
}

export default new APIService()
