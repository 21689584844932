import React from 'react'
import FilePicker from './FilePicker'
import { uploadFile } from '../../services/ImageUpload'

interface Props {
    accept?: string[]
    folderName: string
    onUploaded: (fileUrl: string) => any
    onSelected?: (file: File) => any
    onUploadError?: (error: any) => any
    onUploadProgress?: (progress: number) => any
}

interface State {
    percentage: number
    isUploading: boolean
    finished: boolean
    error: string | null
}

class S3FileUpload extends React.Component<Props, State> {
    state: State = {
        percentage: 0,
        isUploading: false,
        finished: false,
        error: null,
    }

    handleSelect = async (files: File[]) => {
        this.setState({ isUploading: true, error: null, finished: false, percentage: 0 })
        // TODO: support multiple
        const file = files[0]
        if (this.props.onSelected) this.props.onSelected(file)
        try {
            const res = await uploadFile(this.props.folderName, file, this.handleProgress)
            this.setState({ finished: true, percentage: 100, isUploading: false })
            this.props.onUploaded(res)
        } catch (e) {
            this.setState({ finished: true, percentage: 0, isUploading: false, error: e.message })
            if (this.props.onUploadError) this.props.onUploadError(e)
        }
    }

    handleProgress = (progress: number) => {
        this.setState({ percentage: progress })
        if (this.props.onUploadProgress) this.props.onUploadProgress(progress)
    }

    render() {
        return (
            <div className="S3FileUpload">
                <FilePicker onSelected={this.handleSelect} multiple={false} {...this.state} accept={this.props.accept} />
            </div>
        )
    }
}

export default S3FileUpload
