import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import thunk from 'redux-thunk'
import { createStore, AnyAction, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import Amplify from 'aws-amplify'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { rootReducer, AppState } from './reducers'
import config from './config'
import { initializeFirebase } from './services/PushNotifications'

declare var process: {
    env: {
        SERVER_ENV: string
    }
}

const store = createStore<AppState, AnyAction, any, any>(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

declare global {
    interface Window {
        Speak2Namespace: {
            notificationsEnabled: boolean
            pushSubscription: PushSubscription | null
        }
    }
}
window.Speak2Namespace = {
    notificationsEnabled: false,
    pushSubscription: null,
}
// Cognito setup

const envConfig = config[process.env.SERVER_ENV] || config.development

Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: envConfig.identityPoolId,
        // REQUIRED - Amazon Cognito Region
        region: envConfig.region,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: envConfig.userPoolId,
        userPoolWebClientId: envConfig.appClientId,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    API: {
        endpoints: [
            {
                name: 'lambda',
                endpoint: envConfig.lambdaEndpoint,
            },
        ],
    },
})

initializeFirebase(envConfig.firebaseConfig)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
)
