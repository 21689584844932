import React, { ChangeEvent } from 'react'
import { Form, Button } from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import './style.less'
import { RequestType, Unit, Request, User } from '../../../../types'
import { Registrant } from '../../../../types'
import { fetchRegistrants } from '../../../../services/Registrants'
import { fetchUnits } from '../../../../services/Units'
import { fetchRequestTypes } from '../../../../services/RequestTypes'
import DataSourceDropdown from '../../../../components/DataSourceDropdown'
import AssignButton from '../../../../components/AssignButton'
import { fetchActiveUsers } from '../../../../services/Users'
import { createRequest, assignRequest } from '../../../../services/Requests'

interface State {
    requestTypes: RequestType[]
    units: Unit[]
    registrants: Registrant[]
    isFetching: boolean
    hasError: boolean
    error: string | null
    requestData: Partial<Request>
}
class CreateRequest extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            registrants: [],
            requestTypes: [],
            units: [],
            isFetching: false,
            hasError: false,
            error: null,
            requestData: {},
        }
    }

    get isValidRequest() {
        return this.state.requestData.Registrant &&
            // this.state.requestData.Unit &&
            this.state.requestData.RequestType
            ? true
            : false
    }

    async componentDidMount() {
        try {
            this.setState({
                isFetching: true,
                hasError: false,
                error: null,
            })
            const [registrants, units, requestTypes] = await Promise.all([
                fetchRegistrants(),
                fetchUnits(),
                fetchRequestTypes(),
                fetchActiveUsers(),
            ])
            this.setState({
                isFetching: false,
                hasError: false,
                error: null,
                units,
                registrants,
                requestTypes,
            })
        } catch (e) {
            const msg = e.message || 'Unknown Server Error'
            this.setState({
                isFetching: false,
                hasError: true,
                error: msg,
            })
        }
    }

    goHome() {
        this.props.history.push('/')
    }

    getDataList(id: string, source: any[], labelField: string) {
        return (
            <datalist id={id}>
                {source.map(item => (
                    <option value={item._id} label={item[labelField]}>
                        {item[labelField]}
                    </option>
                ))}
            </datalist>
        )
    }

    setRequestDataField(key: keyof Request, value: any) {
        this.setState({
            requestData: {
                ...this.state.requestData,
                [key]: value,
            },
        })
    }

    async handleAssign() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        try {
            const created = await createRequest(this.state.requestData)
            this.setState({
                isFetching: false,
                hasError: false,
                error: null,
            })
            this.goHome()
        } catch (e) {
            const msg = e.message || 'Could not create request due to server error.'
            this.setState({
                isFetching: false,
                hasError: true,
                error: msg,
            })
        }
    }

    render() {
        return (
            <div className="CreateRequest">
                <Form loading={this.state.isFetching}>
                    <Form.Field>
                        <label>Resident</label>
                        <DataSourceDropdown
                            placeholder="Resident"
                            data={this.state.registrants}
                            labelFields={['FirstName', 'LastName']}
                            imageField="Image"
                            onSelectionChange={(reg: Registrant) =>
                                reg && reg._id ? this.setRequestDataField('Registrant', reg._id) : null
                            }
                        />
                    </Form.Field>
                    {/* <Form.Field>
                        <label>Room</label>
                        <DataSourceDropdown
                            placeholder="Room"
                            data={this.state.units}
                            labelFields={['Name']}
                            onSelectionChange={(u: Unit) =>
                                u && u._id ? this.setRequestDataField('Unit', u._id) : null
                            }
                        />
                    </Form.Field> */}
                    <Form.Field>
                        <label>Request Type</label>
                        <DataSourceDropdown
                            placeholder="Request Type"
                            data={this.state.requestTypes}
                            labelFields={['Name']}
                            onSelectionChange={(r: RequestType) =>
                                r && r._id ? this.setRequestDataField('RequestType', r._id) : null
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Request Name</label>
                        <input
                            placeholder="Request Name"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                this.setRequestDataField('Name', e.currentTarget.value)
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Notes</label>
                        <input
                            placeholder="Notes"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                this.setRequestDataField('Details', e.currentTarget.value)
                            }
                        />
                    </Form.Field>
                    <Button
                        basic
                        disabled={!this.isValidRequest}
                        primary
                        icon="play"
                        content="Create"
                        id="createRequest"
                        onClick={this.handleAssign.bind(this)}
                    />
                    {/* <AssignButton
                        users={this.state.staff}
                        onSelectionChange={this.handleAssign.bind(this)}
                        disabled={!this.isValidRequest}
                    /> */}
                </Form>
            </div>
        )
    }
}

export default withRouter(CreateRequest)
