import React, { FunctionComponent } from 'react';
import { Field as FormikField } from 'formik'
import { Button, Icon } from 'semantic-ui-react';

interface Props {
    GenericTextField: FunctionComponent<any>;
} 

const RouteComponentProps: React.FC<Props> = (props: Props) => {
    return (   
        <div className="reset-password-holder">
            <p className="code-sent">A verification code was sent to a user's email.</p>
            <FormikField
                name="VerificationCode"
                type="text"
                component={props.GenericTextField}
                placeholder="Verification Code"
            />
            <FormikField
                name="PasswordReset"
                type="password"
                component={props.GenericTextField}
                placeholder="Reset Password"
            />
            <FormikField
                name="ConfirmPasswordReset"
                type="password"
                component={props.GenericTextField}
                placeholder="Confirm Password"
            />
        </div>
    );
};

export default RouteComponentProps;