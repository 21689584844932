import React, {useState} from 'react'

import './style.less'
import { Button, Dropdown } from 'semantic-ui-react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

interface Props {
    invite: string
    error: string
    success: string
    isSubmitting: boolean
    manager: null | {
        firstName: string
        lastName: string
        contact: string
    }
    onInputChange: (stateProp, value) => any
    submitInvite: (e) => any
}

const FamilyInvite: React.FC<Props> = props => {
    const [ invitationMode, setInvitationMode ]= useState<any>("email");
    return (
        <div className="family-invite">
            <p>Speak2 Family</p>
            {props.manager ? (
                <>
                    <p className="relative-name">Relationship Manager</p>
                    <div className="relative-info">
                        <div className="relative-image-container">
                            <div className="relative-avatar">
                                {props.manager.firstName.charAt(0)} {props.manager.lastName.charAt(0)}
                            </div>
                        </div>
                        <div className="relative-name-container">
                            <p className="relative-name">{`${props.manager.firstName} ${props.manager.lastName}`}</p>
                            <p className="relative-name disabled">{props.manager.contact}</p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h3>Enter email or cell number to invite the person to manage this Resident</h3>
                    <div className="invite-container">
                        <Dropdown
                            style={{ width: '60px' }}
                            defaultValue={'email'}
                            options={[
                                { key: 'email', value: 'email', text: 'Email' },
                                { key: 'sms', value: 'sms', text: 'SMS' },
                            ]}
                            onChange={(event, { value = 'email' }) => {
                                setInvitationMode(value)
                                console.log(value)
                            }}
                        />
                        <div className="flex-1 ml-8">
                            {invitationMode === 'email' ? (
                                <input
                                    type="text"
                                    placeholder="email"
                                    value={props.invite}
                                    onChange={e => props.onInputChange('invite', e.currentTarget.value)}
                                />
                            ) : (
                                <PhoneInput
                                    defaultCountry="US"
                                    value={props.invite}
                                    onChange={phone => {
                                        console.log(phone)
                                        props.onInputChange('invite', phone)
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <Button loading={props.isSubmitting} onClick={props.submitInvite}>
                        Invite
                    </Button>
                    {props.success ? <div className="invite-message">{props.success}</div> : null}
                    {props.error ? <div className="invite-warning">{props.error}</div> : null}
                </>
            )}
        </div>
    )
}

export default FamilyInvite
