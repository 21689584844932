import React from 'react'
import { Form, Message } from 'semantic-ui-react'
import { FieldProps } from 'formik'
import MaskedInput from 'react-text-mask'

interface CustomProps {
    required?: boolean
    placeholder: string
    type?: 'email' | 'password' | 'text' | 'number'
    mask?: boolean | (string | RegExp)[]
}

const GenericTextField: React.FC<any> = (props: FieldProps<any> & CustomProps) => {
    const errMsg = (
        <Message error>
            <p>{props.form.errors[props.field.name]}</p>
        </Message>
    )
    const hasError = props.form.errors[props.field.name] && props.form.touched[props.field.name] ? true : false
    return (
        <Form.Field required={props.required || false} error={hasError}>
            <label>{props.placeholder}</label>
            <Form.Input
                type={props.type || 'text'}
                name={props.field.name}
                {...props.field}
                {...props}
                children={
                    props.mask ? (
                        <MaskedInput
                            {...props.field}
                            mask={props.mask}
                            type={props.type}
                            placeholder={props.placeholder}
                        />
                    ) : (
                        undefined
                    )
                }
            />
            {hasError && errMsg}
        </Form.Field>
    )
}

export default GenericTextField
