import API from './API';

import { DailyActivity } from '../types';

export async function newActivity(dailyActivity: DailyActivity): Promise<void> {
    const response = API.lambdaPost('/dailyactivities/add', dailyActivity);
    return response;
}

export async function Activities(): Promise<any> {
    const response = API.lambdaGet('/dailyactivities/list');
    return response;
}

export async function deleteActivity(_id): Promise<any> {
    const queryStringParam = { _id };
    const response = API.lambdaDeleteById('/dailyactivities/delete', queryStringParam);
    return response;
}

export async function updateActivity(dailyActivity: DailyActivity): Promise<void> {
    const response = API.lambdaPut('/dailyactivities/update', dailyActivity);
    return response;
}