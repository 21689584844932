import React, { useState, useEffect } from 'react'
import { useAdminPanelRequests } from '../../hooks/basicRequestPanel'
import { Tab } from 'semantic-ui-react'
import BasicRequestList from './BasicRequestList'
import { BasicReportSummaryFilters } from '../../types'
import './style.less'
import { withRouter } from 'react-router'
import ReportSummaryBasics from './ReportSummaryBasics'

const Basics = props => {
    const [filters, setFilters] = useState<BasicReportSummaryFilters>({
        fromDate: new Date(),
        toDate: new Date(),
        resident: 'all',
        food: 'all',
        type: 'all',
    })
    const [sortBy, setSortBy] = useState([{ RequestedTime: 'asc' }])
    const [pageNo, setPageNo] = useState(1)

    const { requests, error, isFetching, isPaginating, hasMorePages } = useAdminPanelRequests(
        filters,
        sortBy,
        true,
        false,
        pageNo,
        40,
        props.history,
    )

    const panes = [
        {
            menuItem: 'In Progress',
            render: () => (
                <Tab.Pane attached={false}>
                    <BasicRequestList
                        requests={requests}
                        isFetching={isFetching}
                        isPaginateLoading={isPaginating}
                        nextPage={() => {
                            setPageNo(pageNo + 1)
                        }}
                        hasMorePage={hasMorePages}
                    />
                </Tab.Pane>
            ),
        },
    ]

    return (
        <div className="basics">
            <ReportSummaryBasics
                filters={filters}
                handleFilterChange={updatedFilters => {
                    setFilters(updatedFilters)
                    setPageNo(1)
                }}
                history={props.history}
            />
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
    )
}

export default withRouter(Basics)
