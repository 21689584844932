import {
    RequestsAction,
    UnassignedRequestsAction,
    FETCH_REQUESTS,
    SET_FETCHING,
    SET_PAGINATE_LOADING,
    SET_REQUESTS,
    SET_PAGINATED_REQUESTS,
    SET_BG_REQUESTS,
    SET_ERROR,
    UNASSIGNED_REQUESTS_FETCH_REQUESTS,
    UNASSIGNED_REQUESTS_SET_FETCHING,
    UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING,
    UNASSIGNED_REQUESTS_SET_REQUESTS,
    UNASSIGNED_REQUESTS_SET_BG_REQUESTS,
    UNASSIGNED_REQUESTS_SET_ERROR,
} from '../actions/requests'
import { RequestsState, Request } from '../types'

const initialState: RequestsState = {
    count: 0,
    page: 0,
    limit: 50,
    hasMorePage: true,
    requests: [],
    hasError: false,
    errorCode: null,
    fetching: false,
    paginateLoading: false,
    unassignedRequestCount: 0,
    unassignedRequestPage: 0,
    unassignedRequestLimit: 50,
    unassignedRequests: [],
    unassignedRequestHasError: false,
    unassignedRequestErrorCode: null,
    unassignedRequestFetching: false,
    unassignedPaginateLoading: false,
}

export function requestsReducer(
    state = initialState,
    action: RequestsAction | UnassignedRequestsAction,
): RequestsState {
    switch (action.type) {
        case FETCH_REQUESTS:
            return state
        case SET_FETCHING:
            return {
                ...state,
                fetching: action.isFetching,
            }
        case SET_PAGINATE_LOADING:
            return {
                ...state,
                paginateLoading: action.isPaginateLoading,
            }
        case SET_REQUESTS:
            return {
                ...state,
                count: action.response.count,
                limit: action.response.limit,
                page: action.response.page,
                hasMorePage: action.response.page < Math.ceil(action.response.count / action.response.limit),
                requests: action.response.requests,
            }
        case SET_BG_REQUESTS:
            const requestsIds = state.requests.map((request: Request) => {
                return request._id
            })

            const newRequests: Request[] = []
            action.response.requests.forEach(request => {
                if (!requestsIds.includes(request._id)) {
                    newRequests.push(request)
                }
            })

            return {
                ...state,
                count: action.response.count,
                requests: [...newRequests, ...state.requests],
            }
        case SET_PAGINATED_REQUESTS:
            return {
                ...state,
                count: action.response.count,
                limit: action.response.limit,
                page: action.response.page,
                hasMorePage: action.response.page < Math.ceil(action.response.count / action.response.limit),
                requests: [...state.requests, ...action.response.requests],
            }
        case SET_ERROR:
            return {
                ...state,
                hasError: action.hasError,
                errorCode: action.errorCode,
            }
        case UNASSIGNED_REQUESTS_FETCH_REQUESTS:
            return state
        case UNASSIGNED_REQUESTS_SET_FETCHING:
            return {
                ...state,
                unassignedRequestFetching: action.isFetching,
            }
        case UNASSIGNED_REQUESTS_SET_PAGINATE_LOADING:
            return {
                ...state,
                unassignedPaginateLoading: action.isPaginateLoading,
            }
        case UNASSIGNED_REQUESTS_SET_REQUESTS:
            return {
                ...state,
                unassignedRequestCount: action.response.count,
                unassignedRequestLimit: action.response.limit,
                unassignedRequestPage: action.response.page,
                unassignedRequests: action.response.requests,
            }
        case UNASSIGNED_REQUESTS_SET_BG_REQUESTS:
            const unassignedRequestsIds = (state.unassignedRequests || []).map((request: Request) => {
                return request._id
            })

            const newUnassignedRequests: Request[] = []
            action.response.requests.forEach(request => {
                if (!unassignedRequestsIds.includes(request._id)) {
                    newUnassignedRequests.push(request)
                }
            })

            return {
                ...state,
                unassignedRequestCount: action.response.count,
                hasMorePage:
                    action.response.page < Math.ceil(action.response.count / (state.unassignedRequestLimit || 1)),
                unassignedRequests: [...newUnassignedRequests, ...(state.unassignedRequests || [])],
            }
        case UNASSIGNED_REQUESTS_SET_ERROR:
            return {
                ...state,
                unassignedRequestHasError: action.hasError,
                unassignedRequestErrorCode: action.errorCode,
            }
        default:
            return state
    }
}
