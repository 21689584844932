import API from './API'
import config from '../config'

declare var process: {
    env: {
        SERVER_ENV: string
    }
}
console.log(process.env.SERVER_ENV)
const envConfig = config[process.env.SERVER_ENV] || config.development

export async function getPreSignedS3Url(
    folderName: string,
    file: File,
    randomizeFilename = true,
): Promise<{ putUrl: string; fileName: string }> {
    let name = file.name
    if (randomizeFilename) {
        const nameParts = file.name.split('.')
        nameParts[nameParts.length - 2] = nameParts[nameParts.length - 2] + `_${Date.now()}`
        name = nameParts.join('.')
    }
    const url = await API.lambdaPost('/image/upload', {
        Folder: folderName,
        FileName: name,
    })
    return {
        putUrl: url as string,
        fileName: name,
    }
}

function generateS3ImageUrl(folderName: string, fileName: string) {
    return `${envConfig.s3BucketUrl}/${folderName}/${fileName}`
}

export async function uploadFile(folderName: string, file: File, onProgress?: (percent: number) => any): Promise<any> {
    const { putUrl, fileName } = await getPreSignedS3Url(folderName, file, true)

    await API.putFile(putUrl, file, onProgress)
    const url = generateS3ImageUrl(folderName, fileName)
    return url
}
