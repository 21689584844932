import API from './API'
import { Facility, Identifiable } from '../types'

export async function fetchFacilities(): Promise<Facility[]> {
    const res = await API.lambdaGet('/facilities/list')
    res.Result = res.Result.filter((f: Facility) => f.IsActive === 1)
    return res.Result as Facility[]
}

export async function fetchOneFacility(id: string): Promise<Facility> {
    const res = await API.lambdaGet(`/facilities/get?_id=${id}`)
    return res as Facility
}

export async function createFacility(facility: Partial<Facility>): Promise<Facility> {
    const id = await API.lambdaPost('/facilities/add', facility)
    const created = await fetchOneFacility(id)
    return created
}

export async function updateFacility(facility: Partial<Facility> & Identifiable): Promise<Facility> {
    if (!facility._id) throw new Error('Updated facility object must contain _id field')
    await API.lambdaPost('/facilities/update', facility)
    const updated = await fetchOneFacility(facility._id)
    return updated
}

export async function deleteFacility(facilityId: String): Promise<any> {
    await API.lambdaPost('/facilities/update', {
        _id: facilityId,
        IsActive: 0,
    })
}
