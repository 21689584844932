import API from './API';

export async function sendSmsInvite(contactInfo) {
    const response = API.lambdaPost('/smsinvite/send', contactInfo);
    return response;
}

export async function sendEmailInvite(contactInfo) {
    const response = API.lambdaPost('/emailinvite/send', contactInfo);
    return response;
}