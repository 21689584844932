import React from 'react'
import UsersForm, { FormValues } from '../UsersForm'

import { withRouter, RouteComponentProps } from 'react-router'
import { Department, User, Facility, UserRole, UserProfile } from '../../../../types'
import { Dimmer, Loader } from 'semantic-ui-react'
import { fetchDepartments } from '../../../../services/Departments'
import { fetchOneUser, deleteUser, updateUser, fetchAssignableUserRoles } from '../../../../services/Users'
import { fetchFacilities } from '../../../../services/Facilities'
import { isSysAdmin } from '../../../../services/Permissions';
import { Auth } from 'aws-amplify'

interface State {
    isFetching: boolean
    departments: Department[]
    facilities: Facility[]
    roles: UserRole[]
    user: User | null
    isSaving: boolean
    error: string | null
    newPasswordInputFields: boolean;
    PasswordReset: string;
    ConfirmPasswordReset: string;
}

interface RouteInfo {
    id: string
}

interface Props extends RouteComponentProps<RouteInfo> {
    profile: UserProfile
}

class UserEdit extends React.Component<Props, State> {
    id: string
    constructor(props: Props) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            departments: [],
            user: null,
            isFetching: false,
            facilities: [],
            roles: [],
            newPasswordInputFields: false,
            PasswordReset: '',
            ConfirmPasswordReset: ''
        }

        this.id = props.match.params.id
    }

    async facilitiesPromise() {
        if (isSysAdmin(this.props.profile)) return fetchFacilities()
        return Promise.resolve([])
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const [user, departments, roles, facilities] = await Promise.all([
                fetchOneUser(this.id),
                fetchDepartments(),
                fetchAssignableUserRoles(),
                this.facilitiesPromise(),
            ])
            this.setState({ isFetching: false, user, departments, roles, facilities })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            if (!this.state.user) return
            if (this.state.newPasswordInputFields) {
                await this.forgotPasswordSubmit(data.VerificationCode, data.PasswordReset, data.ConfirmPasswordReset);
            }
            const updateData = { ...data };
            delete updateData.VerificationCode;
            delete updateData.PasswordReset;
            delete updateData.ConfirmPasswordReset;
            await updateUser({ _id: this.id, ...updateData }, this.state.user);
            this.setState({ isSaving: false });
            this.goBack();
        } catch (e) {
            this.setState({ isSaving: false, error: e.message });
        }
    }

    async handleDelete(id: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteUser(id)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    async startPaswordReset(e): Promise<void> {
        e.preventDefault();
        try {
            if (this.state.user) {
                await Auth.forgotPassword(this.state.user.Username);
                this.openNewPasswordInput();
            }
        } catch (error) {
            if (error.message) error = error.message;
            this.setState({
                isSaving: false,
                error: error,
            })
        }
    }

    onPasswordChange(stateProp: keyof State, value: string): void {
        this.setState({
            [stateProp]: value
        } as any, () => console.log(this.state[stateProp]));
    }

    async forgotPasswordSubmit(code, password, confirmPassword): Promise<void> {
        if (!code && !password && !confirmPassword) {
            return;
        }
        if (!this.state.user) throw new Error('User or username is not set.');
        await Auth.forgotPasswordSubmit(this.state.user.Username, code, password);
    }

    openNewPasswordInput(): void {
        this.setState({
            newPasswordInputFields: true
        });
    }

    render() {
        return (
            <div className="UserEdit">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.user && (
                    <UsersForm
                        title="Edit User"
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        startPaswordReset={this.startPaswordReset.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(UserEdit)
