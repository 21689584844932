import API from './API'

import { MenuType } from '../types'

export async function newMenu(menu: MenuType): Promise<any> {
    const response = API.lambdaPost('/menu/add', menu)
    return response
}

export async function fetchMenuItems(page_no = 1, page_size = 10, type, filters = {}): Promise<any> {
    const params = {
        Filter: { timestamp: {}, ...filters },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ timestamp: type === 'active' ? 'desc' : 'asc' }],
        },
    }

    if (type) {
        const date = new Date()
        if (type === 'active') {
            params.Filter.timestamp = {
                $gte: new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime(),
            }
        } else if (type === 'history') {
            params.Filter.timestamp = {
                $lt: new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime(),
            }
        }
    }

    const response = await API.lambdaPost('/menu/list', params)

    return response
}

export async function deleteMenu(_id: string): Promise<void> {
    const queryStringParam = { _id }
    const response = API.lambdaDeleteById('/menu/delete', queryStringParam)
    return response
}

export async function updateMenu(menu: MenuType): Promise<any> {
    const response = API.lambdaPut('/menu/update', menu)
    return response
}
