import React from 'react'
import RequestTypesForm, { FormValues } from '../RequestTypesForm'

import { withRouter, RouteComponentProps } from 'react-router'
import { Department, RequestType } from '../../../../types'
import { Dimmer, Loader } from 'semantic-ui-react'
import { fetchDepartments } from '../../../../services/Departments'
import { fetchOneRequestType, deleteRequestType, updateRequestType } from '../../../../services/RequestTypes'

interface State {
    isFetching: boolean
    departments: Department[]
    requestType: RequestType | null
    isSaving: boolean
    error: string | null
}

interface RouteInfo {
    id: string
}

class RequestTypeEdit extends React.Component<RouteComponentProps<RouteInfo>, State> {
    id: string
    constructor(props: RouteComponentProps<RouteInfo>) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            departments: [],
            requestType: null,
            isFetching: false,
        }

        this.id = props.match.params.id
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const [requestType, departments] = await Promise.all([fetchOneRequestType(this.id), fetchDepartments()])
            this.setState({ isFetching: false, requestType, departments })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            if (!data.Department || !data.EscalationDepartment)
                throw new Error('Must specify Department and Escalation Department')
            const postData = {
                ...data,
                _id: this.id,
                Department: data.Department._id,
                EscalationDepartment: data.EscalationDepartment._id,
                NotificationMethodA: data.NotificationMethodA && data.NotificationMethodA._id,
                NotificationMethodB: data.NotificationMethodB && data.NotificationMethodB._id,
            }
            await updateRequestType(postData)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    async handleDelete(id: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteRequestType(id)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    render() {
        return (
            <div className="EditRequestType">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.requestType && (
                    <RequestTypesForm
                        title="Edit Service Type"
                        departments={this.state.departments}
                        requestType={this.state.requestType}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(RequestTypeEdit)
