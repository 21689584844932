import { Auth } from 'aws-amplify'
import { CurrentUser, UserProfile } from '../types'
import API from './API'

function parseUserResponse(response: any): CurrentUser {
    if (!response.username) throw new Error('No username')
    const user: CurrentUser = {
        username: response.username,
        awsUserObject: response,
    }
    return user
}

interface LoginResponse {
    user: CurrentUser
    profile: UserProfile | null
    passwordChangeRequired: boolean
}

export async function logIn(username: string, password: string): Promise<LoginResponse> {
    try {
        const user = await Auth.signIn(username, password)
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            console.log(user.challengeName)
            throw new Error('UnsupportedChallenge')
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            console.log('password change required')
            const parsedUser = parseUserResponse(user)
            return {
                user: parsedUser,
                profile: null,
                passwordChangeRequired: true,
            }
        } else if (user.challengeName === 'MFA_SETUP') {
            console.log(user.challengeName)
            throw new Error('UnsupportedChallenge')
        } else {
            // The user directly signs in
            const currentUser = parseUserResponse(user)
            const profile = await API.lambdaGet('/users/profile/get')

            return {
                user: currentUser,
                profile,
                passwordChangeRequired: false,
            }
        }
    } catch (err) {
        console.log(err)
        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
        } else {
            console.log(err)
        }
        throw err
    }
}

export async function logOut(): Promise<any> {
    await Auth.signOut()
    return null
}

export async function logInFromCache(): Promise<LoginResponse> {
    const congitoUser = await Auth.currentAuthenticatedUser()
    const user = parseUserResponse(congitoUser)
    const profile = await API.lambdaGet('/users/profile/get')
    return {
        user,
        profile: profile,
        passwordChangeRequired: false,
    }
}

export async function forcedChangePassword(currentUser: CurrentUser, newPassword: string): Promise<LoginResponse> {
    const user = currentUser.awsUserObject
    if (!user) throw new Error('Could not get authenticated user')
    await Auth.completeNewPassword(user, newPassword, {})
    const newUser = await Auth.currentAuthenticatedUser()
    const parsed = parseUserResponse(newUser)
    const profile = await API.lambdaGet('/users/profile/get')
    return {
        user: parsed,
        profile,
        passwordChangeRequired: false,
    }
}

export async function updatePassword(
    currentUser: CurrentUser,
    oldPassword: string,
    newPassword: string,
): Promise<boolean> {
    const user = currentUser.awsUserObject
    if (!user) throw new Error('Could not get authenticated user')
    await Auth.changePassword(user, oldPassword, newPassword)
    return true
}
