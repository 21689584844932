import React, { useState, useEffect } from 'react'
import { Promise } from 'bluebird'
import { Button, Icon, Input, Modal, Progress } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import { Request, UserProfile } from '../../types'
import RadialDropdown from '../RadialDropdown'
import RadialProgress from '../RadialProgress'
import { fetchReportsSummary } from '../../services/Reports'
import { canManageRequests } from '../../services/Permissions'
import { closeRequest } from '../../services/Requests'
import { fetchDepartments } from '../../services/Departments'
import { fetchRegistrants } from '../../services/Registrants'
import { fetchActiveUsers } from '../../services/Users'
import 'react-datepicker/dist/react-datepicker.css'
import './style.less'

import { User, Registrant, Department, ReportSummaryFilters } from '../../types'

interface Props {
    filters: ReportSummaryFilters
    handleFilterChange: (newFilters: ReportSummaryFilters) => void
    shouldCreateRequestBeShown: Boolean
    history: any
    requests?: Request[]
    profile?: UserProfile | null
}

const ReportSummary: React.SFC<Props> = ({
    filters,
    handleFilterChange,
    shouldCreateRequestBeShown,
    history,
    requests,
    profile,
}) => {
    const [active, setActive] = useState(0)
    const [escalated, setEscalated] = useState(0)
    const [resolved, setResolved] = useState(0)
    const [total, setTotal] = useState(1)
    const [error, setError] = useState('')
    const [percentages, setPercentages] = useState({
        active: 0,
        escalated: 0,

        resolved: 0,
    })
    const [staffOptionsList, setStaffOptionsList] = useState<User[]>([])
    const [residentsOptionsList, setResidentsOptionsList] = useState<Registrant[]>([])
    const [departmentsOptionsList, setDepartmentsOptionsList] = useState<Department[]>([])
    const [shouldCloseAllBeShown, setShouldCloseAllBeShown] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [totalRequestsTobeDeleted, setTotalRequestsTobeDeleted] = useState(1)
    const [deletedRequests, setDeletedRequests] = useState(0)

    useEffect(() => {
        const loadReportSummary = async () => {
            try {
                setError('')

                const res = await fetchReportsSummary(
                    filters.fromDate,
                    filters.toDate,
                    filters.staff,
                    filters.resident,
                    filters.department,
                )
                if (res.TotRequests === 0) res.TotRequests = 1 // avoid division by 0

                setActive(res.Active)
                setEscalated(res.Escalated)
                setResolved(res.Resolved)
                setTotal(res.TotRequests)
            } catch (e) {
                setError(e.message)
            }
        }

        loadReportSummary()
    }, [filters.fromDate, filters.toDate, filters.staff, filters.resident, filters.department, filters.reload])

    useEffect(() => {
        profile && setShouldCloseAllBeShown(canManageRequests(profile))
        const populateFilters = () => {
            Promise.all([fetchActiveUsers(), fetchRegistrants(), fetchDepartments()]).then(
                ([staffList, residentsList, departmentsList]) => {
                    setStaffOptionsList(staffList)
                    setResidentsOptionsList(residentsList)
                    setDepartmentsOptionsList(departmentsList)
                },
            )
        }

        populateFilters()
    }, [])

    useEffect(() => {
        setPercentages({
            active: (active / total) * 100,
            resolved: (resolved / total) * 100,
            escalated: (escalated / total) * 100,
        })
    }, [active, resolved, escalated, total])

    const delay = ms => new Promise(res => setTimeout(res, ms))

    const redirectToCreate = () => {
        history.push('/admin/requests/create')
    }

    const closeAll = async requestIds => {
        let counter = 0
        setTotalRequestsTobeDeleted(requestIds.length)
        setIsModalOpen(true)
        await Promise.map(
            requestIds,
            async requestId => {
                await closeRequest(requestId)
                counter += 1
                setDeletedRequests(counter)
            },
            { concurrency: 5 },
        )
        await delay(500)
        handleFilterChange({ ...filters, reload: filters.reload + 1 })
        setIsModalOpen(false)
        setDeletedRequests(0)
        setTotalRequestsTobeDeleted(1)
    }

    return (
        <div className="Reports">
            <Modal open={isModalOpen} size="small">
                <Modal.Header>Closing requests</Modal.Header>
                <Modal.Content>
                    <div>{`${deletedRequests} of ${totalRequestsTobeDeleted} deleted`}</div>
                    <Progress color="teal" total={totalRequestsTobeDeleted} value={deletedRequests}></Progress>
                </Modal.Content>
            </Modal>
            <div className="report-panel-row">
                {/* Componet to be passed down here  */}
                <div className={`btn-container ${shouldCloseAllBeShown && 'close-btn-present'}`}>
                    {shouldCreateRequestBeShown && (
                        <button
                            className="create-request"
                            onClick={() => {
                                redirectToCreate()
                            }}
                        >
                            <Icon name="add" className="button-icon" />
                            <div className="text">Create Request</div>
                        </button>
                    )}
                    {shouldCloseAllBeShown && (
                        <button
                            className="create-request mt-10"
                            onClick={() => {
                                const requestIds = [...(requests || [])]
                                    .filter(request => request.Status !== 'Closed')
                                    .map(request => request._id)

                                if (requestIds.length) {
                                    closeAll(requestIds)
                                }
                            }}
                        >
                            <Icon name="close" className="button-icon" />
                            <div className="text">Close All Presented</div>
                        </button>
                    )}
                </div>

                <div>
                    <div>
                        <div className="text-top-datepicker">Start Date</div>

                        <DatePicker
                            selected={filters.fromDate}
                            onChange={date => {
                                handleFilterChange({ ...filters, fromDate: date })
                            }}
                            selectsStart
                            startDate={filters.fromDate}
                            endDate={filters.toDate}
                            maxDate={new Date()}
                            customInput={<Input />}
                        />
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <div className="text-top-datepicker">End Date</div>
                        <DatePicker
                            selected={filters.toDate}
                            onChange={date => {
                                handleFilterChange({ ...filters, toDate: date })
                            }}
                            selectsEnd
                            endDate={filters.toDate}
                            minDate={filters.fromDate}
                            maxDate={new Date()}
                            customInput={<Input />}
                        />
                    </div>
                </div>
            </div>
            <div className="justify-center flex flex-1">
                <div className="report-panel-row radial">
                    <RadialProgress
                        percentage={0}
                        color="#53A4CC"
                        textTop={active.toString()}
                        onClick={() => {
                            handleFilterChange({ ...filters, requestType: 'active' })
                        }}
                        textBottom="Active"
                    />
                    <RadialProgress
                        percentage={percentages.escalated}
                        color="#53A4CC"
                        textTop={escalated.toString()}
                        onClick={() => {
                            handleFilterChange({ ...filters, requestType: 'escalated' })
                        }}
                        textBottom="Escalated"
                    />
                    <RadialProgress
                        percentage={percentages.resolved}
                        color="#53A4CC"
                        textTop={resolved.toString()}
                        onClick={() => {
                            handleFilterChange({ ...filters, requestType: 'resolved' })
                        }}
                        textBottom="Resolved"
                    />
                </div>
                <div className="report-panel-row radial">
                    <RadialDropdown
                        text="Staff"
                        color="#53A4CC"
                        selectedValue={filters.staff}
                        options={[
                            { value: 'all', label: 'All' },
                            ...staffOptionsList.map(item => {
                                return { value: item._id, label: `${item.FirstName} ${item.LastName}` }
                            }),
                        ]}
                        onClick={value => {
                            handleFilterChange({ ...filters, staff: value })
                        }}
                    />
                    <RadialDropdown
                        text="Residents"
                        color="#53A4CC"
                        selectedValue={filters.resident}
                        options={[
                            { value: 'all', label: 'All' },
                            ...residentsOptionsList.map(item => {
                                return { value: item._id, label: `${item.FirstName} ${item.LastName}` }
                            }),
                        ]}
                        onClick={value => {
                            handleFilterChange({ ...filters, resident: value })
                        }}
                    />
                    <RadialDropdown
                        text="Departments"
                        color="#53A4CC"
                        selectedValue={filters.department}
                        options={[
                            { value: 'all', label: 'All' },
                            ...departmentsOptionsList.map(item => {
                                return { value: item._id, label: item.Name }
                            }),
                        ]}
                        onClick={value => {
                            handleFilterChange({ ...filters, department: value })
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReportSummary
