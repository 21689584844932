import API from './API'
import { Unit, Identifiable } from '../types'

export async function fetchUnits(): Promise<Unit[]> {
    /*
        Updated to use lambdaPost because the back-end
        architecture of lambdaGet doesn't allow to pass filters
    */
    const res = await API.lambdaPost('/units/list', {
        Filter: { IsActive: 1 },
    })
    const units = res.Result as Unit[]
    return units.filter(u => u.IsActive === 1)
}

export async function fetchPaginatedUnits(page_no = 1, page_size = 100): Promise<Unit[]> {
    console.log('Page_no', page_no)
    const res = await API.lambdaPost('/units/list', {
        Filter: {
            IsActive: 1,
        },
        QueryOptions: {
            page_no,
            page_size,
            sort: [{ _id: -1 }],
        },
    })

    const units = res.Result as Unit[]
    return units
}

export async function fetchOneUnit(id: string): Promise<Unit> {
    const res = await API.lambdaGet(`/units/get?_id=${id}`)
    return res as Unit
}

export async function createUnit(unit: Partial<Unit>): Promise<Unit> {
    const id = await API.lambdaPost('/units/add', unit)
    const created = await fetchOneUnit(id)
    return created
}

export async function updateUnit(Unit: Partial<Unit> & Identifiable): Promise<Unit> {
    if (!Unit._id) throw new Error('Updated Unit object must contain _id field')
    await API.lambdaPost('/units/update', Unit)
    const updated = await fetchOneUnit(Unit._id)
    return updated
}

export async function deleteUnit(unitId: String): Promise<any> {
    await API.lambdaPost('/units/update', {
        _id: unitId,
        IsActive: 0,
    })
}
