import React from 'react';

import './style.less';

interface Props {
    onInputChange: (stateProp, value) => any;
    alexaBasicsChecked: boolean;
    speak2BasicsChecked: boolean;
    speak2FamilyChecked: boolean;
    speak2RequestsChecked: boolean;
}

const Adoption: React.FC<Props> = props => {
    return (
        <div className="adoption">
            <p>Adoption</p>
            <div className="checkbox-holder">
                <div className="checkbox-option">
                    <label htmlFor="alexaBasics">Alexa<br />Basics</label>
                    <input 
                        type="checkbox" 
                        name="alexaBasics" 
                        checked={props.alexaBasicsChecked}
                        onChange={e => props.onInputChange('alexaBasics', e.currentTarget.checked)} 
                    />
                </div>
                <div className="checkbox-option">
                    <label htmlFor="speak2Basics">Speak2<br />Basics</label>
                    <input 
                        type="checkbox" 
                        name="speak2Basics" 
                        checked={props.speak2BasicsChecked}
                        onChange={e => props.onInputChange('speak2Basics', e.currentTarget.checked)} 
                    />
                </div>
                <div className="checkbox-option">
                    <label htmlFor="speak2Family">Speak2<br />Family</label>
                    <input 
                        type="checkbox" 
                        name="speak2Family" 
                        checked={props.speak2FamilyChecked}
                        onChange={e => props.onInputChange('speak2Family', e.currentTarget.checked)} 
                    />
                </div>
                <div className="checkbox-option">
                    <label htmlFor="speak2Requests">Speak2<br />Requests</label>
                    <input 
                        type="checkbox" 
                        name="speak2Requests" 
                        checked={props.speak2RequestsChecked}
                        onChange={e => props.onInputChange('speak2Requests', e.currentTarget.checked)} 
                    />
                </div>
            </div>
        </div>
    );
};

export default Adoption;