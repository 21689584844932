import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import Flatpickr from 'react-flatpickr'
import { withRouter, RouteComponentProps } from 'react-router'
import ReactQuill from 'react-quill'

import './style.less'
import 'react-quill/dist/quill.snow.css'
import { MenuType } from '../../types/menu'
import { capitalizeFirstLetter } from './utils'

interface Props extends RouteComponentProps {
    isFetching: boolean
    isSaving: boolean
    aciveFoodTab: string | undefined
    editableMenu?: Partial<MenuType>
    edit?: boolean
    _id?: string
    copyMenu?: MenuType | null
    handleSubmit: (menu) => any
    handleUpdate: (menu) => any
    cancelEditMenu: (id?) => any
}

interface State {
    menu: Partial<MenuType>
}

class MenuFormEditor extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            menu: {
                text: '',
                alexaReadableCollection: [],
                timestamp: null,
                dateString: '',
            },
        }
    }

    setMenuText(content, delta, source, editor) {
        const htmlText = content
        const alexaReadableCollection = editor.getContents().ops
        this.setState({
            menu: {
                ...this.state.menu,
                text: htmlText,
                alexaReadableCollection,
            },
        })
    }

    setActivityTime(time) {
        console.log('time: ', time, Date.parse(time))
        this.setState({
            menu: {
                ...this.state.menu,
                timestamp: Date.parse(time),
                dateString: new Date(time).toDateString(),
            },
        })
    }

    validForm() {
        return this.state.menu.text && this.state.menu.timestamp
    }

    async handleSubmit() {
        if (this.props.edit) {
            const menu = this.state.menu
            menu._id = this.props._id
            menu.category = this.props.aciveFoodTab
            this.props.handleUpdate(menu)
        } else {
            this.props.handleSubmit(this.state.menu)
        }
        this.setState({
            menu: {
                text: '',
                timestamp: null,
                dateString: '',
            },
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.copyMenu &&
            nextProps.copyMenu !== prevState.copyMenu &&
            nextProps.copyMenu.text &&
            nextProps.copyMenu.timestamp
        ) {
            return {
                menu: {
                    text: nextProps.copyMenu.text,
                    timestamp: nextProps.copyMenu.timestamp,
                    alexaReadableCollection: nextProps.copyMenu.alexaReadableCollection,
                },
                copyMenu: nextProps.copyMenu,
            }
        }
        return prevState
    }

    componentDidMount() {
        if (this.props.editableMenu) {
            this.setState({
                menu: this.props.editableMenu,
            })
        }
    }

    render() {
        return (
            <Form loading={this.props.isFetching} autoComplete="false" onSubmit={this.handleSubmit.bind(this)}>
                <Form.Field>
                    <ReactQuill
                        value={this.state.menu.text}
                        placeholder={`Insert ${this.props.aciveFoodTab} content here.`}
                        onChange={this.setMenuText.bind(this)}
                        modules={{
                            toolbar: [
                                [{ size: ['small', false, 'large', 'huge'] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                [{ color: [] }, { background: [] }],
                                [{ align: [] }],
                            ],
                            clipboard: {
                                matchVisual: false,
                            },
                        }}
                    />
                </Form.Field>

                <Form.Field>
                    <Flatpickr
                        value={this.state.menu.timestamp}
                        options={{
                            dateFormat: 'd.m.Y',
                            altInput: true,
                            altFormat: 'F j, Y',
                        }}
                        placeholder="Select Date"
                        onChange={(date) => this.setActivityTime(date)}
                    />
                </Form.Field>

                <Button
                    type="submit"
                    primary
                    loading={this.props.isSaving}
                    disabled={this.props.isSaving || !this.validForm()}
                >
                    {`Save ${capitalizeFirstLetter(this.props.aciveFoodTab)}`}
                </Button>
                <Button
                    onClick={() => {
                        if (this.props.edit) {
                            this.props.cancelEditMenu(this.props._id)
                        } else {
                            this.setState({
                                menu: {
                                    text: '',
                                    timestamp: null,
                                },
                            })
                            this.props.cancelEditMenu()
                        }
                    }}
                >
                    Cancel
                </Button>
            </Form>
        )
    }
}

export default withRouter(MenuFormEditor)
