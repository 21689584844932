import React, { MouseEvent } from 'react'
import { Form, Button, Tab } from 'semantic-ui-react'

import './style.less'
import { UserRole, Registrant, Unit, User } from '../../../../types'
import DataSourceDropdown from '../../../../components/DataSourceDropdown'
import EditableImage from '../../../../components/EditableImage'
import FamilyInvite from '../../../../components/FamilyInvite'
import Adoption from '../../../../components/Adoption'
import { sendSmsInvite, sendEmailInvite } from '../../../../services/SnsService'
import { setError } from '../../../../actions/auth'

interface State {
    registrantData: Partial<Registrant>
    selectedUnit: Unit | null
    role: UserRole | null
    PrimaryCaregiver: User | string | null
    invite: string
    success: string
    error: string
    isSubmittingInvite: boolean
}
interface Props {
    units: Unit[]
    staffUsers: (User | { _id: string; FirstName: string })[]
    registrant: Registrant | null
    isFetching: boolean
    hasError: boolean
    error: string | null
    isSaving: boolean
    onSave: (registrantData: Partial<Registrant>, unit: Unit) => any
    cancel: () => any
    onDelete?: (registrantId: string) => any
}
class RegistrantEditor extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            registrantData: {},
            role: null,
            selectedUnit: (this.props.registrant && this.props.registrant.Unit) || null,
            PrimaryCaregiver: (this.props.registrant && this.props.registrant.PrimaryCaregiver) || null,
            invite: '',
            success: '',
            error: '',
            isSubmittingInvite: false,
        }
    }

    setRegistrantDataField(key: keyof Registrant, value: any) {
        this.setState({
            registrantData: {
                ...this.state.registrantData,
                [key]: value,
            },
        })
    }

    setRegistrantAdoptionField(key: keyof Registrant['Adoption'], value: any) {
        this.setState({
            registrantData: {
                ...this.state.registrantData,
                Adoption: {
                    ...this.state.registrantData.Adoption,
                    [key]: value,
                },
            },
        })
    }

    setStateDataField(key: keyof State, value: any) {
        this.setState({
            [key]: value,
        } as Pick<State, keyof State>)
    }

    getRoomNumberFromUnit(unitId: string): string {
        const unit = this.props.units.find(u => u._id === unitId)
        return unit ? unit.Name : ''
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (Object.keys(state.registrantData).length) return null
        if (!props.registrant) return null
        return {
            registrantData: props.registrant,
            selectedUnit: props.registrant.Unit,
        }
    }

    async handleSubmit() {
        if (!this.state.selectedUnit) return
        this.props.onSave(this.state.registrantData, this.state.selectedUnit)
    }

    async handleDelete(e: MouseEvent) {
        e.preventDefault()
        if (!this.canDelete) return
        // TODO: use proper confirm here
        const result = window.confirm('Are you sure you want to remove this Resident?')
        if (!result) return
        if (this.props.onDelete && this.state.registrantData && this.state.registrantData._id) {
            this.props.onDelete(this.state.registrantData._id)
        }
    }

    async handleSubmitInvite(e: MouseEvent): Promise<void> {
        e.preventDefault()

        const setIsSubmitting = value => {
            this.setState({ isSubmittingInvite: value })
        }

        setIsSubmitting(true)

        const inviteContact = btoa(this.state.invite)
        const registrantId = btoa((this.props.registrant && this.props.registrant._id) || '')
        const firstName = btoa((this.props.registrant && this.props.registrant.FirstName) || '')
        const lastName = btoa((this.props.registrant && this.props.registrant.LastName) || '')
        const image = btoa((this.props.registrant && this.props.registrant.Image) || '')

        const setError = error => {
            this.setState({ error, success: '' })
        }

        const setSuccess = success => {
            this.setState({ success, error: '' })
        }

        if (!inviteContact || !registrantId) setError('invite')

        const inviteObject = {
            c: inviteContact,
            r: registrantId,
            f: firstName,
            l: lastName,
            i: image,
        }

        const invitationOptions = {
            email: async function() {
                try {
                    inviteObject.c = btoa(atob(inviteObject.c).toLowerCase())
                    const emailResponse = await sendEmailInvite(inviteObject)
                    console.log(emailResponse)
                    if (emailResponse === 'This person is already invited.') {
                        setError('This person is already invited.')
                    } else if (emailResponse.error) {
                        setError('Could not send email.')
                    } else {
                        setSuccess('Invite sent successfully.')
                    }
                } catch (error) {
                    setError('Could not send email.')
                }
                setIsSubmitting(false)
            },
            phone: async function() {
                try {
                    const smsResponse = await sendSmsInvite(inviteObject)
                    if (smsResponse === 'This person is already invited.') {
                        setError('This person is already invited.')
                    } else if (smsResponse.error) {
                        setError('Could not send sms.')
                    } else {
                        setSuccess('Invite sent successfully.')
                    }
                } catch (error) {
                    setError('Could not send sms.')
                }
                setIsSubmitting(false)
            },
        }
        const option = this.validateInvite(this.state.invite)
        if (option) {
            invitationOptions[option]()
        } else {
            console.log('INVALID INPUT!')
            setIsSubmitting(false)
        }
    }

    handleCancel(e: MouseEvent) {
        e.preventDefault()
        this.props.cancel()
    }

    validateInvite(value: string) {
        const emailTest = /\S+@\S+\.\S+/
        if (emailTest.test(value)) return 'email'
        const phoneTest = /^[0-9+?]*$/
        if (phoneTest.test(value)) return 'phone'
        return false
    }

    get validEmail() {
        if (!this.state.registrantData.Email || this.state.registrantData.Email.length < 1) return true
        const re = /\S+@\S+\.\S+/
        return re.test(this.state.registrantData.Email)
    }

    get validForm() {
        return (
            this.validEmail &&
            this.state.selectedUnit &&
            this.state.registrantData.FirstName &&
            this.state.registrantData.LastName
        )
    }

    get canDelete() {
        return this.props.onDelete && this.state.registrantData && this.state.registrantData._id
    }

    panes = [
        {
            menuItem: 'About',
            render: () => {
                return (
                    <div className="tab-content">
                        <Form.Field required>
                            <Form.Group widths="equal">
                                <Form.Input
                                    value={this.state.registrantData.FirstName || ''}
                                    required
                                    fluid
                                    label="First name"
                                    onChange={e => this.setRegistrantDataField('FirstName', e.currentTarget.value)}
                                    placeholder="First name"
                                />
                                <Form.Input
                                    value={this.state.registrantData.LastName || ''}
                                    required
                                    fluid
                                    label="Last name"
                                    onChange={e => this.setRegistrantDataField('LastName', e.currentTarget.value)}
                                    placeholder="Last name"
                                />
                            </Form.Group>
                        </Form.Field>

                        <Form.Field required>
                            <label>Room</label>
                            <DataSourceDropdown
                                selected={this.state.registrantData.Unit || undefined}
                                placeholder="Room"
                                data={this.props.units}
                                labelFields={['Name']}
                                onSelectionChange={(u: Unit) =>
                                    this.setState({
                                        selectedUnit: u,
                                    })
                                }
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Email</label>
                            <Form.Input
                                value={this.state.registrantData.Email || ''}
                                error={!this.validEmail}
                                placeholder="Email"
                                type="email"
                                autoComplete="false"
                                name="registeremail"
                                onChange={e => this.setRegistrantDataField('Email', e.currentTarget.value)}
                            />
                        </Form.Field>

                        <div className="features-container">
                            <FamilyInvite
                                invite={this.state.invite}
                                manager={this.state.registrantData.Manager || null}
                                onInputChange={this.setStateDataField.bind(this)}
                                submitInvite={this.handleSubmitInvite.bind(this)}
                                success={this.state.success}
                                error={this.state.error}
                                isSubmitting={this.state.isSubmittingInvite}
                            />
                            <Adoption
                                alexaBasicsChecked={
                                    this.state.registrantData.Adoption
                                        ? !!this.state.registrantData.Adoption.alexaBasics
                                        : false
                                }
                                speak2BasicsChecked={
                                    this.state.registrantData.Adoption
                                        ? !!this.state.registrantData.Adoption.speak2Basics
                                        : false
                                }
                                speak2FamilyChecked={
                                    this.state.registrantData.Adoption
                                        ? !!this.state.registrantData.Adoption.speak2Family
                                        : false
                                }
                                speak2RequestsChecked={
                                    this.state.registrantData.Adoption
                                        ? !!this.state.registrantData.Adoption.speak2Requests
                                        : false
                                }
                                onInputChange={this.setRegistrantAdoptionField.bind(this)}
                            />
                        </div>
                    </div>
                )
            },
        },
        {
            menuItem: 'Other Info',
            render: () => {
                return (
                    <div className="tab-content">
                        <Form.Field>
                            <label>Cell</label>
                            <Form.Input
                                value={this.state.registrantData.Cell || ''}
                                placeholder="Cell"
                                onChange={e => this.setRegistrantDataField('Cell', e.currentTarget.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Phone</label>
                            <Form.Input
                                value={this.state.registrantData.Phone || ''}
                                placeholder="Phone"
                                onChange={e => this.setRegistrantDataField('Phone', e.currentTarget.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Special Handling</label>
                            <Form.Input
                                value={this.state.registrantData.SpecialHandling || ''}
                                placeholder="Special Handling"
                                onChange={e => this.setRegistrantDataField('SpecialHandling', e.currentTarget.value)}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Care Plan</label>
                            <Form.Input
                                value={this.state.registrantData.CarePlan || ''}
                                type="textarea"
                                placeholder="Care Plan"
                                onChange={e => this.setRegistrantDataField('CarePlan', e.currentTarget.value)}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Primary Caregiver</label>
                            <DataSourceDropdown
                                selected={this.state.registrantData.PrimaryCaregiver || undefined}
                                placeholder="Primary Caregiver"
                                data={[{ _id: '1', FirstName: 'Other' }].concat(this.props.staffUsers)}
                                labelFields={['FirstName', 'LastName']}
                                onSelectionChange={user => {
                                    // If the _id of user is '1' representing "Other", than insert "Other" into db
                                    if (user._id === '1') {
                                        this.setRegistrantDataField('PrimaryCaregiver', 'Other')
                                        return
                                    }
                                    // If a regular user was selected, insert the users _id into db
                                    this.setRegistrantDataField('PrimaryCaregiver', user._id)
                                }}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Amazon Account ID</label>
                            <Form.Input
                                value={this.state.registrantData.AmazonAccountID || ''}
                                error={!this.validEmail}
                                placeholder="Amazon Account ID"
                                type="email"
                                autoComplete="false"
                                name="amazonid"
                                onChange={e => this.setRegistrantDataField('AmazonAccountID', e.currentTarget.value)}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Notes</label>
                            <Form.Input
                                value={this.state.registrantData.Notes || ''}
                                type="textarea"
                                placeholder="Notes"
                                onChange={e => this.setRegistrantDataField('Notes', e.currentTarget.value)}
                            />
                        </Form.Field>
                    </div>
                )
            },
        },
    ]

    render() {
        const placeholderUrl = `${process.env.PUBLIC_URL}/avatar_placeholder.png`

        return (
            <div className="CreateUser">
                <Form loading={this.props.isFetching} autoComplete="false" onSubmit={this.handleSubmit.bind(this)}>
                    <Form.Field className="image-field">
                        <EditableImage
                            url={this.state.registrantData.Image}
                            placeholder={placeholderUrl}
                            onSelected={newUrl => this.setRegistrantDataField('Image', newUrl)}
                        />
                    </Form.Field>

                    <Tab className="registrant-tab" panes={this.panes} />

                    <Form.Field>
                        <div className="error-message">{this.props.hasError && this.props.error}</div>
                    </Form.Field>
                    <div className="button-holder">
                        <Button
                            type="submit"
                            primary
                            loading={this.props.isSaving}
                            disabled={this.props.isSaving || !this.validForm}
                        >
                            Save Resident
                        </Button>
                        <Button basic onClick={this.handleCancel.bind(this)}>
                            Cancel
                        </Button>
                        {this.canDelete && (
                            <Button
                                basic
                                color="red"
                                loading={this.props.isSaving}
                                onClick={this.handleDelete.bind(this)}
                            >
                                Remove Resident
                            </Button>
                        )}
                    </div>
                </Form>
            </div>
        )
    }
}

export default RegistrantEditor
