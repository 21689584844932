import API from './API'


export async function cloneData(filters): Promise<any> {
    const payload = {
        ...filters
    }    
    const res = await API.lambdaPost('/clone-data/add',payload);
    
    return res;
}