import React from 'react'
import { Item } from 'semantic-ui-react'
import './style.less'
import { Request } from '../../types'
import RequestItem from '../RequestItem'

interface Props {
    requests: Request[]
    unassigned: boolean
    onClick: (id: string) => any
}

const RequestsList: React.FC<Props> = props => {
    const requestItems = props.requests
        // .filter(req => req.Registrant_FirstName && req.Registrant_LastName)
        .map(req => (
            <RequestItem
                key={req._id}
                id={req._id}
                requestName={req.Name || 'Unknown'}
                requestTime={req.RequestedTime}
                residentName={req.Registrant_FirstName + ' ' + req.Registrant_LastName}
                escalatedIndicator={(req.EscalatedTo && props.unassigned) || false}
                avatarUrl={req.Registrant_Image}
                room={req.Unit ? req.Unit_Name : 'Unknown Room'}
                onClick={() => props.onClick(req._id)}
            />
        ))

    return (
        <Item.Group divided unstackable>
            {requestItems}
        </Item.Group>
    )
}

export default RequestsList
