import React, { useEffect, useState } from 'react'
import { Dimmer, Loader, Button, List } from 'semantic-ui-react'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchBroadcastMessages } from '../../../../services/Speak2Broadcaster'
import Message from './Message'
import './style.less'
const Speak2FamilyIncomingMessageList = ({}) => {
    const [page, setPage] = useState(1)
    const [messages, setMessages] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isMore, setIsMore] = useState(true)

    const loadMessages = async (localPage = 1) => {
        const resp = await fetchBroadcastMessages(localPage, 30)
        console.log('resp', resp)
        if (resp.length < 30) {
            setIsMore(false)
        }
        setMessages([...messages, ...resp])
    }

    const loadMore = async () => {
        setIsLoading(true)
        await loadMessages(page)
        setIsLoading(false)

        setPage(page + 1)
    }

    useEffect(() => {
        loadMore()
    }, [])

    const updateMessage = (id, isApproved) => {
        const index = messages.findIndex(el => el._id === id)

        const localCopy = messages.map(e => e)

        localCopy[index] = {
            ...localCopy[index],
            isDistributed: true,
            [isApproved ? 'isApproved' : 'isRejected']: true,
        }

        setMessages([...localCopy])
    }

    return (
        <div className="Speak2FamilyMessageList">
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading} />
            </Dimmer>
            <List divided relaxed>
                {messages.map(message => (
                    <List.Item key={message._id} className={message.isDistributed ? 'disabled-message' : ''}>
                        <List.Content>
                            <List.Header>{message.relativeName}</List.Header>{' '}
                            <div className="sentAtdate"> {new Date(message.sentAt).toLocaleString()} </div>
                            <List.Description>
                                <Message
                                    type={message.type}
                                    text={message.text}
                                    playableUrl={message.playableUrl}
                                    isDistributed={!!message.isDistributed}
                                    isApproved={!!message.isApproved}
                                    isRejected={!!message.isRejected}
                                    _id={message._id}
                                    updateMessage={updateMessage}
                                />
                            </List.Description>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
            <Button onClick={() => loadMore()} disabled={!isMore}>
                Load More
            </Button>
        </div>
    )
}

export default withRouter(Speak2FamilyIncomingMessageList)
