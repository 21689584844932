import React from 'react'
import './style.less'
import { Dimmer, Loader, Button, List } from 'semantic-ui-react'
import { Contact } from '../../../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchContacts } from '../../../../services/Contacts'

interface State {
    contacts: Contact[]
    error: string | null
    isFetching: boolean
    page: number
    count: number
    limit: number
}

class ContactsList extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            contacts: [],
            error: null,
            isFetching: false,
            page: 0,
            count: 0,
            limit: 10000,
        }
    }

    async componentDidMount() {
        this.setState({
            isFetching: true,
            error: null,
        })

        try {
            const contacts = await fetchContacts()
            this.setState({
                isFetching: false,
                contacts,
                error: null,
            })
        } catch (e) {
            const msg = e.message || 'Could not fetch Contacts.'
            this.setState({
                isFetching: false,
                error: msg,
            })
        }
    }

    navigateToCreate() {
        return this.props.history.push('/admin/forms/contacts/create')
    }

    goToEdit(id: string) {
        return this.props.history.push(`/admin/forms/contact/${id}`)
    }

    registrantName(contact: Contact) {
        if (contact.Registrant) {
            return `${contact.Registrant.FirstName} ${contact.Registrant.LastName}`
        } else {
            return 'No resident'
        }
    }

    get listItems() {
        return this.state.contacts.map(c => (
            <List.Item key={c._id} onClick={() => this.goToEdit(c._id)}>
                <List.Icon name="user" size="large" verticalAlign="middle" />
                <List.Content>
                    <List.Header as="a">
                        {c.FirstName} {c.LastName}
                    </List.Header>
                    <List.Description as="a">Contact For: {this.registrantName(c)}</List.Description>
                </List.Content>
            </List.Item>
        ))
    }

    render() {
        return (
            <div className="ContactsList">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Button
                    content="Create New"
                    icon="add"
                    labelPosition="left"
                    primary
                    onClick={this.navigateToCreate.bind(this)}
                />
                <List divided relaxed>
                    {this.listItems}
                </List>
            </div>
        )
    }
}

export default withRouter(ContactsList)
