import { authReducer } from './auth'
import { requestsReducer } from './requests'
import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
    authReducer,
    requestsReducer,
})

export type AppState = ReturnType<typeof rootReducer>
