export default {
    development: {
        identityPoolId: 'us-east-1:66a2b483-d23f-4b59-bac1-cdf2dd3fcc66',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '18r8rc6uto26h07t6d8anslvbv',
        lambdaEndpoint: 'https://yek6gf0kb3.execute-api.us-east-1.amazonaws.com/dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    speak2familyDev: {
        identityPoolId: 'us-east-1:66a2b483-d23f-4b59-bac1-cdf2dd3fcc66',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '18r8rc6uto26h07t6d8anslvbv',
        lambdaEndpoint: 'https://ay76u3raz6.execute-api.us-east-1.amazonaws.com/speak2family-dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    production: {
        identityPoolId: 'us-east-1:66a2b483-d23f-4b59-bac1-cdf2dd3fcc66',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '18r8rc6uto26h07t6d8anslvbv',
        lambdaEndpoint: 'https://isuqd3h5r8.execute-api.us-east-1.amazonaws.com/prod',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
    test: {
        identityPoolId: 'us-east-1:66a2b483-d23f-4b59-bac1-cdf2dd3fcc66',
        region: 'us-east-1',
        userPoolId: 'us-east-1_bJokgHBxQ',
        appClientId: '18r8rc6uto26h07t6d8anslvbv',
        lambdaEndpoint: 'https://0ihzow8ng7.execute-api.us-east-1.amazonaws.com/api',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        firebaseConfig: {
            apiKey: 'AIzaSyD81idwtejtL71m3Y43LJsKjPj3JDvMRyo',
            authDomain: 'speak2-app.firebaseapp.com',
            databaseURL: 'https://speak2-app.firebaseio.com',
            projectId: 'speak2-app',
            storageBucket: 'speak2-app.appspot.com',
            messagingSenderId: '676379562281',
            appId: '1:676379562281:web:4698bae915fbb978',
        },
    },
}
