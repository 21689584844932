import API from './API'

import { UniversityTraining } from '../types'

export async function newTraining(universityTraining: UniversityTraining): Promise<void> {
    const response = API.lambdaPost('/university/trainings/add', universityTraining)
    return response
}

export async function getTraining(
    _id,
    page_no: string | number | undefined = 1,
    page_size = 5,
    filters: any = null,
): Promise<any> {
    if (_id == null || _id == undefined) {
        const params = {
            QueryOptions: {
                page_no,
                page_size,
                //sort: [{ topic: 'desc' }],
            },
        }
        if (filters) {
            params['Filter'] = { ...filters }
        }
        const response = API.lambdaPost('/university/trainings/list', params)
        return response
    } else {
        const queryStringParam = { _id }
        const response = API.lambdaGet('/university/trainings/list', queryStringParam)
        return response
    }
}

export async function deleteTraining(_id): Promise<any> {
    const queryStringParam = { _id }
    const response = API.lambdaDeleteById('/university/trainings/delete', queryStringParam)
    return response
}

export async function updateTraining(universityTraining: UniversityTraining): Promise<void> {
    const response = API.lambdaPut('/university/trainings/update', universityTraining)
    return response
}
