import React from 'react';

import './style.less';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dimmer, Loader, Icon, Tab, TabProps, Pagination, PaginationProps } from 'semantic-ui-react';
import { DailyActivity, UserProfile } from '../../../types';
import 'flatpickr/dist/themes/airbnb.css';
import 'rc-time-picker/assets/index.css';
import { newActivity, Activities, deleteActivity, updateActivity } from '../../../services/DailyActivities';
import ActivityForm from '../../../components/ActivityForm';
import ListItemsPane from '../../../components/ListItemsPane';

interface State {
    isFetching: boolean;
    error: string | null;
    isSaving: boolean;
    activities: DailyActivity[];
    activeActivities: DailyActivity[];
    historyActivities: DailyActivity[];
    openCreateForm: boolean;
    activityFilter: string;
    activeTab: string;
    activePageNumber: any;
    copyActivity: DailyActivity | null;
    activeTabIndex: number;
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null;
}

class DailyActivities extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isFetching: false,
            error: null,
            isSaving: false,
            activities: [],
            activeActivities: [],
            historyActivities: [],
            openCreateForm: false,
            activityFilter: '',
            activeTab: 'active',
            activePageNumber: 1,
            copyActivity: null,
            activeTabIndex: 0
        }
    }

    openCloseCreateActivityForm(): void {
        if (this.state.copyActivity) {
            this.setState({
                openCreateForm: !this.state.openCreateForm,
                copyActivity: null
            });
        } else {
            this.setState({
                openCreateForm: !this.state.openCreateForm
            });
        }
    }

    async deleteActivity(id): Promise<void> {
        this.setState({
            ...this.state,
            isSaving: true,
        });
        try {
            await deleteActivity(id);
            await this.populateStateWithActivities('delete');
        } catch (error) {
            this.setState({
                ...this.state,
                isSaving: false,
                error: error
            });
        }
    }

    editActivity(id: string): void {
        const activities = this.state.activities.map((activity: DailyActivity) => {
            if (activity._id === id) activity.edit = true;
            return activity;
        });
        this.setState({
            ...this.state,
            activities
        });
    }

    cancelEditActivity(id?: string): void {
        if (id) {
            const activities = this.state.activities.map((activity: DailyActivity) => {
                if (activity._id === id) delete activity.edit;
                return activity;
            });
            this.setState({
                ...this.state,
                activities,
                copyActivity: null
            });
        } else {
            this.setState({
                openCreateForm: false,
                copyActivity: null
            })
        }
    }

    copyActivity(id: string) {
        let activity = this.state.activities.find((activity: DailyActivity) => activity._id === id);
        document.getElementsByClassName('page-header')[0].scrollIntoView({block: 'start', behavior: 'smooth'});
        if (activity) {
            this.setState({
                openCreateForm: true,
                copyActivity: activity
            });
        }
    }

    async handleSubmit(activity: DailyActivity): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null
        });

        try {
            await newActivity({
                text: activity.text,
                timestamp: activity.timestamp,
                AddedBy: this.props.profile ? this.props.profile._id : ''
            });

            await this.populateStateWithActivities('save');

        } catch (error) {
            this.setState({
                isSaving: false,
                error: error.message || 'Could not save Activity.',
            });
        }
    }

    async handleUpdate(activity): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null
        });

        try {
            await updateActivity({
                _id: activity._id,
                text: activity.text,
                timestamp: activity.timestamp,
                UpdatedBy: this.props.profile ? this.props.profile._id : ''
            });

            await this.populateStateWithActivities('update');

        } catch (error) {
            this.setState({
                isSaving: false,
                error: error.message || 'Could not update Activity.',
            });
        }
    }

    async getActivities(): Promise<DailyActivity[]> {
        this.setState({ isFetching: true, error: null });
        const activities = await Activities();
        return activities.Result;
    }

    setActivityDateFilter(time): void {
        const date = new Date(Date.parse(time)).toLocaleDateString('en-US');
        if (this.state.activeTab === 'active') {
            this.setState({
                activityFilter: date,
                activities: this.state.activeActivities.filter((activity: DailyActivity) => activity.date === date),
                activePageNumber: 1
            });
        }
        if (this.state.activeTab === 'history') {
            this.setState({
                activityFilter: date,
                activities: this.state.historyActivities.filter((activity: DailyActivity) => activity.date === date),
                activePageNumber: 1
            });
        }
        
    }

    clearDateFilter(): void {
        if (this.state.activeTab === 'active') {
            this.setState({
                activityFilter: '',
                activities: this.state.activeActivities,
                activePageNumber: 1
            });
        }
        if (this.state.activeTab === 'history') {
            this.setState({
                activityFilter: '',
                activities: this.state.historyActivities,
                activePageNumber: 1
            });
        }
    }

    handleTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps): void {
        this.cancelEditActivity();
        if (data.activeIndex === 0) {
            this.setState({
                activeTab: 'active',
                activities: this.state.activeActivities,
                activePageNumber: 1,
                activeTabIndex: 0
            });
        }
        if (data.activeIndex === 1) {
            this.setState({
                activeTab: 'history',
                activities: this.state.historyActivities,
                activePageNumber: 1,
                activeTabIndex: 1
            });
        }
    }

    changePage(e, data: PaginationProps): void {
        this.setState({
            activePageNumber: data.activePage
        });
    }

    getPaginatedActivity() {
        if (this.state.activities.length > this.paginationPerPageNumber) {
            const startNumber = (this.state.activePageNumber - 1) * this.paginationPerPageNumber;
            const endNumber = (this.state.activePageNumber) * this.paginationPerPageNumber - 1;

            let returnActivities = this.state.activities;

            if (this.state.activeTab === 'history') {
                returnActivities = this.state.activities.slice();
                returnActivities.sort((a, b) => b.timestamp - a.timestamp);
            }

            return returnActivities.filter((activity: DailyActivity, index: number) => {
                return index >= startNumber && index <= endNumber;
            });
        }
        return this.state.activities;
    }

    async populateStateWithActivities(readActivityIntent) {
        try {
            const activities = await this.getActivities();
            

            activities.forEach((activity: DailyActivity) => {
                activity.date = new Date(activity.timestamp).toLocaleDateString("en-US");
            });

            const now = Date.now();

            const activeActivities = activities.filter((activity: DailyActivity) => {
                return activity.timestamp >= now;
            });
            const historyActivities = activities.filter((activity: DailyActivity) => {
                return activity.timestamp < now;
            });
            
            if ((readActivityIntent === 'update' || readActivityIntent === 'delete') && this.state.activeTab === 'history') {
                this.setState({
                    ...this.state,
                    isFetching: false,
                    isSaving: false,
                    openCreateForm: false,
                    activeTab: 'history',
                    activities: historyActivities,
                    activeActivities,
                    historyActivities,
                    activePageNumber: 1,
                    activeTabIndex: 1
                });
            } else {
                this.setState({
                    ...this.state,
                    isFetching: false,
                    isSaving: false,
                    openCreateForm: false,
                    activeTab: 'active',
                    activities: activeActivities,
                    activeActivities,
                    historyActivities,
                    activePageNumber: 1,
                    activeTabIndex: 0
                });
            }
            
        } catch (error) {
            this.setState({
                ...this.state,
                isFetching: false,
                error
            });
        }
    }

    async componentDidMount() {
        await this.populateStateWithActivities('mount');
    }

    paginationPerPageNumber = 10;

    panes = [
        {
            menuItem: 'Active',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.activityFilter}
                    setListItemDateFilter={this.setActivityDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.activities}
                    getPaginatedListItem={this.getPaginatedActivity.bind(this)}
                    deleteListItem={this.deleteActivity.bind(this)}
                    editListItem={this.editActivity.bind(this)}
                    copyListItem={this.copyActivity.bind(this)}
                    cancelEditListItem={this.cancelEditActivity.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage='DailyActivities'
                />
            ),
        },
        {
            menuItem: 'History',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.activityFilter}
                    setListItemDateFilter={this.setActivityDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.activities}
                    getPaginatedListItem={this.getPaginatedActivity.bind(this)}
                    deleteListItem={this.deleteActivity.bind(this)}
                    editListItem={this.editActivity.bind(this)}
                    copyListItem={this.copyActivity.bind(this)}
                    cancelEditListItem={this.cancelEditActivity.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage='DailyActivities'
                />
            ),
        }
    ];

    render() {
        return (
            <div className="DailyActivities">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>

                <button className="create-activity" onClick={this.openCloseCreateActivityForm.bind(this)}>
                    <Icon name="add" className="button-icon" />
                    <div className="text">Create Activity</div>
                </button>

                { this.state.openCreateForm 
                    ? 
                    <ActivityForm
                        isFetching={this.state.isFetching}
                        isSaving={this.state.isSaving}
                        handleSubmit={this.handleSubmit.bind(this)}
                        handleUpdate={this.handleUpdate.bind(this)}
                        cancelEditActivity={this.cancelEditActivity.bind(this)}
                        copyActivity={this.state.copyActivity}
                    /> 
                    : 
                    null 
                }

                <Tab
                    activeIndex={this.state.activeTabIndex}
                    className='views-tab'
                    menu={{ secondary: true, pointing: true }}
                    panes={this.panes}
                    onTabChange={this.handleTabChange.bind(this)}
                />

                {this.state.activities.length > this.paginationPerPageNumber
                    ?
                    <div className='pagination-holder'>
                        <Pagination 
                            activePage={this.state.activePageNumber}
                            totalPages={Math.ceil(this.state.activities.length / this.paginationPerPageNumber)}
                            onPageChange={this.changePage.bind(this)}
                            siblingRange={1} />
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

export default withRouter(DailyActivities);