import React from 'react'
import RequestTypesForm, { FormValues } from '../RequestTypesForm'
import { withRouter, RouteComponentProps } from 'react-router'
import { fetchDepartments } from '../../../../services/Departments'
import { Dimmer, Loader } from 'semantic-ui-react'
import { Department, RequestType } from '../../../../types'
import { createRequestType } from '../../../../services/RequestTypes';

interface State {
    isSaving: boolean
    error: string | null
    isFetching: boolean
    departments: Department[]
}

class RequestTypesCreate extends React.Component<RouteComponentProps, State> {
    state = {
        isSaving: false,
        error: null,
        isFetching: false,
        departments: [],
    }

    goBack() {
        this.props.history.goBack()
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const departments = await fetchDepartments()
            this.setState({ isFetching: false, departments })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    async handleSave(data: FormValues) {
        this.setState({ isSaving: true, error: null })
        try {
            if (!data.Department || !data.EscalationDepartment) throw new Error('Must specify Department and Escalation Department')
            const requestType: Partial<RequestType> = {
                ...data,
                Department: data.Department._id,
                NotificationMethodA: data.NotificationMethodA && data.NotificationMethodA._id,
                NotificationMethodB: data.NotificationMethodB && data.NotificationMethodB._id,
                EscalationDepartment: data.EscalationDepartment._id,
            }
            await createRequestType(requestType)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    render() {
        return (
            <div className="RequestTypesCreate">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.departments.length && (
                    <RequestTypesForm
                        title="Create Service Type"
                        departments={this.state.departments}
                        requestType={null}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(RequestTypesCreate)
