import React from 'react'
import UnitsForm, { UnitFormValues } from '../UnitsForm'

import { withRouter, RouteComponentProps } from 'react-router'
import { Unit } from '../../../../types'
import { Dimmer, Loader } from 'semantic-ui-react'
import { fetchOneUnit, updateUnit, deleteUnit } from '../../../../services/Units';

interface State {
    isFetching: boolean
    unit: Unit | null
    isSaving: boolean
    error: string | null
}

interface RouteInfo {
    id: string
}

class UnitsEdit extends React.Component<RouteComponentProps<RouteInfo>, State> {
    id: string
    constructor(props: RouteComponentProps<RouteInfo>) {
        super(props)
        this.state = {
            isSaving: false,
            error: null,
            unit: null,
            isFetching: false,
        }

        this.id = props.match.params.id
    }

    async componentDidMount() {
        this.setState({ isFetching: true, error: null })
        try {
            const unit = await fetchOneUnit(this.id)
            this.setState({ isFetching: false, unit })
        } catch (e) {
            this.setState({ isFetching: false, error: e.message })
        }
    }

    goBack() {
        this.props.history.goBack()
    }

    async handleSave(data: UnitFormValues) {
        this.setState({ isSaving: true, error: null })
        const postData = {
            ...data,
            _id: this.id,
        }
        try {
            await updateUnit(postData)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({ isSaving: false, error: e.message })
        }
    }

    async handleDelete(unitId: string) {
        this.setState({ isSaving: true, error: null })
        try {
            await deleteUnit(unitId)
            this.setState({ isSaving: false })
            this.goBack()
        } catch (e) {
            this.setState({
                isSaving: false,
                error: e.message,
            })
        }
    }

    render() {
        return (
            <div className="EditUnit">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                {this.state.unit && (
                    <UnitsForm
                        title="Edit Room"
                        unit={this.state.unit}
                        onSave={this.handleSave.bind(this)}
                        cancel={this.goBack.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        {...this.state}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(UnitsEdit)
